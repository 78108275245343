import { Flex, Space, Typography, Table, Collapse, List, Col, Row } from "antd";
import React from "react";
import AELHandler from "./TextHandlers/AELHandler"
import ExcessHandler from "./TextHandlers/ExcessHandler";
import PFHandler from "./TextHandlers/PFHandler";
import CEHandler from "./TextHandlers/CEHandler";
import AELPulseTrainHandler from "./TextHandlers/AELPulseTrainHandler";
import AELAverageHandler from "./TextHandlers/AELAverageHandler"
const { Text, Title } = Typography

export function LaserClassResultsDiffuseReflective(props) {

    /**
     * @type {number}
     * Values should either be 1, 2, or 3.
     */
    let condition = props?.condition
    // console.log("check",props)
    const mostRestrictiveCheck = (aelType, laserClass) => {
        const x = {
            c1: 'class1',
            c2: 'class2',
            c3r: 'class3r',
            c3b: 'class3b'
        }
        const orange = '#F8BD14'
        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed"){
            if (condition === 1) {
                const mostRestrictive =  props?.result?.laserClass?.condition1?.mostRestrictiveAEL[x[laserClass]]
                if (aelType === 'single') {
                    if ( props.result.laserClass?.condition1?.class[x[laserClass]]?.joules !== undefined && props.result.laserClass?.condition1?.class[x[laserClass]]?.joules !== null) {
                        
                            if (mostRestrictive ===  props.result.laserClass?.condition1?.class[x[laserClass]]?.joules) {
                                return { background: '#F8BD14' }
                            } else {
                                return { background: null }
                            }
                        
                    }
                } else {
                    if ( props?.result?.laserClass?.condition1?.class[x[laserClass]][aelType]?.joules !== 0) {
                        if (mostRestrictive === props?.result?.laserClass?.condition1?.class[x[laserClass]][aelType]?.joules && props.result.laserClass?.condition1?.class[x[laserClass]]?.joules !== props?.result?.laserClass?.condition1?.class[x[laserClass]][aelType]?.joules ) {
                            return { background: orange }
                        } else { 
                            return { background: null }
                        }
                    }
                }
            }
            else{
                const mostRestrictive =  props?.result?.laserClass?.condition3?.mostRestrictiveAEL[x[laserClass]]
                if (aelType === 'single') {
                    if ( props.result.laserClass?.condition3?.class[x[laserClass]]?.joules !== undefined && props.result.laserClass?.condition3?.class[x[laserClass]]?.joules !== null) {
                        
                            if (mostRestrictive ===  props.result.laserClass?.condition3?.class[x[laserClass]]?.joules) {
                                return { background: '#F8BD14' }
                            } else {
                                return { background: null }
                            }
                    }
                } else {
                    if ( props.result.laserClass?.condition3?.class[x[laserClass]][aelType]?.joules !== 0) {
                        if (mostRestrictive === props?.result?.laserClass?.condition3?.class[x[laserClass]][aelType]?.joules && props.result.laserClass?.condition3?.class[x[laserClass]]?.joules !== props?.result?.laserClass?.condition3?.class[x[laserClass]][aelType]?.joules ) {
                            return { background: orange }
                        } else {
                            return { background: null }
                        }
                    }
                }
            }
        }
       return null
    }
    
    const column = (()=>{
        let columns = []
        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed"){
            columns= [{
                key: 1,
                title: 'Laser Class',
                dataIndex: 'name',
                align: 'center'
            }, {
                key: 2,
                title: 'Accessible Emission Limit',
                children: [
                    {
                        key: "ab",
                        title: 'Single Pulse',
                        children: [
                            {
                                key: 'a',
                                title: 'Joules',
                                dataIndex: 'joules',
                                align: 'center'
                            }, {
                                key: 'b',
                                title: 'Watts',
                                dataIndex: 'watts',
                                align: 'center',
                                x: condition
                            }]
                    },{
                        key: "bb",
                        title: 'Pulse Train',
                        children: [
                            {
                                key: 'a',
                                title: 'Joules',
                                dataIndex: 'ptJoules',
                                align: 'center'
                            },{
                                key: 'b',
                                title: 'Watts',
                                dataIndex: 'ptWatts',
                                align: 'center',
                                x: condition
                            }],
                    },{
                        key: "cc",
                        title: <Text>AEL<sub> s.p.T</sub></Text>,
                        children: [
                            {
                                key: 'a',
                                title: 'Joules',
                                dataIndex: 'averageJoules',
                                align: 'center'
                            },{
                                key: 'b',
                                title: 'Watts',
                                dataIndex: 'averageWatts',
                                align: 'center',
                                x: condition
                            }]
                    }]
            },{
                key: 3,
                title: <Text strong>AEL <sub>T</sub></Text>,
                dataIndex: "aelTimeBase",
                align: 'center'
            },
            {
                key: 4,
                title: 'Emission Hazard Value',
                dataIndex: 'excess',
                align: 'center'
            }, {
                key: 5,
                title: 'Pass/Fail',
                dataIndex: 'pf',
                align: 'center'
            }]
        }
        else {
            columns= [{
                key: 1,
                title: 'Laser Class',
                dataIndex: 'name',
                align: 'center'
            }, {
                key: 2,
                title: 'Accessible Emission Limit',
                dataIndex: 'joules',
                align: 'center'
                // children: [{
                //     key: 'a',
                //     title: 'Joules',
                //     dataIndex: 'joules',
                //     align: 'center'
                // }, {
                //     key: 'b',
                //     title: 'Watts',
                //     dataIndex: 'watts',
                //     align: 'center',
                //     x: condition
                // }]  
            }, {
                key: 3,
                title: '    Emission Hazard Value',
                dataIndex: 'excess',
                align: 'center'
            }, {
                key: 4,
                title: 'Pass/Fail',
                dataIndex: 'pf',
                align: 'center'
            }]
        }
        return ({columns: columns})
    })()
    
  
    return (
        <Flex style={{
            width: '100%'
        }}>
            <Space direction="vertical" style={{
                width: '100%'
            }}>
                <Table size="small" title={() => {
                    return <Flex justify="center"><Text strong style={{
                        fontSize: '15px'
                    }}>Condition {condition}</Text></Flex>;
                }} bordered pagination={false} 
                scroll={{ x: 800}} 
                columns={column.columns} 
                dataSource={[{
                    key: 1,
                    name: 'AEL for Class 1',
                    joules: <AELHandler style={mostRestrictiveCheck('single', 'c1')} hec={props?.result?.laserClass} condition={condition} laserClass={'1'} unitToReturn={'joules'} />,
                    watts: <AELHandler style={mostRestrictiveCheck('single', 'c1')} hec={props?.result?.laserClass} condition={condition} laserClass={'1'} unitToReturn={'watts'} />,
                    excess: <ExcessHandler hec={props?.result?.laserClass} condition={condition} laserClass={'1'} />,
                    ptJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c1')} hec={props?.result.laserClass} condition={condition} laserClass={'1'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    ptWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler  style={mostRestrictiveCheck('pulseTrain', 'c1')} hec={props?.result.laserClass} condition={condition} laserClass={'1'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    averageJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler  style={mostRestrictiveCheck('AELSPT', 'c1')} hec={props?.result.laserClass} condition={condition} laserClass={'1'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    averageWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c1')} hec={props?.result.laserClass} condition={condition} laserClass={'1'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    aelTimeBase: <Text>{props.result.laserClass[`condition${condition}`]?.class?.class1?.average?.joulesPretty}</Text>,
                    pf: <PFHandler hec={props?.result?.laserClass} condition={condition} laserClass={'1'} />
                }, {
                    key: 2,
                    name: 'AEL for Class 2',
                    joules: <AELHandler style={mostRestrictiveCheck('single', 'c1')} hec={props?.result?.laserClass} condition={condition} laserClass={'2'} unitToReturn={'joules'} />,
                    watts: <AELHandler style={mostRestrictiveCheck('single', 'c1')} hec={props?.result?.laserClass} condition={condition} laserClass={'2'} unitToReturn={'watts'} />,
                    excess: <ExcessHandler hec={props?.result?.laserClass} condition={condition} laserClass={'2'} />,
                    ptJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c2')} hec={props?.result.laserClass} condition={condition} laserClass={'2'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    ptWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c2')} hec={props?.result.laserClass} condition={condition} laserClass={'2'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    averageJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c2')} hec={props?.result.laserClass} condition={condition} laserClass={'2'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    averageWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c2')} hec={props?.result.laserClass} condition={condition} laserClass={'2'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    aelTimeBase: <Text>{props.result.laserClass[`condition${condition}`]?.class?.class2?.average?.joulesPretty}</Text>,
                    pf: <PFHandler hec={props?.result?.laserClass} condition={condition} laserClass={'2'} />
                }, {
                    key: 3,
                    name: 'AEL for Class 3R',
                    joules: <AELHandler style={mostRestrictiveCheck('single', 'c3r')} hec={props?.result?.laserClass} condition={condition} laserClass={'3r'} unitToReturn={'joules'} />,
                    watts: <AELHandler style={mostRestrictiveCheck('single', 'c3r')} hec={props?.result?.laserClass} condition={condition} laserClass={'3r'} unitToReturn={'watts'} />,
                    excess: <ExcessHandler hec={props?.result?.laserClass} condition={condition} laserClass={'3r'} />,
                    ptJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c3r')} hec={props?.result.laserClass} condition={condition} laserClass={'3r'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    ptWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c3r')} hec={props?.result.laserClass} condition={condition} laserClass={'3r'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    averageJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c3r')} hec={props?.result.laserClass} condition={condition} laserClass={'3r'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    averageWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c3r')} hec={props?.result.laserClass} condition={condition} laserClass={'3r'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    aelTimeBase: <Text>{props.result.laserClass[`condition${condition}`]?.class?.class3r?.average?.joulesPretty}</Text>,
                    pf: <PFHandler hec={props?.result?.laserClass} condition={condition} laserClass={'3r'} />
                }, {
                    key: 4,
                    name: 'AEL for Class 3B',
                    joules: <AELHandler style={mostRestrictiveCheck('single', 'c3b')} hec={props?.result?.laserClass} condition={condition} laserClass={'3b'} unitToReturn={'joules'} />,
                    watts: <AELHandler style={mostRestrictiveCheck('single', 'c3b')} hec={props?.result?.laserClass} condition={condition} laserClass={'3b'} unitToReturn={'watts'} />,
                    excess: <ExcessHandler hec={props?.result?.laserClass} condition={condition} laserClass={'3b'} />,
                    ptJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c3b')} hec={props?.result.laserClass} condition={condition} laserClass={'3b'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    ptWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', 'c3b')} hec={props?.result.laserClass} condition={condition} laserClass={'3b'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    averageJoules: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c3b')} hec={props?.result.laserClass} condition={condition} laserClass={'3b'} unitToReturn={'joules'}/>}
                        return null
                    })(),
                    averageWatts: (()=>{
                        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ) 
                        { return <AELAverageHandler style={mostRestrictiveCheck('AELSPT', 'c3b')} hec={props?.result.laserClass} condition={condition} laserClass={'3b'} unitToReturn={'watts'}/>}
                        return null
                    })(),
                    aelTimeBase: <Text>{props.result.laserClass[`condition${condition}`]?.class?.class3b?.average?.joulesPretty}</Text>,
                    pf: <PFHandler hec={props?.result?.laserClass} condition={condition} laserClass={'3b'} />
                }, 
                {
                    key: 5,
                    name: <Text strong>Collected Emission</Text>,
                    // joules: <Text strong>{props.result?.hec?.[`condition${condition}`]?.details?.collectedEmission?.joulesPretty}</Text> || '-',
                    joules: <CEHandler hec={props?.result?.laserClass} condition={condition} unitToReturn={'joules'} />,
                    watts: <CEHandler hec={props?.result?.laserClass} condition={condition} unitToReturn={'watts'} />
                }]} footer={() => {
                    return <Collapse items={[{
                        key: 1,
                        label: <Flex justify="center"><Text strong type="warning">More info</Text></Flex>,
                        children: 
                        <Row justify="space-evenly" style={{
                            width: '100%',
                            justifyItems:"center"
                        }}>
                           
                            <Col lg={12} sm={24} style={{}} justify="center">
                                <Space style={{
                                    width: '95%'
                                }} direction="vertical">
                                    <Flex justify="center"><Text strong>Legend</Text></Flex>
                                    <List bordered>
                                        <List.Item>The AE values displayed in <Typography.Text strong type={'danger'}>red font</Typography.Text> indicate that the collected emission has exceeded the specified limit.</List.Item>
                                        <List.Item>The AE values displayed in <Typography.Text strong type={'success'}>green font</Typography.Text> indicate that the collected emission is within the specified limit.</List.Item>
                                        {
                                            /* {laserOperation === 'RP' ? <List.Item>The AE values highlighted in orange indicate that specified value is the <Text strong style={{ background: `#F8BD14` }}>Most Restrictive AEL.</Text></List.Item> : null} */
                                        }
                                    </List>
                                </Space>
                               
                            </Col>
                           
                            
                            <Col lg={12} sm={24} style={{
                              
                            }} justify="center">
                                <Table size="small" bordered pagination={false} style={{
                                    width: '95%'
                                }} columns={[{
                                    key: 1,
                                    title: `Condition ${condition} Misc Results`,
                                    dataIndex: 'name',
                                    align: 'right'
                                }, {
                                    key: 2,
                                    title: 'Value',
                                    dataIndex: 'value',
                                    align: 'center'
                                }]} dataSource={[{
                                    key: 1,
                                    name: 'Aperture Stop',
                                    value: props.result?.laserClass?.[`condition${condition}`]?.details?.aperture
                                }, {
                                    key: 2,
                                    name: 'Aperture Distance',
                                    value: props.result?.laserClass?.[`condition${condition}`]?.details?.distance
                                }, {
                                    key: 3,
                                    name: 'Angular Subtense',
                                    value: props.result?.laserClass?.[`condition${condition}`]?.details?.angularSubtense
                                }, {
                                    key: 4,
                                    name: 'Value for C₆',
                                    value: props.result?.laserClass?.[`condition${condition}`]?.details?.c6?.pretty
                                }, {
                                    key: 5,
                                    name: 'Acceptance Angle',
                                    value: null
                                }]} />
                           
                            </Col>
                        </Row>
                    }]} />;
                }} />
            </Space>
        </Flex>
    );
}
