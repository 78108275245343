import React, { createContext, useEffect, useState } from 'react'
import FetchLaserProfile from '../components/MyLaserProfileFetch';

const EnvironmentContext = createContext()

const EnvironmentProvider = ({ isLocal, children }) => {
    const [email, setEmail] = useState();
    const [otp, setOTP] = useState();
    const [myUser, setMyUser] = useState(null)

    const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(myUser, isLocal)


    // Logs messages to the console only in the local development environment
    const log = (...args) => {
        if (isLocal) {
            console.log(...args)
        }
    }


    const handleLogout = () => {
        setMyUser(null)
    }


    return (
        <EnvironmentContext.Provider
            value={{ isLocal, otp, setOTP, setEmail, email, setMyUser, myUser, myLaserProfile, laserProfileIsFetching, handleLogout, log }}
        >
            {children}
        </EnvironmentContext.Provider>
    )
}

export { EnvironmentContext, EnvironmentProvider }