import { Button, Select, Menu, Avatar, Card, Divider, Flex, Row, Space, Typography, Layout, message, Modal, Spin, ConfigProvider } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { ShoppingOutlined, LockFilled, ProfileOutlined, BugOutlined, HomeOutlined, LogoutOutlined, LaptopOutlined, NotificationOutlined, UserOutlined, LineOutlined, CreditCardOutlined, LinkedinOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import SubscriptionPlan from "../components/Plan"
import { EditProfile } from '../components/editProfile/EditProfile'
import { LaserProfileModal } from '../components/LaserProfile/LaserProfileModal'
import { NavLink, useLocation } from 'react-router-dom';
import { CalculatorNavigationHomePage } from '../components/CalculatorNavigationHomePage'
import FetchCalculations from '../components/FetchCalculations'
import FetchLaserProfile from '../components/MyLaserProfileFetch'
import RTLSS from '../assets/Logo/rtlss logo.png'
import { ReactComponent as Bars } from '../assets/Bars.svg'
import Loader from '../assets/Loader'
import Cookies from 'js-cookie'
import './home.css'
import { Contacts } from '../components/Footer/Contact'
import { FooterHandler } from '../components/Footer/Footer'
import ForgotPassword from '../components/ForgotPassword/ForgotPassword'
import SubMenu from 'antd/es/menu/SubMenu'
import { EnvironmentContext } from '../routes/EnvironmentContext'

const { Sider, Content, Footer } = Layout
const { Text } = Typography

const Home = () => {
  const { isLocal, myUser, setMyUser } = useContext(EnvironmentContext)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [laserProfileVisibility, setLaserProfileVisibility] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(myUser, isLocal)
  const [sampleModalVisible, setSampleModalVisible] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  let sent2 = searchParams.get('defKey')
  let sent = searchParams.get('handleClick')
  const [defKey, setDefKey] = useState(sent2 !== null ? sent2 : "1")
  const [handleClick, sethandleClick] = useState(sent !== null ? sent : "home");
  const [spinning, setSpinning] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial, freeTrialActivated, isPreAuthorized } = FetchCalculations(isLocal, myUser)
  const [userLocation, setUserLocation] = useState(null)
  const [openCookieNotice, setOpenCookieNotice] = useState(false)
  const [mergedOpenKeys, setMergedOpenKeys] = useState([]);
  const filteredKeys = Array.isArray(mergedOpenKeys) ? mergedOpenKeys.filter(key => key !== 'someKey') : [];

  const { SubMenu } = Menu;
  useEffect(() => {
    if (Cookies.get('RTLSS_Loc') !== undefined) {
      setUserLocation(JSON.parse(Cookies.get('RTLSS_Loc')))
    } else {

    }
  }, [])



  useEffect(() => {
    if (numberOfCalcs !== null) {
      setSpinning(false);
    } else {
      setSpinning(true);
    }
  }, [numberOfCalcs]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    messageApi.open({

      type: 'success',
      content: 'This is a prompt message for success, and it will disappear in 3 seconds',
      duration: 3,

    });
    setIsModalOpen(false);
  };

  const handleDeleteProfile = (props) => {
    setDeleteIsLoading(true)
    let laserIDToDelete = props.currentTarget.value
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/delete-laser'
        } else {
          return 'api/delete-laser'
        }
      })(),
      withCredentials: true,
      data: { laserIDToDelete: laserIDToDelete }
    })
      .then((response) => {
        success(response.data.message)
        setMyUser({ ...myUser, laserProfile: response.data.data })

      })
      .catch((err) => {
        isError(err.data.message)
      })
      .finally(() => {
        setDeleteIsLoading(false)
      })
  }

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    })
  }

  const isError = (message) => {
    messageApi.open({
      type: 'error',
      conntent: message,
      duration: 4
    })
  }

  // if (monday === undefined || mondayUsers === undefined) {
  //   updateDataSource = null
  // } else {
  //   // Clean the data first.
  //   const cleanMondayData = monday.filter(({ name, column_values }) => column_values[1].text === 'Backlogs' || column_values[1].text === 'Working on it')


  //   if (cleanMondayData.length !== 0) {

  //     updateDataSource = cleanMondayData.map(({ name: desc, column_values }) => {
  //       return (
  //         {
  //           dev: (() => {
  //             if (column_values[0].value === null) {
  //               return (
  //                 <Flex justify='start' align='center'>
  //                   N/A
  //                 </Flex>
  //               )
  //             } else {
  //               let myDev = mondayUsers.find(({ id }) => id === JSON.parse(column_values[0].value).personsAndTeams[0].id)
  //               const { photo_thumb, name } = myDev
  //               return (
  //                 <Flex justify='start' align='center' style={{ height: '100%' }}>
  //                   <Space>
  //                     <Avatar src={photo_thumb} icon={<UserOutlined />} />
  //                     {name}
  //                   </Space>
  //                 </Flex>
  //               )
  //             }
  //           })(),
  //           desc: desc,
  //           timeline: (() => {
  //             if (column_values[2].value === null) {
  //               return null
  //             } else {
  //               return `${dayjs(JSON.parse(column_values[2].value).from).format('MMM DD')} - ${dayjs(JSON.parse(column_values[2].value).to).format('MMM DD')}`
  //             }
  //           })()
  //         }
  //       )
  //     })
  //   } else {
  //     cleanMondayData = null
  //   }
  // }

  // if (git === undefined) {
  //   dataSource = null
  // } else {
  //   dataSource = git.map(({ author: { avatar_url, login }, commit: { message, author: { date } } }, index) => {
  //     return (
  //       {
  //         key: index + 1,
  //         login: (
  //           <Flex align='center'>
  //             <Space>
  //               <Avatar src={avatar_url} icon={<UserOutlined />} />
  //               {login}
  //             </Space>
  //           </Flex>
  //         ),
  //         description: message,
  //         date: dayjs(date).format('MMM DD YYYY')
  //       }
  //     )
  //   })
  // }
  // const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
  //   const key = String(index + 1);
  //   return {
  //     key: `sub${key}`,
  //     icon: React.createElement(icon),
  //     label: `subnav ${key}`,
  //     children: new Array(4).fill(null).map((_, j) => {
  //       const subKey = index * 4 + j + 1;
  //       return {
  //         key: subKey,
  //         label: `option${subKey}`,
  //       };
  //     }),
  //   };
  // });

  const handleCookieNotice = () => {
    setOpenCookieNotice(false)
    Cookies.set('_cookieConsent', true, { expires: 182 })
  }

  return (
    <>
      {contextHolder}
      {/*Side Menu  */}
      <Modal
        open={sampleModalVisible}
        onCancel={() => { setSampleModalVisible(false) }}
        width={800}
      >
        {/* <LaserClassResultsV2 /> */}
      </Modal>
      <Spin spinning={spinning} size='large' indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", right: "50%", top: "60%", transform: "translate(-41%, -50%)" }} />}>

        <Layout hasSider style={{
          minHeight: '100vh',
          flex: 1
        }}>
          <Sider
            theme='light'
            breakpoint='lg'
            style={{
              position: 'sticky',
              left: 0,
              top: 78,
              bottom: 0,
            }}>

            {/* Side Menu Items*/}
            <Menu
              collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
              theme='light'
              mode="inline"
              defaultSelectedKeys={defKey}
              defaultOpenKeys={defKey}
              role="navigation"
              aria-label="Main navigation"
              style={{
                height: '100%',
                borderRight: "1px solid #F0F0F0",
                marginTop: "60px",
                color: "#1d1f8c"
              }}
              openKeys={mergedOpenKeys} // Ensure this is an array
              onOpenChange={keys => setMergedOpenKeys(keys)}
            >
              <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => sethandleClick("home")}
                role="menuitem"
                aria-label="Home" >
                Home
              </Menu.Item>
              <SubMenu key="2" icon={<ProfileOutlined />} title="Account" role="submenuitem"
                aria-label="Submenu">
                <Menu.Item key="7" icon={<UserOutlined />} onClick={() => sethandleClick("profile")} role="submenuitem"
                  aria-label="Edit Profile">Edit Profile</Menu.Item>
                <Menu.Item key="8" icon={<LockFilled />}><NavLink to='/secure-change' role="sunmenuitem"
                  aria-label="Edit Password"></NavLink>Security</Menu.Item>
              </SubMenu>
              <Menu.Item key="3" icon={<LineOutlined />} onClick={() => sethandleClick("laserProfile")} role="menuitem"
                aria-label="Saved Lasers">
                Saved Lasers
              </Menu.Item>
              <Menu.Item key="4" icon={<ShoppingOutlined />} onClick={() => sethandleClick("billing")} role="menuitem"
                aria-label="Buy Calculations">
                Buy Calculations
              </Menu.Item>
              <Divider />
              <Menu.Item Key="5" icon={<BugOutlined />} style={{ color: 'red' }} role="menuitem"
                aria-label="Report">
                <NavLink to="/report" style={{ textDecoration: 'none' }} >
                  Report a Bug
                </NavLink>
              </Menu.Item>
              <Select
                className='collapse-select'
                label='Standard Selection'
                defaultValue="iec"
                dropdownStyle={{ minWidth: '150px', maxWidth: '300px' }}
                style={{
                  textDecoration: "none",
                }}
                options={[
                  {
                    value: "iec",
                    label: "IEC 60825-1 2014",
                  },
                  {
                    value: "ansi",
                    label: "ANSI",
                    disabled: true
                  },
                ]}
                role="menuitem"
                aria-label="Standard"
              />
              <Divider />
              <Menu.Item key="6" icon={<LogoutOutlined />} role="menuitem"
                aria-label="Logout">
                <NavLink to="/logout" style={{ textDecoration: 'none' }}>
                  Logout
                </NavLink>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ backgroundColor: "white", flex: 1, minHeight: "100vh" }}>
            {/*Home Page Content */}
            <Content style={{ flex: 1, marginTop: "50px", overflow: 'initial' }}>
              {handleClick === "home" ? (
                <>
                  <Row justify='center' align='middle' style={{ marginBottom: "30px" }}>
                    <h1 style={{ margin: '30px 0px 0 0px', color: '#1d1f8c', textAlign: "center", fontSize: '2rem', }} >Your Safety Starts Here. Pick a Calculator and Start Calculating!</h1>
                  </Row>

                  {/** Menu forthe calculators */}
                  <Row justify='start' align='middle'>
                    <CalculatorNavigationHomePage />
                  </Row>


                  {/* Footer */}
                  <Row justify='center' style={{ margin: '3% 0 3% 0' }}>
                    <h2 style={{ color: '#1d1f8c', textAlign: "center" }}><i>Innovative laser calculations from the experts in laser safety</i></h2>

                  </Row>
                </>
              ) : handleClick === "profile" ? (
                <Row justify={"center"}>
                  <EditProfile
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    myUser={myUser}
                    setMyUser={setMyUser}
                    isLocal={isLocal}
                  />

                </Row>
              ) : handleClick === "laserProfile" ? (
                <Row justify={"center"}>
                  {myLaserProfile !== null ? (
                    <>
                      <LaserProfileModal
                        myUser={myUser}
                        isLocal={isLocal}
                        laserProfileVisibility={laserProfileVisibility}
                        setLaserProfileVisibility={setLaserProfileVisibility}
                        deleteIsLoading={deleteIsLoading}
                        handleDeleteProfile={handleDeleteProfile}
                        myLaserProfile={myLaserProfile}
                      />

                      {/* Laser Profile Modal */}
                    </>
                  ) : null}
                </Row>
              ) : handleClick === "billing" ? (
                <>
                  <Row justify={"center"}>
                    <Card style={{ width: "60vw", display: "flex", justifyContent: "center", textAlign: "center" }}>
                      <Typography.Text>
                        <h2 style={{ color: "#1d1f8c" }}>
                          {isSubscribed === true ? ("You are Subscribed: Unlimited Calculations")
                            : isFreeTrial === true ? ("Free Trial Activated")
                              : isFreeTrial === false ? ("Free Trial Expired")
                                : isPreAuthorized === true ? ("Activate Free Trial")
                                  : (<>Your Available Calculations:  <span style={{ color: numberOfCalcs < 4 ? "#d82424" : "#10c96d" }}> {numberOfCalcs}</span> </>)}
                        </h2>
                      </Typography.Text>
                    </Card>
                  </Row>
                  <SubscriptionPlan isLocal={isLocal} myUser={myUser} isSubscribed={isSubscribed} isFreeTrial={isFreeTrial} freeTrialActivated={freeTrialActivated} isPreAuthorized={isPreAuthorized} userLocation={userLocation} />
                </>
              ) : handleClick === "contact" ? (
                <>
                  <Contacts />
                </>
              )
                : null}
            </Content>
          </Layout>
        </Layout>
        <FooterHandler />
        <Modal
          open={openCookieNotice}
          title={<h3>We use cookies</h3>}
          closable={false}
          footer={
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Button type='primary' onClick={() => { handleCookieNotice() }} style={{ width: "50%" }}> Accept </Button>
            </Row>

          }>
          <Typography.Paragraph>
            We utilize essential cookies that are vital for the proper functioning of our application. These cookies support critical features such as session management and geolocation, which we use exclusively to determine your currency.
            As these cookies are necessary for our app to operate effectively, they cannot be disabled in our systems.
            By using our services, you consent to the use of these essential cookies as outlined in our <a href="/privacy-policy" target='_blank' rel="noreferrer" style={{ textDecoration: "underline" }}>Privacy Policy</a>.
          </Typography.Paragraph>
          <Typography.Text type='secondary' italic>Please note that you can only acknowledge this notice by clicking the 'Accept' button.</Typography.Text>
        </Modal>
      </Spin>
    </>




  )
}
export default Home
