import React, {useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Watermark from './Watermark';
import logo from "../../assets/Logo/logosidetext.png"
import laserFooter from "../../assets/Logo/laserFooter.png"
// Create styles
Font.register(({family: 'Roboto', fonts: [
  {
    src: 'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xIIzc.ttf', // Normal font
  },
  {
    src: 'https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrIzc.ttf', // Italic font
    fontStyle: 'italic',
  },
],}))
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: "center",
    position: 'relative',
    paddingTop: '25px',
   
  },
  title: {
    justify: 'cenetr',
    textAlign: 'center',
    marginBottom: "0.2cm",
    marginTop: "0.5cm"
  },
  image: {
    width: "35vw",
    marginBottom: "45px"
   
  },
  row: {
    flexDirection: "row",
    width: "90vw"
  },
  smallRow: {
    flexDirection: "row",
    width: "100%",
  
  },
  box: {
    width: "0.8in",
    height: '0.8in',
    justify: 'center',
    borderRadius:"50px"
  
  },
  marginLeft: {
    marginLeft: "2cm"
  },
  underLine: {
    borderBottom: '1px solid black',
    marginBottom: "0.5cm"
  },
  data: {
    fontSize: "6mm"
  },
  center: {
    display:"flex",
    justify: "center",
    justifyContent: "center",
    flexDirection: "row",
    textAlign: 'center',
  },
  titleBox: {
    justify: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    backgroundColor: 'rgb(230,230,230)',
    marginBottom: "0.2cm",
    marginTop: "0.5cm",
    width: "90vw",
    height: "30px",
    padding: "5px",
    fontSize: "15px",
    fontWeight: "bold"

  },
  classification: {
    backgroundColor: 'rgb(230,230,230)',
    marginBottom: "0.2cm",
    marginTop: "0.5cm",
    width: "90vw",
    height: "30px",
    padding: "5px",
    fontSize: "15px",
    fontWeight: "bold"

  },
  subTitle: {
    fontSize: "13px",
    justifyContent: "space-around",
    fontWeight: "bold",
    marginBottom: "15px"
  },
  result: {
    fontSize: "13px",
    fontWeight: "bold",
    marginTop: "20px"
  },
  footer: {
    position: "absolute",
    bottom: 40,
    width: "100vw",
    height: "4vh"
  
  },
  header: {
    justifyContent: "space-between",
  },
  verdict: {
    width: "90vw",
    borderRadius: "20px",
    height: "30px",
    padding:"5px",
    textAlign: 'center',
  
  },
  classVerdict: {
    width: "60vw",
    border: "1px solid, #1d1f8c",
    borderRadius: "20px",
    padding:"5px",
    textAlign: 'center',
    justify: "center",
    color: "#1d1f8c",
    marginTop: "50px"
  
  },
  repPulsedAEL: {
    fontSize: "16px"
  },
  repPulsedAELHeader: {
    fontSize: "14px"
  },
  correctionFactors: {
    justifyContent: "space-evenly",
    fontSize: "50px"
  },
  AE: {
    justifyContent: "space-around",
  },
  subscript: {
    fontSize: 10,
    baselineShift: -3, 
  },
  secondaryText: {
    color: '#505050', 
    fontSize: 12,  
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  AccessibleEmission: {
    fontSize: "15px",
    marginTop: "10px"
  }
  
});
let color
let verText
let skinColor
let skinVerText
export function ResultsPDF({result, date, form, laserName})
{


  let operation
  let laserOperation
  let source 
  if (form.laserOperation === "cw") {
    operation = form.cw
    laserOperation = "Continous Wave"
  }
  else if (form.laserOperation === "singlePulse") {
    operation = form.singlePulse
    laserOperation = "Single Pulse"
  }
  else if (form.laserOperation === "repetitivelyPulsed") {
    operation = form.repetitivelyPulsed
    laserOperation = "Repetitively Pulsed"
  }
  else if (form.laserOperation === "FO_cw") {
    operation = form.FO_cw
    laserOperation = "Fiber Optic Continous Wave"
  }
  else if (form.laserOperation === "FO_singlePulse") {
    operation = form.FO_singlePulse
    laserOperation = "Fiber Optic Single Pulse"
  }
  else if (form.laserOperation === "FO_repetitivelyPulsed") {
    operation = form.FO_repetitivelyPulsed
    laserOperation = "Repetitively Pulsed"
  }
  else if (form.laserOperation === "diffuseReflectiveCW") {
    operation = form.diffuseReflectiveCW
    laserOperation = "Diffuse Reflective Continous Wave"
    result = result.data
 
  }


  if (form.sourceType === "point") {
    source = "Point"
  }
  else {
    source = "Extended"
  }

 
  // Create Document Component
  
  const MPE = result.MPE?.joulesPerSqmPretty?.eng ?? result.MPEsingle?.joulesPerSqmPretty?.eng ?? result.MPE?.single?.joulesPerSqmPretty?.eng ?? result?.ExtendedMPE?.joulesPerSqmPretty?.eng 
  const skinMPE = result.skinMPE?.joulesPerSqmPretty?.eng ?? result.skinMPEsingle?.joulesPerSqmPretty?.eng ?? result.skinMPE?.single?.joulesPerSqmPretty?.eng ?? result.MPEskin?.joulesPerSqmPretty?.eng
  const AE = result.AE?.joulesPerSqmPretty?.eng ?? result.AE?.joulesPerSqmPretty ?? result?.AE?.albedo_joulesPerSqmPretty
  const actual_AE = result.AE?.actual_joulesPerSqmPretty?.eng ?? result.AE?.actual_joulesPerSqmPretty ?? result.AE?.joulesPerSqmPretty
  const skinAE = result.skinAE?.joulesPerSqmPretty?.eng ?? result.skinAE?.joulesPerSqmPretty ?? result.skinAE.albedo_joulesPerSqmPretty
  const skinActual_AE = result.skinAE?.actual_joulesPerSqmPretty?.eng ?? result.skinAE?.actual_joulesPerSqmPretty ?? result.skinAE.joulesPerSqmPretty

  const pulseTrainMPE = result.MPE?.joulesPerSqmPretty?.eng ?? result.MPE?.pulseTrain?.joulesPerSqmPretty ?? result?.ExtendedMPE?.joulesPerSqmPretty?.eng
  const averageMPE = result.MPE?.joulesPerSqmPretty?.eng ?? result.MPE?.average?.joulesPerSqmPretty ?? result?.ExtendedMPE?.joulesPerSqmPretty?.eng

return (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <Watermark />
      {/**Parameters */}
      <View>
        <View style={[styles.row, styles.header]}>

          <View>
            <Image src={logo} style={styles.image}></Image>
          </View>

          <View style={{fontSize:"14px"}}>
            <View style={{justifyContent:"center"}}>
              <Text style={{marginTop:"10px"}}>Name: {laserName}</Text> 
            </View>
            <View style={{justifyContent:"flex-end"}}>
              <Text style={{justifyContent:"flex-end"}}>Date: {date}</Text>
            </View>
          </View>
        </View>
     
      <View style={styles.row}><Text style={styles.title}>Point & Extended Source Results (IEC 60825-1 2014)</Text></View>
      <Text style={{fontWeight:"bold"}}> A) Laser Properties</Text>
        <View style={styles.row}>
          <div style={styles.titleBox}>
            <Text>Laser Profile</Text> 
          </div>
        </View>
        
            <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text>Laser Operation:</Text>
                <Text>Source:</Text>
              </View>
              <View>
                <View style={styles.center}>
                  <Text>{laserOperation}</Text>
                </View>
                <View style={styles.center}>
                  <Text>{source} Source</Text>
                </View>
              </View>
            </View>

        <View style={styles.row}>
          <div style={styles.titleBox}>
            <Text>{laserOperation} Settings</Text> 
          </div>
        </View>
        <View  style={[styles.row, styles.subTitle]}>
          <View>
            <Text style={styles.result}>Wavelength</Text>
            <Text style={styles.result}>Exposure Duration</Text>
            <Text style={styles.result}>{operation?.energy?.num ? "Energy" : operation?.averagePower?.num ? "Average Power" : "Laser Power"}</Text>
            <Text style={styles.result}>{operation.beamDiameter?.num ? "Beam Diameter" : operation.diameter?.num ? "Fiber Core Diameter" : form.extendedSettings?.apparentSourceSize?.num ? "Apparent Source Size" : "Numerical Aperture"}</Text>
            
            {form.laserOperation === "cw" || form.laserOperation === "singlePulse" || form.laserOperation === "repetitivelyPulsed" ? 
              (<Text style={styles.result}>Beam Divergence</Text>) : null
            }
            
            {form.sourceType === "multimodeGI" ? (
              <Text style={styles.result}>Index Grading Factor</Text>
            ) : null }
            <Text style={styles.result}>Distance to Target</Text>
            {laserOperation === "Repetitively Pulsed" ?  
            <>
              <Text style={styles.result}>Pulse Duration</Text> 
              <Text style={styles.result}>Frequency</Text>
            </>
            : null }
          </View>
          <View>
            <Text style={styles.result}>{operation.wavelength.num} nm</Text>
            <Text style={styles.result}>{operation.time?.num ?? operation?.pulseDuration?.num ?? operation?.duration?.num} {operation?.time?.unit ?? operation?.pulseDuration?.unit === 'u' ?  '\u00B5' : operation?.pulseDuration?.unit ?? operation?.duration?.unit === 'u' ?  '\u00B5' : operation?.duration?.unit}s</Text>
             <Text style={styles.result}>{(operation?.power?.num != null ? operation.power.num + " " + (operation?.power?.unit === false ? " " : operation?.power?.unit)  + "W" :
              operation?.energy?.num != null ? operation.energy.num + " " + (operation?.energy?.unit === false ? " " : operation?.energy?.unit)  + "J" :
              operation?.peakPower?.num != null ? operation.peakPower.num + " " + (operation?.peakPower?.unit === false ? " " : operation?.peakPower?.unit) + "W" :  operation.averagePower.num != null ? operation.averagePower.num + " " +  (operation?.averagePower?.unit === false ? " " : operation?.averagePower?.unit) + "W" :
              undefined) }</Text>
            
            {operation?.numerical_aperture?.num ? <Text style={styles.result}>{operation?.numerical_aperture?.num}</Text>
            : form.extendedSettings?.apparentSourceSize?.num ?  <Text style={styles.result}>{form.extendedSettings?.apparentSourceSize?.num} {form.extendedSettings?.apparentSourceSize?.unit}m</Text>
            : <Text style={styles.result}>{operation.beamDiameter?.num ?? operation.diameter?.num } {operation?.beamDiameter?.unit === 'u' ? '\u00B5' : operation?.beamDiameter?.unit ?? operation?.diameter?.unit }m</Text>
            }
           
            {form.laserOperation !== "cw" && form.laserOperation !== "singlePulse" && form.laserOperation !== "repetitivelyPulsed" ? (
             null
            )  : <Text style={styles.result}>{operation.beamDivergence?.num} {operation?.beamDivergence?.unit === "degree" ? "°" : operation?.beamDivergence?.unit !== false ? operation?.beamDivergence?.unit + "rad" : "rad"} </Text>}
          
            {operation?.IGF?.num === undefined ? null :  <Text style={styles.result}>{operation.IGF.num}</Text> }
           
           
            <Text style={styles.result}>{operation?.distance?.num} {operation?.distance?.unit}m</Text>
            
            {laserOperation === "Repetitively Pulsed" ? 
            <>
            <Text style={styles.result}>{operation?.pulseDuration?.num ?? operation?.duration?.num} {operation?.pulseDuration?.unit ?? operation?.duration?.unit}s</Text>  
            <Text style={styles.result}>{operation?.pulseFrequency?.num} {operation?.pulseFrequency?.unit}Hz</Text>  
            </>
            : null}
          </View>
        </View>
      </View>
      
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
        
        {/* <View style={{justifyContent:"space-between"}}>
          <Text>RealTime - Laser Safety Software</Text>
          <Text>Lookup Table Results</Text>
        </View> */}
    </Page>

     {/**Calculation Results */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />
      
      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
        <Text> B) Calculation Results</Text>

        <View style={styles.row}>
          <View>
          <div style={styles.titleBox}>
            <Text>Eye</Text> 
          </div>
            <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text style={styles.center}>{ laserOperation === "Repetitively Pulsed" ? "Single Pulse MPE" : "Maximum Permissible Emission" }</Text>
                <View style={styles.center}>
                  <Text style={[styles.center, styles.result]}>{MPE}{MPE.includes("J/m²") ? "" : "J/m²" }</Text>
                </View>
              </View>
              <View>
                <Text>Accessible Emission</Text>
              
                  <View style={[styles.smallRow, styles.AE]}>
                    <Text  style={styles.center}>AE</Text>
                    <Text style={styles.center}>Actual AE</Text>
                  </View>
        
                  <View style={[styles.smallRow, styles.AE]}>
                  <Text style={styles.center}>{AE}{ AE.includes("J/m²") ? "" : "J/m²" }</Text>
                  <Text style={{ ...styles.center, marginLeft: " 15px" }}>{actual_AE}{actual_AE.includes("J/m²") ? "" : "J/m²" }</Text>
                  </View>
  
              </View>
              <View >
                <Text>Eye Safety Ratio</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.MPEexcess?.joulesPretty_single?.pretty ?? result?.MPEexcess?.joulesPretty?.pretty ?? result?.safetyratio?.pretty?.pretty ?? result?.safetyratio?.singlePretty?.pretty}</Text>
                </View>
              </View>
            </View>
            {laserOperation === "Repetitively Pulsed" ? (
              <>
                {/* Pulse Train Results*/}
                <View style={[styles.row, styles.subTitle]}>
                  <View>
                    <Text style={styles.center}>Pulse Train MPE</Text>
                    <View style={styles.center}>
                      <Text style={[styles.center, styles.result]}>{pulseTrainMPE}{pulseTrainMPE.includes("J/m²") ? "" : "J/m²" }</Text>
                    </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>AE</Text>
                        <Text style={styles.center}>Actual AE</Text>
                      </View>

                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{AE}{ AE.includes("J/m²") ? "" : "J/m²" }</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{actual_AE}{actual_AE.includes("J/m²") ? "" : "J/m²" }</Text>
                      </View>
           
                  </View>
                  <View>
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result.MPEexcess?.joulesPretty_train?.pretty ?? result?.safetyratio?.pulseTrainPretty?.pretty}</Text>
                    </View>
                  </View>
                </View><View style={[styles.row, styles.subTitle]}>
                
                {/* Average Results*/}
                <View>
                  <Text style={styles.center}>Average MPE</Text>
                  <View style={styles.center}>
                    <Text style={[styles.center, styles.result]}>
                        {averageMPE}{averageMPE.includes("J/m²") ? "" : "J/m²" }
                    </Text>
                  </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>AE</Text>
                        <Text style={styles.center}>Actual AE</Text>
                      </View>

                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{AE}{ AE.includes("J/m²") ? "" : "J/m²" }</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{actual_AE}{actual_AE.includes("J/m²") ? "" : "J/m²" }</Text>
                      </View>
                
                  </View>
                  <View>
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result?.MPEexcess?.joulesPretty_average?.pretty ?? result?.safetyratio?.averagePretty?.pretty}</Text>
                    </View>
                  </View>
              </View></>
            ) : null}
         
            <View style={[styles.row, styles.center]}>
              
              {(() => {
                  if (!result?.safetyratio || !result?.safetyratio?.joules) {
                    if (result?.MPEexcess?.joules > 1 ) {
                      color = "#d82424"
                      verText = "DANGEROUS FOR THE EYE"
                    }
                    else if(result?.MPEexcess?.joules <= 0.5 ) {
                      color = "#10c96d"
                      verText = "SAFE FOR THE EYE"
                    }
                    else if (result?.MPEexcess?.joules > 0.5 || result?.MPEexcess?.joules <=1) {
                      color = "#ffa200"
                      verText = "MARGINALLY SAFE FOR THE EYE"
                    }
                }
                else{
                    if (result?.safetyratio?.joules > 1 ) {
                      color = "#d82424"
                      verText = "DANGEROUS FOR THE EYE"
                    }
                    else if(result?.safetyratio?.joules <= 0.5) {
                      color = "#10c96d"
                      verText = "SAFE FOR THE EYE"
                    }
                    else if (result.safetyratio.joules > 0.5 || result.safetyratio.joules <=1) {
                      color = "#ffa200"
                      verText = "MARGINALLY SAFE FOR THE EYE"
                    }
                }
                })()}
              <div style={{
                ...styles.verdict,
                backgroundColor: color
              }}>
                <Text style={{...styles.center, color: "white"}}>{verText}</Text>
              </div>
               
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View>
          <div style={styles.titleBox}>
            <Text>Skin</Text> 
          </div>
          <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text style={styles.center}>{ laserOperation === "Repetitively Pulsed" ? "Single Pulse MPE" : "Maximum Permissible Emission" }</Text>
                <View style={styles.center}>
                  <Text style={[styles.center, styles.result]}>
                    {skinMPE}J/m²
                  </Text>
                </View>
              </View>
              <View>
                <Text>Accessible Emission</Text>
                <View style={[styles.smallRow, styles.AE]}>
                    <Text  style={styles.center}>AE</Text>
                    <Text style={styles.center}>Actual AE</Text>
                  </View>
                
                  <View style={[styles.smallRow, styles.AE]}>
                  <Text style={styles.center}>{skinAE}{skinAE.includes("J/m²") ? "" : "J/m²" }</Text>
                  <Text style={{ ...styles.center, marginLeft: " 15px" }}>{skinActual_AE}{skinActual_AE.includes("J/m²") ? "" : "J/m²" }</Text>
                  </View>
                
  
              </View>
              <View >
                <Text>Eye Safety Ratio</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result.skinMPEExcess.joulesPretty_single?.pretty ?? result.skinMPEExcess.joulesPretty_single?.pretty ?? result.skinMPEExcess.joulesPretty?.pretty}</Text>
                </View>
              </View>
            </View>
              {laserOperation === "Repetitively Pulsed" || laserOperation === "Repetitively Pulsed" ? (
                <>
                 {/** Pulse Train MPE Results */}
                <View style={[styles.row, styles.subTitle]}>
                  <View>
                    <Text style={styles.center}>Pulse Train MPE</Text>
                    <View style={styles.center}>
                      <Text style={[styles.center, styles.result]}>{result.skinMPE?.joulesPerSqmPretty?.eng ?? result.skinMPEsingle?.joulesPerSqmPretty?.eng ?? result.skinMPE?.PulseTrain?.joulesPerSqmPretty?.eng ?? result.MPEskin?.joulesPerSqmPretty?.eng}J/m²</Text>
                    </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                        <Text  style={styles.center}>AE</Text>
                        <Text style={styles.center}>Actual AE</Text>
                      </View>
                    
                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{skinAE}{skinAE.includes("J/m²") ? "" : "J/m²" }</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{skinActual_AE}{skinActual_AE.includes("J/m²") ? "" : "J/m²" }</Text>
                      </View>
                   
      
                  </View>
                  <View >
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result.skinMPEExcess.joulesPretty_train?.pretty ?? result.skinMPEExcess.joulesPretty_train.pretty}</Text>
                    </View>
                  </View>
                </View>

                {/** Average MPE Results */}
                <View style={[styles.row, styles.subTitle]}>
                  <View>
                  <Text style={styles.center}>Average MPE</Text>
                  <View style={styles.center}>
                    <Text style={[styles.center, styles.result]}>{result.skinMPE?.joulesPerSqmPretty?.eng ?? result.skinMPEsingle?.joulesPerSqmPretty?.eng ?? result.skinMPE?.MPEAverage?.joulesPerSqmPretty?.eng ?? result.MPEskin?.joulesPerSqmPretty?.eng}J/m²</Text>
                  </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                      <Text  style={styles.center}>AE</Text>
                      <Text style={styles.center}>Actual AE</Text>
                      </View>
                  
                      <View style={[styles.smallRow, styles.AE]}>
                      <Text  style={styles.center}>{skinAE}{skinAE.includes("J/m²") ? "" : "J/m²" }</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{skinActual_AE}{skinActual_AE.includes("J/m²") ? "" : "J/m²" }</Text>
                      </View>
                    </View>
                  
                  <View >
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result.skinMPEExcess.joulesPretty_average?.pretty ?? result.skinMPEExcess.joulesPretty_average.pretty}</Text>
                    </View>
                  </View>
                </View>
                </>
              ): null }
         
            <View style={[styles.row, styles.center]}>
              
              {(() => {
                  if (result.skinMPEExcess.joules > 1) {
                    skinColor = "#d82424"
                    skinVerText = "DANGEROUS FOR THE SKIN"
                  }
                  else if(result.skinMPEExcess.joules <= 0.5){
                    skinColor = "#10c96d"
                    skinVerText = "SAFE FOR THE SKIN"
                }
                  else if (result.skinMPEExcess.joules > 0.5 || result.skinMPEExcess.joules <=1) {
                    skinColor = "#ffa200"
                    skinVerText = "MARGINALLY SAFE FOR THE SKIN"
                }
                })()}
              <div style={{
                ...styles.verdict,
                backgroundColor: skinColor
              }}>
                <Text style={{ ...styles.center, color: "white" }}>{skinVerText}</Text>
              </div>
                
            </View>
          </View>
        </View>
        {laserOperation !== "Repetitively Pulsed" ? (      
        <><View style={styles.row}>
            <div style={styles.titleBox}>
              <Text>Beam Properties</Text>
            </div>
          </View><View style={[styles.row, styles.subTitle]}>
              <View>
                <Text>N.O.H.D.</Text>
                <View style={[styles.center, styles.result]}>
                <Text>
                  {/* {((value) => (value?.endsWith('m') ? value : `${value}m`))( */}
                    {result?.pdfNOHD?.eng ?? result?.NOHD?.eng ?? result?.nohd?.eng ?? ''}m
                  {/* )} */}
                </Text>
                </View>
              </View>
              <View>
                <Text>E.N.O.H.D.</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>
                      {result?.ENOHD?.pretty ?? 
                      result?.ENOHD ?? 
                      result?.XNOHD?.pretty ?? 
                      result?.xnohd?.pretty ?? "" }
                   
                  </Text>                
                </View>
              </View>
              <View>
                <Text>Spot Size</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>
                    {((value) => (value?.endsWith('m') ? value : `${value}m`))(
                      result?.outputBeamDiameter?.outputBeamDiameterPretty ??
                      result?.spotsize?.d63Pretty?.eng ??
                      result?.spotsize?.eng ??
                      ''
                    )}
                  </Text>
                </View>
              </View>
              <View>
                <Text>Limit Aperture</Text>
                <View style={[styles.result]}>
                  <Text>Eye:{result?.limitingAperture?.eye ?? result?.limitAperture?.eye} mm</Text>
                  <Text>Skin:{result?.limitingAperture?.skin ?? result?.limitAperture?.skin} mm </Text>
                </View>
              </View>
              <View>
                <Text>Applicable Beam Area</Text>
                <View style={[styles.result]}>
                  <Text>Eye: {result?.applicableBeamArea?.applicableBeamAreaPrettiest ?? result?.applicableBeamArea?.applicableBeamAreaEyePrettiest} </Text>
                  <Text>Skin:  {result?.skinApplicableArea?.skinApplicableAreaPrettiest ?? result?.applicableBeamArea?.applicableBeamAreaSkinPrettiest} </Text>
                </View>
              </View>
              {result.beamDivergence ?(
                  <View>
                    <Text>Beam Divergence</Text>
                    <View style={[styles.result]}>
                      <Text>{result?.beamDivergence?.degrees?.pretty ?? result?.beamDivergence }</Text>
                    </View>
                  </View>
              ) : null }
             
            </View><View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Spectrum</Text>
              </div>
            </View>
            <View style={styles.center}>
              <View style={styles.center}>
                <Text style={[
                  styles.box,
                  {
                    backgroundColor: (() => {
                      if (result.spectrum.range == "Infrared") {
                        return "maroon";
                      }
                      else if (result.spectrum.range == "Ultraviolet") {
                        return "purple";
                      }
                      return "rgb(" + result.spectrum.rgb + ")";
                    })()
                  }
                ]}>
                </Text>
              </View>
              <View style={{ alignSelf: "center", marginLeft: "10px" }}>
                <Text>{result.spectrum.region}</Text>
              </View>
            </View>
          </>
        ) : null}
      </View>

      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>
    
    {/**Moved beam properties to next page if laser operation is Repetitively Pulsed
     because it results wont fit on previouse page */}
    {laserOperation === "Repetitively Pulsed" ? (
      <Page size="LETTER" style={styles.page}>
        <Watermark />
        <View>
          <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
          </View>
          <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Beam Properties</Text> 
              </div>
          </View>
              <View style={[styles.row, styles.subTitle]}>
                <View>
                    <Text>N.O.H.D.</Text> 
                    <View style={[styles.center, styles.result]}>
                      <Text>{result?.NOHD?.eng ?? result.nohd.eng}m</Text>
                    </View> 
                  </View>
                  <View>
                    <Text>E.N.O.H.D.</Text> 
                    <View style={[styles.center, styles.result]}>
                      <Text>{result?.ENOHD?.pretty ?? result?.ENOHD ?? result.xnohd.pretty }</Text> 
                    </View>
                  </View>
                  <View>
                    <Text>Spot Size</Text> 
                    <View style={[styles.center, styles.result]}>
                      <Text>{result?.outputBeamDiameter?.outputBeamDiameterPretty ?? result.spotsize.eng ?? result.spotsize.d63Pretty.eng}</Text> 
                    </View>
                  </View>
                  <View>
                    <Text>Limit Aperture</Text> 
                    <View style={[styles.result]}>
                      <Text>Eye:{result?.limitingAperture.eye} mm</Text> 
                      <Text>Skin:{result?.limitingAperture.skin} mm </Text> 
                    </View>
                  </View>
                  <View>
                    <Text>Applicable Beam Area</Text> 
                    <View style={[styles.result]}>
                      <Text>Eye: {result?.applicableBeamArea.applicableBeamAreaPrettiest ?? result?.applicableBeamArea.applicableBeamAreaeyePrettiest } </Text> 
                      <Text>Skin:  {result?.skinApplicableArea?.skinApplicableAreaPrettiest ?? result?.applicableBeamArea.applicableBeamAreaSkinPrettiest} </Text> 
                    </View>
                  </View> 
              </View>
            <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Spectrum</Text> 
              </div>
            </View>
            <View style={styles.center}>
                <View style={styles.center}>
                  <Text style={[
                      styles.box, 
                      {backgroundColor: (() => {
                        if (result.spectrum.range == "Infrared") {
                          return "maroon"
                        }
                        else if (result.spectrum.range == "Ultraviolet") {
                          return "purple"
                        }
                          return "rgb(" + result.spectrum.rgb + ")"
                        })()} 
                  ]}>
                  </Text>
                </View>
                <View style={{alignSelf:"center", marginLeft: "10px"}}>
                  <Text>{result.spectrum.region}</Text>
                </View>
            </View>
        </View>
        <View style={styles.footer}>
          <Image src={laserFooter}></Image>
        </View>
      </Page>

    ) : null}

    {/**Correction Factors */}
    <Page size="LETTER" style={styles.page}>
    <Watermark />
    <View>
      <View style={[styles.row, styles.header]}>
              <View>
                <Image src={logo} style={styles.image}></Image>
            </View>
              <View>
                <Text style={{marginTop:"20px"}}>{date}</Text>
              </View>
      </View>
      <Text> C) Correction Factors</Text>
      <View style={styles.row}>
                <div style={styles.titleBox}>
                  <Text>Correction Factors & Time Bases</Text>
                </div>
      </View>
      <View style={[styles.row, styles.correctionFactors]}>
        <View>  
          {result.correctionFactors.map(cf => { 
            if (cf.name === "T1" || cf.name === "T2") {
              return null // Skip rendering for filtered items
            }
            return (
              <Text style={{ ...styles.result, fontSize: "17px" }}>{cf?.name}: {cf?.pretty}</Text>
            )
          })}
        </View>
        <View>
          {result.correctionFactors.map(cf => {
            if (cf.name === "T1" || cf.name === "T2") {
              return <Text style={{ ...styles.result, fontSize: "17px" }}> {cf?.name}: {cf?.pretty}</Text>// Skip rendering for filtered items
            }
          })}
        </View>  
      </View>
        {laserOperation === "Repetitively Pulsed" ?
        <>
          <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Pulse Data</Text>
              </div>
          </View>
          {result.isEmployed ? 
            <View style={styles.center}>
              <Text style={styles.secondaryText}>Multiple Pulses Occur Within the Period Ti</Text>
            </View>
          : null}
          <View style={[styles.row, styles.subTitle]}>
            <View>
              <Text>Pulse Train Duration</Text> 
              <View style={[styles.center, styles.result]}>
                <Text>{result?.pulseTrainDuration}</Text>
              </View> 
            </View>
            <View>
              <Text>Number of Pulses</Text> 
              <View style={[styles.center, styles.result]}>
                <Text>{result?.Nv2.pretty}</Text> 
              </View>
            </View>
            {result.isEmployed ? 
              <>
              <View>
                <Text>T<Text style={[styles.subscript]}>i</Text></Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.T_i}</Text> 
                </View>
              </View>
              <View>
                <Text>Pulses Contained in the Period T<Text style={[styles.subscript]}>i</Text></Text>
                  <View style={[styles.result]}>
                    <Text>{result?.PulsesWithinT_i}</Text> 
                  </View>
              </View>
              </>
            : null}
          </View>
        </>: null }
    </View>
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>        
    </Page>

    
     {/**Classification Details Condition 1 */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />

      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
        <Text> D) Classification Details</Text>
        <Text>These Accessible Emission Limit (AEL) has a computation time base of seconds.</Text>
        
        <Text>D.1) Condition 1</Text>

        <View style={[styles.row, styles.classification, { justifyContent:"space-around"}]}>
          <View>
            <Text>Laser Properties</Text> 
          </View>
          <View>
            <Text>Values</Text>
          </View>
        </View>

        <View style={[styles.row, { justifyContent:"space-around"}]}>
          <View>
            <Text>Aperture Stop</Text> 
            <Text>Aperture Distance</Text> 
            <Text>Angular Subtense</Text> 
            <Text>Value for C6</Text> 
            <Text>Acceptance Angle</Text> 

          </View>
          <View>
            <Text>{result.laserClass.condition1?.apertureStop === "NaNm" ? "N/A" : result.laserClass.condition1?.apertureStop}</Text> 
            <Text>{result.laserClass.condition1?.apertureDistance === "NaN m" ? "N/A" : result.laserClass.condition1?.apertureDistance}</Text> 
            <Text>{ result.laserClass.condition1.angularSubtense}</Text> 
            <Text>{ result.laserClass.condition1?.C6}</Text> 
            <Text>{ result.laserClass.condition1.angleOfAcceptance}</Text> 
          </View>
        </View>

        <View style={[styles.row, styles.classification, laserOperation === "Repetitively Pulsed" ? styles.repPulsedAELHeader : null, { justifyContent:"space-around"}]}>
          <View>
            <Text style={{marginLeft:"10px"}}>Laser Class</Text> 
          </View>
          <View>
            <Text style={{ marginLeft: laserOperation === "Repetitively Pulsed" ? "25px" : "55px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>single</Text></Text>
          </View> 
          {laserOperation === "Repetitively Pulsed" ? (
          <>
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.Train</Text></Text>
          </View> 
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.T</Text></Text>
          </View> 
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "28px" : "65px"}}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>T</Text></Text>
          </View> 
          </>
          ) : null}
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "30px" : "55px" }}>EHV</Text>
          </View>
          <View >
            <Text style={{marginLeft:"40px"}}>Pass/Fail</Text>
          </View>
        </View>

        <View style={[styles.row, laserOperation === "Repetitively Pulsed" ? styles.repPulsedAEL : null, { justifyContent:"space-around"}]}>
          <View>
            <Text>Class 1</Text> 
            <Text>Class 2</Text> 
            <Text>Class 3r</Text> 
            <Text>Class 3B</Text> 
          </View>
          <View>
            <Text>{result.laserClass.condition1.AEL_standard.single.c1.joulesPretty === "nullJ" ? result?.laserClass?.condition1?.AEL_standard?.single?.c1?.joulesPerSqmPretty : result.laserClass.condition1.AEL_standard.single.c1.joulesPretty != "nullJ" ?  result?.laserClass?.condition1?.AEL_standard?.single?.c1?.joulesPretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.single.c2?.joulesPretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.single.c3r.joulesPretty === "nullJ" ? result.laserClass.condition1.AEL_standard.single.c3r.joulesPerSqmPretty : result.laserClass.condition1.AEL_standard.single.c3r.joulesPretty != "nullJ" ? result.laserClass.condition1.AEL_standard.single.c3r.joulesPretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.single.c3b.joulesPretty ?? "-"} </Text> 
          </View>

          {laserOperation === "Repetitively Pulsed" || form.laserOperation === "FO_repetitivelyPulsed" ? (
         <>
          {/**pulse train */}
          <View>
            <Text>{result.laserClass.condition1.AEL_standard.pulseTrain.c1?.pretty ??  "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.pulseTrain.c2?.raw === 0 ? "-" : result.laserClass.condition1.AEL_standard.pulseTrain.c2?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.pulseTrain.c3r?.pretty ?? "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.pulseTrain.c3b?.pretty ?? "-"} </Text> 
          </View>
          {/**AEL s.p.T */}
          <View >
            <Text>{result.laserClass.condition1.AEL_standard.AELSPT.c1.pretty === "nullJ" ? result.laserClass.condition1.AEL_standard.AELSPT.c1.pretty : result.laserClass.condition1.AEL_standard.AELSPT.c1.pretty != "nullJ" ?  result.laserClass.condition1.AEL_standard.AELSPT.c1.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.AELSPT.c2?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.AELSPT.c3r.pretty === "nullJ" ? result.laserClass.condition1.AEL_standard.AELSPT.c3r.pretty : result.laserClass.condition1.AEL_standard.AELSPT.c3r.pretty != "nullJ" ? result.laserClass.condition1.AEL_standard.AELSPT.c3r.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.AELSPT.c3b.pretty ?? "-"} </Text> 
          </View>
           {/**average */}
          <View >
            <Text>{result.laserClass.condition1.AEL_standard.Average.c1.pretty === "nullJ" ? result.laserClass.condition1.AEL_standard.Average.c1.pretty : result.laserClass.condition1.AEL_standard.Average.c1.pretty != "nullJ" ?  result.laserClass.condition1.AEL_standard.Average.c1.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.Average.c2?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.Average.c3r.pretty === "nullJ" ? result.laserClass.condition1.AEL_standard.Average.c3r.pretty : result.laserClass.condition1.AEL_standard.Average.c3r.pretty != "nullJ" ? result.laserClass.condition1.AEL_standard.Average.c3r.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.Average.c3b.pretty ?? "-"} </Text> 
          </View>
          </>
          ) : null}
          
          <View>
            <Text>{result.laserClass.condition1.AEL_standard?.Excess.c1excess?.pretty === "NaN" ? "--" : result.laserClass.condition1.AEL_standard?.Excess.c1excess?.pretty}</Text> 
            <Text>{result.laserClass.condition1.AEL_standard?.Excess.c2excess?.pretty === "NaN" || result.laserClass.condition1.AEL_standard?.Excess.c2excess?.raw === null ? "-" : result.laserClass.condition1.AEL_standard?.Excess.c2excess?.pretty}</Text> 
            <Text>{result.laserClass.condition1.AEL_standard?.Excess.c3rexcess?.pretty === "NaN" ? "--" : result.laserClass.condition1.AEL_standard?.Excess.c3rexcess?.pretty}</Text>
            <Text>{result.laserClass.condition1.AEL_standard?.Excess.c3bexcess?.pretty === "NaN" ? "--" : result.laserClass.condition1.AEL_standard?.Excess.c3bexcess?.pretty}</Text> 
          </View>
          <View>
            <Text>{result.laserClass.condition1.AEL_standard.Excess.c1excess.raw > 1 ? "Fail" : result.laserClass.condition1.AEL_standard.Excess.c1excess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.Excess.c2excess.raw === null ? "-" : result.laserClass.condition1.AEL_standard.Excess.c2excess.raw > 1 ? "Fail" : result.laserClass.condition1.AEL_standard.Excess.c2excess.raw <= 1 ? "Pass" : "-" }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.Excess.c3rexcess.raw > 1 ? "Fail" : result.laserClass.condition1.AEL_standard.Excess.c3rexcess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition1.AEL_standard.Excess.c3bexcess.raw > 1 ? "Fail" : result.laserClass.condition1.AEL_standard.Excess.c3bexcess.raw <= 1 ? "Pass" : null }</Text> 
        </View>
        </View>

        <View style={[styles.row, styles.AccessibleEmission]}>
          <Text>Accessible Emission: {result?.laserClass?.condition1?.collectedEmissionJoules?.joulesPretty}</Text>
        </View>

      </View>
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>

    {form.laserOperation === "FO_cw" || form.laserOperation === "FO_singlePulse" || form.laserOperation === "FO_repetitivelyPulsed" ? ( 
      <>
      {/**Classification Details Condition 2 */ }
      <Page size="LETTER" style={styles.page}>
        <Watermark />

        <View>
          <View style={[styles.row, styles.header]}>
              <View>
                <Image src={logo} style={styles.image}></Image>
            </View>
              <View>
                <Text style={{marginTop:"20px"}}>{date}</Text>
            </View>
          </View>
        
        <Text>D.2) Condition 2</Text>

          <View style={[styles.row, styles.classification, { justifyContent:"space-around"}]}>
            <View>
              <Text>Laser Properties</Text> 
            </View>
            <View>
              <Text>Values</Text>
            </View>
          </View>

          <View style={[styles.row, { justifyContent:"space-around"}]}>
            <View>
              <Text>Aperture Stop</Text> 
              <Text>Aperture Distance</Text> 
              <Text>Angular Subtense</Text> 
              <Text>Value for C6</Text> 
              <Text>Acceptance Angle</Text> 

            </View>
            <View>
              <Text>{result.laserClass.condition2?.apertureStop === "NaNm" ? "N/A" : result.laserClass.condition2?.apertureStop}</Text> 
              <Text>{result.laserClass.condition2?.apertureDistance === "NaN m" ? "N/A" : result.laserClass.condition2?.apertureDistance}</Text> 
              <Text>{ result.laserClass.condition2.angularSubtense}</Text> 
              <Text>{ result.laserClass.condition2?.C6}</Text> 
              <Text>{ result.laserClass.condition2.angleOfAcceptance}</Text> 
            </View>
          </View>

          <View style={[styles.row, styles.classification, laserOperation === "Repetitively Pulsed" ? styles.repPulsedAELHeader : null, { justifyContent:"space-around"}]}>
            <View>
              <Text style={{marginLeft:"10px"}}>Laser Class</Text> 
            </View>
            <View>
              <Text style={{ marginLeft: laserOperation === "Repetitively Pulsed" ? "25px" : "55px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>single</Text></Text>
            </View> 
            {laserOperation === "Repetitively Pulsed" ? (
            <>
            <View>
              <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.Train</Text></Text>
            </View> 
            <View>
              <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.T</Text></Text>
            </View> 
            <View>
              <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "28px" : "65px"}}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>T</Text></Text>
            </View> 
            </>
            ) : null}
            <View>
              <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "30px" : "55px" }}>EHV</Text>
            </View>
            <View >
              <Text style={{marginLeft:"40px"}}>Pass/Fail</Text>
            </View>
          </View>

          <View style={[styles.row, laserOperation === "Repetitively Pulsed" ? styles.repPulsedAEL : null, { justifyContent:"space-around"}]}>
            <View>
              <Text>Class 1</Text> 
              <Text>Class 2</Text> 
              <Text>Class 3r</Text> 
              <Text>Class 3B</Text> 
            </View>
            <View>
              <Text>{result.laserClass.condition2.AEL_standard.single.c1.joulesPretty === "nullJ" ? result.laserClass.condition2.AEL_standard.single.c1.joulesPerSqmPretty : result.laserClass.condition2.AEL_standard.single.c1.joulesPretty != "nullJ" ?  result.laserClass.condition2.AEL_standard.single.c1.joulesPretty : "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.single.c2?.joulesPretty ?? "-"}</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.single.c3r.joulesPretty === "nullJ" ? result.laserClass.condition2.AEL_standard.single.c3r.joulesPerSqmPretty : result.laserClass.condition2.AEL_standard.single.c3r.joulesPretty != "nullJ" ? result.laserClass.condition2.AEL_standard.single.c3r.joulesPretty : "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.single.c3b.joulesPretty ?? "-"}</Text> 
              
            </View>

            {laserOperation === "Repetitively Pulsed" || form.laserOperation === "FO_repetitivelyPulsed" ? (
            <>
            {/**pulse train */}
            <View>
              <Text>{result.laserClass.condition2.AEL_standard.pulseTrain.c1?.pretty ??  "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.pulseTrain.c2?.raw === 0 ? "-" : result.laserClass.condition2.AEL_standard.pulseTrain.c2?.pretty ?? "-"}</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.pulseTrain.c3r?.pretty ?? "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.pulseTrain.c3b?.pretty ?? "-"} </Text> 
            </View>
             {/**AEL s.p.T */}
            <View >
              <Text>{result.laserClass.condition2.AEL_standard.AELSPT.c1.pretty === "nullJ" ? result.laserClass.condition2.AEL_standard.AELSPT.c1.pretty : result.laserClass.condition2.AEL_standard.AELSPT.c1.pretty != "nullJ" ?  result.laserClass.condition2.AEL_standard.AELSPT.c1.pretty : "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.AELSPT.c2?.pretty ?? "-"}</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.AELSPT.c3r.pretty === "nullJ" ? result.laserClass.condition2.AEL_standard.AELSPT.c3r.pretty : result.laserClass.condition2.AEL_standard.AELSPT.c3r.pretty != "nullJ" ? result.laserClass.condition2.AEL_standard.AELSPT.c3r.pretty : "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.AELSPT.c3b.pretty ?? "-"} </Text> 
            </View>
            {/**average */}
            <View >
              <Text>{result.laserClass.condition2.AEL_standard.Average.c1.joulesPretty === "nullJ" ? result.laserClass.condition2.AEL_standard.Average.c1.joulesPerSqmPretty : result.laserClass.condition2.AEL_standard.Average.c1.joulesPretty != "nullJ" ?  result.laserClass.condition2.AEL_standard.Average.c1.joulesPretty : "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.Average.c2?.joulesPretty ?? "-"}</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.Average.c3r.joulesPretty === "nullJ" ? result.laserClass.condition2.AEL_standard.Average.c3r.joulesPerSqmPretty : result.laserClass.condition2.AEL_standard.Average.c3r.joulesPretty != "nullJ" ? result.laserClass.condition2.AEL_standard.Average.c3r.joulesPretty : "-" }</Text> 
              <Text>{result.laserClass.condition2.AEL_standard.Average.c3b.joulesPretty ?? "-"} </Text> 
            </View>
            </>
            ) : null}

          <View>
              <Text>{result.laserClass.condition2.AEL_standard?.Excess.c1excess?.pretty === "NaN" ? "--" : result.laserClass.condition2.AEL_standard?.Excess.c1excess?.pretty}</Text> 
              <Text>{result.laserClass.condition2.AEL_standard?.Excess.c2excess?.pretty === "NaN" || result.laserClass.condition2.AEL_standard?.Excess.c2excess?.raw === null ? "--" : result.laserClass.condition2.AEL_standard?.Excess.c2excess?.pretty}</Text> 
              <Text>{result.laserClass.condition2.AEL_standard?.Excess.c3rexcess?.pretty === "NaN" ? "--" : result.laserClass.condition2.AEL_standard?.Excess.c3rexcess?.pretty}</Text>
              <Text>{result.laserClass.condition2.AEL_standard?.Excess.c3bexcess?.pretty === "NaN" ? "--" : result.laserClass.condition2.AEL_standard?.Excess.c3bexcess?.pretty}</Text> 
            
            </View>
            <View>
            <Text>{result.laserClass.condition2.AEL_standard.Excess.c1excess.raw > 1 ? "Fail" : result.laserClass.condition2.AEL_standard.Excess.c1excess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition2.AEL_standard.Excess.c2excess.raw === null ? "-" : result.laserClass.condition2.AEL_standard.Excess.c2excess.raw > 1 ? "Fail" : result.laserClass.condition2.AEL_standard.Excess.c2excess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition2.AEL_standard.Excess.c3rexcess.raw > 1 ? "Fail" : result.laserClass.condition2.AEL_standard.Excess.c3rexcess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition2.AEL_standard.Excess.c3bexcess.raw > 1 ? "Fail" : result.laserClass.condition2.AEL_standard.Excess.c3bexcess.raw <= 1 ? "Pass" : null }</Text> 
            </View>
          </View>
          <View style={[styles.row, styles.AccessibleEmission]}>
          <Text>Accessible Emission: {result?.laserClass?.condition2?.collectedEmissionJoules?.joulesPretty}</Text>
        </View>
        </View>
      
        <View style={styles.footer}>
          <Image src={laserFooter}></Image>
        </View>
      </Page>
      </>
    ) : null}
    {/**Classification Details Condition 3 */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />

      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
      
      <Text>D.2) Condition 3</Text>

        <View style={[styles.row, styles.classification, { justifyContent:"space-around"}]}>
          <View>
            <Text>Laser Properties</Text> 
          </View>
          <View>
            <Text>Values</Text>
          </View>
        </View>

        <View style={[styles.row, { justifyContent:"space-around"}]}>
          <View>
            <Text>Aperture Stop</Text> 
            <Text>Aperture Distance</Text> 
            <Text>Angular Subtense</Text> 
            <Text>Value for C6</Text> 
            <Text>Acceptance Angle</Text> 

          </View>
          <View>
            <Text>{result.laserClass.condition3?.apertureStop === "NaNm" ? "N/A" : result.laserClass.condition3?.apertureStop}</Text> 
            <Text>{result.laserClass.condition3?.apertureDistance === "NaN m" ? "N/A" : result.laserClass.condition3?.apertureDistance}</Text> 
            <Text>{ result.laserClass.condition3.angularSubtense}</Text> 
            <Text>{ result.laserClass.condition3?.C6}</Text> 
            <Text>{ result.laserClass.condition3.angleOfAcceptance}</Text> 
          </View>
        </View>

        <View style={[styles.row, styles.classification, laserOperation === "Repetitively Pulsed" ? styles.repPulsedAELHeader : null, { justifyContent:"space-around"}]}>
          <View>
            <Text style={{marginLeft:"10px"}}>Laser Class</Text> 
          </View>
          <View>
            <Text style={{ marginLeft: laserOperation === "Repetitively Pulsed" ? "25px" : "55px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>single</Text></Text>
          </View> 
          {laserOperation === "Repetitively Pulsed" ? (
          <>
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.Train</Text></Text>
          </View> 
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.T</Text></Text>
          </View> 
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "28px" : "65px"}}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>T</Text></Text>
          </View>
          </>
          ) : null}
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "30px" : "55px" }}>EHV</Text>
          </View>
          <View >
            <Text style={{marginLeft:"40px"}}>Pass/Fail</Text>
          </View>
        </View>

        <View style={[styles.row, laserOperation === "Repetitively Pulsed" ? styles.repPulsedAEL : null, { justifyContent:"space-around"}]}>
          <View>
            <Text>Class 1</Text> 
            <Text>Class 2</Text> 
            <Text>Class 3r</Text> 
            <Text>Class 3B</Text> 
          </View>
           <View>
            <Text>{result.laserClass.condition3.AEL_standard.single.c1.joulesPretty === "nullJ" ? result.laserClass.condition3.AEL_standard.single.c1.joulesPerSqmPretty : result.laserClass.condition3.AEL_standard.single.c1.joulesPretty != "nullJ" ?  result.laserClass.condition3.AEL_standard.single.c1.joulesPretty : "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.single.c2?.joulesPretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.single.c3r.joulesPretty === "nullJ" ? result.laserClass.condition3.AEL_standard.single.c3r.joulesPerSqmPretty : result.laserClass.condition3.AEL_standard.single.c3r.joulesPretty != "nullJ" ? result.laserClass.condition3.AEL_standard.single.c3r.joulesPretty : "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.single.c3b.joulesPretty ?? "-"}</Text> 
            
          </View>

          {laserOperation === "Repetitively Pulsed" ? (
          <>
          {/**pulse train */}
          <View>
            <Text>{result.laserClass.condition3.AEL_standard.pulseTrain.c1?.pretty ??  "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.pulseTrain.c2?.raw === 0 ? "-" : result.laserClass.condition3.AEL_standard.pulseTrain.c2?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.pulseTrain.c3r?.pretty ?? "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.pulseTrain.c3b?.pretty ?? "-"} </Text> 
          </View>
          {/**AEL s.p.T */}
          <View >
            <Text>{result.laserClass.condition3.AEL_standard.AELSPT.c1.pretty === "nullJ" ? result.laserClass.condition3.AEL_standard.AELSPT.c1.pretty : result.laserClass.condition3.AEL_standard.AELSPT.c1.pretty != "nullJ" ?  result.laserClass.condition3.AEL_standard.AELSPT.c1.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.AELSPT.c2?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.AELSPT.c3r.pretty === "nullJ" ? result.laserClass.condition3.AEL_standard.AELSPT.c3r.pretty : result.laserClass.condition3.AEL_standard.AELSPT.c3r.pretty != "nullJ" ? result.laserClass.condition3.AEL_standard.AELSPT.c3r.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.AELSPT.c3b.pretty ?? "-"} </Text> 
          </View>
           {/**average */}
          <View >
            <Text>{result.laserClass.condition3.AEL_standard.Average.c1.pretty === "nullJ" ? result.laserClass.condition3.AEL_standard.Average.c1.pretty : result.laserClass.condition3.AEL_standard.Average.c1.pretty != "nullJ" ?  result.laserClass.condition3.AEL_standard.Average.c1.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.Average.c2?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.Average.c3r.pretty === "nullJ" ? result.laserClass.condition3.AEL_standard.Average.c3r.pretty : result.laserClass.condition3.AEL_standard.Average.c3r.pretty != "nullJ" ? result.laserClass.condition3.AEL_standard.Average.c3r.pretty : "-" }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.Average.c3b.pretty ?? "-"} </Text> 
          </View>
          </>
          ) : null}

         <View>
            <Text>{result.laserClass.condition3.AEL_standard?.Excess.c1excess?.pretty === "NaN" ? "--" : result.laserClass.condition3.AEL_standard?.Excess.c1excess?.pretty}</Text> 
            <Text>{result.laserClass.condition3.AEL_standard?.Excess.c2excess?.pretty === "NaN" || result.laserClass.condition3.AEL_standard?.Excess.c2excess?.raw === null ? "-" : result.laserClass.condition3.AEL_standard?.Excess.c2excess?.pretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition3.AEL_standard?.Excess.c3rexcess?.pretty === "NaN" ? "--" : result.laserClass.condition3.AEL_standard?.Excess.c3rexcess?.pretty}</Text>
            <Text>{result.laserClass.condition3.AEL_standard?.Excess.c3bexcess?.pretty === "NaN" ? "--" : result.laserClass.condition3.AEL_standard?.Excess.c3bexcess?.pretty}</Text> 
           
          </View>
          <View>
            <Text>{result.laserClass.condition3.AEL_standard.Excess.c1excess.raw > 1 ? "Fail" : result.laserClass.condition3.AEL_standard.Excess.c1excess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.Excess.c2excess.raw === null ? "-" :result.laserClass.condition3.AEL_standard.Excess.c2excess.raw > 1 ? "Fail" : result.laserClass.condition3.AEL_standard.Excess.c2excess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.Excess.c3rexcess.raw > 1 ? "Fail" : result.laserClass.condition3.AEL_standard.Excess.c3rexcess.raw <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition3.AEL_standard.Excess.c3bexcess.raw > 1 ? "Fail" : result.laserClass.condition3.AEL_standard.Excess.c3bexcess.raw <= 1 ? "Pass" : null }</Text> 
          </View>
        </View>
        <View style={[styles.row, styles.AccessibleEmission]}>
          <Text>Accessible Emission: {result?.laserClass?.condition3?.collectedEmissionJoules?.joulesPretty}</Text>
        </View>
        <View style={[styles.row, styles.center]}>
          <div  style={[styles.center, styles.classVerdict]}>
            <Text>This Product is Classified as a Class {result.laserClass.Class} Laser</Text>
          </div>
        </View>
      </View>
    
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>

    {/**Eyewear */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />

      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View> 
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
        <Text> E) Safety Eyewears and Filter</Text>
       
        <View style={styles.row}>
          <div style={styles.titleBox}>
                <Text>Optical Density, LB Rating, and RB Number</Text> 
          </div>
        </View>

        <View style={styles.row}>
         
          <View style={[styles.row, styles.subTitle]}>
            <View style={{ alignSelf: "center" }}><Text>Results at {operation.distance?.num} {operation.distance?.unit !== false ? operation.distance?.unit : null}m</Text></View>
              <View style={{justifyContent:"center"}}>
                <Text>Nominal O.D.</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.NominalOD.OD}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Glass</Text> 
                <View style={[styles.center, styles.result]}>
                    <Text>{result?.Glass_average ?? result.GlassAverage}  {result.Glass}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Plastic</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.Plastic_average ?? result.PlasticAverage}  {result.Plastic}</Text>
                </View>
              </View>
              <View>
                <Text>RB Number </Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.RBNumber}</Text>
                </View>
              </View>
          </View>
        </View>
        <View style={[styles.row, { width: "100%", borderBottom: "1px solid black", marginBottom:"20px"}]}></View>
        
        <View style={[styles.row, styles.subTitle]}>
              <View style={{ alignSelf: "center" }}><Text>Results at 0.1 m</Text> </View>
              <View style={{justifyContent:"center"}}>
                <Text>Nominal O.D.</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.NominalOD_distance.OD}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Glass</Text> 
                <View style={[styles.center, styles.result]}>
                    <Text>{result?.GlassAverage_Distance }  { result.Glass_Distance}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Plastic</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.PlasticAverage_Distance }  { result.Plastic_Distance}</Text>
                </View>
              </View>
              <View>
                <Text>RB Number </Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.RBNumber_distance}</Text>
                </View>
              </View>
        </View>    
      </View>
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>
  </Document>
)
}
