import React, {useState} from "react";
import { Space, Typography, Divider, Tooltip, Flex, Row, Col, Collapse, Tabs} from "antd";
import ResultCard from "../../../../components/ResultCard";
import { EyewearInfo } from "../../../../components/infographicsModal/EyewearInfo";
import { MathJax } from 'better-react-mathjax';
import decimalForm from "../../../../components/DecimalForm";



export function EyewearResults(eyewearResults){
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [info, setInfo] = useState(null)

    
   
    const handleClick = (e) => {
        setIsModalOpen(true)
        setInfo(e)
    
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    let props = eyewearResults.props
   
    const textCenter = { display: 'flex', justifyContent: 'center' }
    let message = "The LB number indicates the level of protection provided by glass/plastic. ".concat((props.result.Glass != null && props.result.Plastic != null) ? ""
        : "If the pulse duration exceeds the exposure duration specified in Table B.1, a scale number for I is not applicable, and only a scale number for the D mode should be computed accordingly.")
    
    const highlight = {
        display: 'inline-block',
        fontFamily: 'monospace',
        // backgroundColor: '#f5f5f5',
        // border: '1px solid #d9d9d9',
        borderRadius: '4px',
        padding: '2px 4px',
        fontSize: '90%',
        color: '#D63384',
        fontWeight: 'bold'        
    };
    const {Paragraph, Text, Title} = Typography

    let beamDiameter = props?.result?.outputBeamDiameter?.outputBeamDiameter_mm ?? props?.result?.spotsize?.d63  ?? props?.result?.outputBeamDiameter?.spotSize ?? props?.result?.outputBeamDiameter2 ??  props?.result?.outputBeamDiameter?.d63
    
    let useBeamDiameter = beamDiameter > 15 ? 15 : beamDiameter

    let useAE =  props?.result?.AE?.joulesPerSqmPretty.eng ?? props?.result?.AE?.joulesPerSqmPretty

    let displayMPE =  props?.result?.MPE?.joulesPerSqmPretty?.eng ?? props?.result?.MPEsingle?.joulesPerSqmPretty?.eng ?? props?.result?.MPE?.mostRestrictive?.joulesPerSqmPretty ?? props?.result?.ExtendedMPE?.joulesPerSqmPretty?.eng
    let useMPE = props?.result?.MPE?.joulesPerSqm ?? props?.result?.MPEsingle?.joulesPerSqm ?? props?.result?.MPE?.mostRestrictive?.joulesPerSqm ?? props?.result?.ExtendedMPE?.joulesPerSqm
    let useActualAE 
    let displayActualAE
    let wavelength = decimalForm(props?.data?.cw?.wavelength ?? props?.data?.singlePulse?.wavelength ?? props?.data?.repetitivelyPulsed?.wavelength ?? props?.data?.FO_cw?.wavelength ?? props?.data?.FO_singlePulse?.wavelength ?? props?.data?.FO_repetitivelyPulsed?.wavelength ?? props?.data?.diffuseReflectiveCW?.wavelength ?? props?.data?.diffuseReflectiveSP?.wavelength ?? props?.data?.diffuseReflectiveRP?.wavelength)
    let pulseDuration = decimalForm(props?.data?.cw?.time ?? props?.data?.singlePulse?.pulseDuration ?? props?.data?.repetitivelyPulsed?.pulseDuration ?? props?.data?.FO_cw?.time ?? props?.data?.FO_singlePulse?.duration ?? props?.data?.FO_repetitivelyPulsed?.duration ?? props?.data?.diffuseReflectiveCW?.time ??  props?.data?.diffuseReflectiveSP?.pulseDuration ?? props?.data?.diffuseReflectiveRP?.pulseDuration)
    let laserOperation = props.data.laserOperation
    let unit
    let powerEnergy = props?.data?.cw?.power 
    ?? props?.data?.singlePulse?.energy 
    ?? props?.data?.singlePulse?.peakPower 
    ?? props?.data?.repetitivelyPulsed?.energy 
    ?? props?.data?.repetitivelyPulsed?.peakPower 
    ?? props?.data?.repetitivelyPulsed?.averagePower 
    ?? props?.data?.FO_cw?.power 
    ?? props?.data?.FO_singlePulse?.power 
    ?? props?.data?.FO_repetitivelyPulsed?.peakPower 
    ?? props?.data?.diffuseReflectiveCW?.power 
    ?? props?.data?.diffuseReflectiveSP?.energy 
    ?? props?.data?.diffuseReflectiveSP?.peakPower 
    ?? props?.data?.diffuseReflectiveRP?.energy 
    ?? props?.data?.diffuseReflectiveRP?.peakPower 
    ?? props?.data?.diffuseReflectiveRP?.averagePower

    let diameterDistance = props?.result?.outputBeamDiameter?.outputBeamDiameter_distance_mm
    ?? props?.result?.spotsize?.d63  ?? props?.result?.outputBeamDiameter?.outputBeamDiameter_distance_mm 
    ??( props?.result?.outputBeamDiameter?.d63_distance ?? props?.result?.outputBeamDiameter?.spotSize_distance ) * 1000
    

    let useDiameterDistance = diameterDistance > 15 ? 15 : diameterDistance

    function correctionFactor(type, distance = false){
        return Math.pow(distance ? useDiameterDistance : useBeamDiameter, type === "glass" ? 1.1693 : 1.2233)
    }

    if (wavelength >= 180e-9 && wavelength <= 315e-9) {
        if ((pulseDuration >= 30000 && laserOperation !== "RP") || laserOperation === "D" || pulseDuration < 1e-9) {
            useActualAE = props.result.AE.actual_wattsPerSqm;
            displayActualAE = props.result.AE?.actual_wattsPerSqmPretty?.eng ?? props?.result?.AE?.actual_wattsPerSqmPretty ?? props?.result?.AE?.albedo_wattsPerSqmPretty;
            unit = "W/m²";
        } else {
            useActualAE = props.result.AE.actual_joulesPerSqm;
            displayActualAE =  props.result.AE?.actual_joulesPerSqmPretty?.eng ?? props?.result?.AE?.actual_joulesPerSqmPretty;
            unit = "J/m²";
        }
    } else if (wavelength > 315e-9 && wavelength <= 1400e-9) {
        if ((pulseDuration > 5e-4 && laserOperation !== "RP") || laserOperation === "D" || pulseDuration < 1e-9) {
            useActualAE = props.result.AE.actual_wattsPerSqm;
            displayActualAE =  props.result.AE?.actual_wattsPerSqmPretty?.eng ?? props?.result?.AE?.actual_wattsPerSqmPretty ?? props?.result?.AE?.albedo_wattsPerSqmPretty;
            unit = "W/m²";
        } else {
            useActualAE = props.result.AE.actual_joulesPerSqm;
            displayActualAE = props.result.AE?.actual_joulesPerSqmPretty?.eng ?? props?.result?.AE?.actual_joulesPerSqmPretty ;
            unit = "J/m²";
        }
    } else if (wavelength > 1400e-9 && wavelength <= 1e3) {
        if ((pulseDuration > 0.1 && laserOperation !== "RP") || laserOperation === "D" || pulseDuration < 1e-9) {
            useActualAE = props.result.AE.actual_wattsPerSqm;
            displayActualAE = props.result.AE?.actual_wattsPerSqmPretty?.eng ?? props?.result?.AE?.actual_wattsPerSqmPretty ?? props?.result?.AE?.albedo_wattsPerSqmPretty;
            unit = "W/m²";
        } else {
            useActualAE = props.result.AE.actual_joulesPerSqm;
            displayActualAE = props.result.AE?.actual_joulesPerSqmPretty?.eng ?? props?.result?.AE?.actual_joulesPerSqmPretty;
            unit = "J/m²";
        }
    }
    displayActualAE = displayActualAE.includes("/m²") ? displayActualAE : displayActualAE + unit || "N/A"
    useAE =  useAE.includes("J/m²") ? useAE : useAE + "J/m²" 
    displayMPE = displayMPE.includes("J/m²") ? displayMPE : displayMPE + "J/m²"

    
    return (
        <> 

        {/* <EyewearInfo
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          info={info}
        /> */}
        <Row justify="center" align="center" >
            <Divider>
                <Typography>Optical Density, LB Rating, and RB Number</Typography>
            </Divider>
            <Col sm={24} md={24} lg={24} xl={24} xxl={3} style={{alignContent:"center"}}>
                <Flex wrap="true" style={{justifyContent: "center", justifySelf:"center"}}>
                    <strong style={{ display: "flex", alignItems: "center"}}>Results at {props.result.distance} m</strong>
                </Flex>
            </Col>
            <Flex wrap="true">
                <ResultCard >
                    <div onClick={()=>handleClick("nominalOD")} style={{cursor:"pointer"}}>
                        <strong style={textCenter} > Nominal O.D.</strong>
                        <>
                        <Space style={textCenter}>
                            <strong>
                                <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                                    <Typography>{props.result.NominalOD.OD}</Typography>
                                </Tooltip>
                            </strong>
                        </Space>
                        </>
                    </div>
                </ResultCard>      
                <ResultCard>
                    <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                        <strong style={textCenter}>RB Number</strong>
                        <Typography style={textCenter}>{props.result.RBNumber}</Typography>
                    </Tooltip>
                </ResultCard>
            </Flex>
            <Flex wrap="true">
                <ResultCard>
                    <div onClick={()=>handleClick("LBNumberGlass")} style={{cursor:"pointer"}}>
                        <Tooltip title={message}>
                            <strong style={textCenter}>LB Number for Glass</strong>
                            <>
                                {props.result.Glass_average != null ? (
                                <>
                                <Space style={textCenter}>
                                    <Typography style={textCenter}>{props.result.Glass_average}</Typography>
                                    {props.result.Glass == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                    <Typography style={textCenter}>{props.result.Glass}</Typography>
                                </Space>
                                </> 
                                ) : <Typography style={textCenter}>{props.result.Glass}</Typography>} 
                            </>
                        </Tooltip>
                    </div>
                </ResultCard>
                <ResultCard>
                  
                        <Tooltip title={message}>
                            <strong style={textCenter}>LB Number for Plastic</strong>
                            {props.result.Plastic_average != null ? (
                                <Space style={textCenter}>
                                    <Typography style={textCenter}>{props.result.Plastic_average}</Typography>
                                    {props.result.Plastic == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                    <Typography style={textCenter}>{props.result.Plastic}</Typography>
                                </Space>
                            ) : <Typography style={textCenter}>{props.result.Plastic}</Typography>
                        }
                        </Tooltip>
                
                </ResultCard>
            </Flex>

            <Row style={{width:"100%", margin:"10px 0px 30px 0px"}}>
                <Collapse   
                    style={{width:"100%"}}
                    size="small"
                    items={[
                        {
                            key: '1',
                            label: 'More Info',
                            children: (
                                
                                    <Tabs 
                                        items= {[
                                            
                                            {
                                                key:1,
                                                label:"Nominal O.D.",
                                                children: (
                                                    <MathJax key="1">
                                                    <Typography>
                                                        <Paragraph>
                                                        <Text strong>Nominal Optical Density (OD)</Text> is calculated as the logarithm of the quotient of the Accessible Emission (AE) and Maximum Permissible Exposure (MPE):
                                                        </Paragraph>
                                                        <Paragraph>
                                                        <Text strong style={{...highlight, fontSize: "15px"}}>{`\\(OD = \\log(\\frac{AE}{MPE})\\)`}</Text>
                                                        </Paragraph>
                                                        <Paragraph>
                                                        The formula is used to ensure laser safety by determining the required attenuation of laser exposure.
                                                        </Paragraph>
                                                        <Paragraph>
                                                        Where:
                                                        <ul >
                                                            <li>
                                                            <Text strong style={{...highlight, fontSize: "15px"}}>AE = { useAE}</Text>
                                                            </li>
                                                            <li>
                                                            <Text strong style={{...highlight, fontSize: "15px"}}>MPE = { displayMPE}</Text>
                                                            </li>
                                                        </ul>
                                                        </Paragraph>
                                                        <Paragraph>
                                                            <Text>Solution:</Text>
                                                            <br/>
                                                            <Text strong style={{...highlight, fontSize: "20px"}}>   {`\\(OD = \\log(\\frac{\\text{${(props.result.AE.joulesPerSqmPretty.eng ?? props.result?.AE?.joulesPerSqmPretty).includes("J/m²") ? props.result.AE.joulesPerSqmPretty.eng ?? props.result?.AE?.joulesPerSqmPretty : props.result.AE.joulesPerSqmPretty.eng + "J/m²" ?? props.result?.AE?.joulesPerSqmPretty + "J/m²"}}}{\\text{${displayMPE}}})\\)`}</Text>
                                                            <br/>
                                                            <Text strong style={{...highlight, fontSize: "20px"}}> {`\\(OD = \\text{${Math.log10(props.result?.AE?.joulesPerSqm / useMPE).toFixed(1)}}\\)`}</Text>
                                                        </Paragraph>
                                                        <Paragraph>
                                                        <Text type="secondary">
                                                            Note: The higher the OD, the greater the attenuation required to protect from hazardous laser exposure.
                                                        </Text>
                                                        </Paragraph>
                                                    </Typography>
                                                    </MathJax>
                                                )
                                                
                                            },
                                            {
                                                key:2,
                                                label:"RB Number",
                                                children: (
                                                    <MathJax>
                                                        <Typography>
                                                            <Paragraph>
                                                            <Text strong>
                                                                The EN 208 standard only applies to lasers that emit wavelengths in the visible section of the wavelength (400 to 700nm).
                                                            </Text>
                                                            </Paragraph>
                                                            <Paragraph> 
                                                                If the beam diameter is larger than the limit aperture of 7mm, then the selection can be based on the fraction of the power that would pass through a 7mm aperture.
                                                            </Paragraph>
                                                            <Paragraph >
                                                            Where:
                                                                <ul >
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}>Wavelength = {wavelength * 1000000000} nm </Text></li>
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}> Power/Energy = {powerEnergy?.num}{powerEnergy?.unit} </Text></li>
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}> Beam diameter =  {beamDiameter.toFixed(2)} mm</Text></li>
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}> Limit Aperture = 7 mm </Text></li>
                                                                </ul>
                                                            
                                                            </Paragraph>
                                                                {wavelength * 1000000000 >= 400 && wavelength * 1000000000 < 700 ? (

                                                                    <Paragraph>
                                                                        <Text >Formula:</Text>
                                                                            <br/>
                                                                            {beamDiameter > 7 ? (
                                                                            <>
                                                                                <Text>If the beam diameter is larger than the 7mm limit aperture: </Text>
                                                                                <br/>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>{`\\(\\text{Adjusted Power/Energy = Power/Energy} \\cdot \\left( \\frac{\\text{limitAperture}}{\\text{outputBeamDiameter}} \\right)^2\\)`}</Text>
                                                                            
                                                                                <Paragraph>
                                                                                <Text > Solution:</Text>
                                                                                <br/>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>{`\\(\\text{Adjusted Power/Energy = ${powerEnergy?.num}} \\cdot \\left( \\frac{\\text{7}}{\\text{${beamDiameter.toFixed(2)}}} \\right)^2\\)`}</Text>
                                                                                <br/>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>Adjusted Power/Energy = {(powerEnergy?.num * (Math.pow((7/beamDiameter),2))).toFixed(2)} </Text>
                                                                                <br/>
                                                                                <Text>Use the <Text code style={{...highlight}}>Adjusted Power/Energy</Text> to determine the appropriate RB number from BS EN 208</Text>
                                                                                </Paragraph>
                                                                                <Paragraph>
                                                                                    <Text style={{...highlight, fontSize:"15px"}}>Result: {props.result.RBNumber}</Text>
                                                                                </Paragraph>
                                                                            </>
                                                                            )
                                                                            : 
                                                                            <>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>Power/Energy =  {powerEnergy?.num}</Text>
                                                                                <br/>
                                                                                <Text >Use <Text code style={{...highlight}}>Power/Energy </Text>to determine the appropriate RB number from BS EN 208</Text>
                                                                                <Paragraph>
                                                                                    <Text style={{...highlight, fontSize:"15px"}}>Result: {props.result.RBNumber}</Text>
                                                                                </Paragraph>
                                                                            </>
                                                                            }                                                          
                                                                    </Paragraph>
                                                                )
                                                                :(
                                                                    <>
                                                                    <Paragraph>
                                                                        <Text> Wavelength is outside the visible section of wavelength (400 to 700nm)</Text>
                                                                    </Paragraph>
                                                                    <Paragraph>
                                                                        <Text strong> Thus, <Text >RB number = N/A</Text></Text>
                                                                    </Paragraph>
                                                                    </>
                                                                )}
                                                        </Typography>
                                                    </MathJax>
                                                )
                                            },
                                            {
                                                key:3,
                                                label:"LB Number for Glass",
                                                children: (
                                                    <MathJax>
                                                        <Typography>
                                                            <Paragraph>
                                                            <Text strong>To calculate the adjusted power/energy density <Text style={highlight}>Glass  {`\\(F(d)^{1.1693}\\)`}</Text>:</Text>
                                                            </Paragraph>
                                                            {/* <Paragraph>
                                                                <Text>Correction Factor<Text style={highlight}> {`\\((d)^{1.2233}\\)`} </Text>(plastic). If the beam diameter d is larger than 15 mm, the function values for d = 15 mm shall be used.</Text>
                                                            </Paragraph> */}
                                                            {/* <Paragraph>
                                                            Glass <Text strong>{`\\(F(d)^{1.1693}\\)`}</Text>
                                                            </Paragraph> */}
                                                            <Paragraph>
                                                            If the beam diameter <Text strong>d</Text> is larger than <Text strong>15 mm</Text>, the function values for <Text strong>d = 15 mm </Text> 
                                                            shall be used.
                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Multiply the actual power/energy density (<Text code>ActualAE</Text>) by the correction factor <Text strong style={highlight}>{`\\((d)^{1.1693}\\)`}</Text>:  
                                                            <br />
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>
                                                                    Formula:
                                                                </Text>
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = ActualAE ×  {`\\((d)^{1.1693}\\)`}</Text>  

                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong> Where:</Text>
                                                            </Paragraph>
                                                            

                                                            <Paragraph style={{justifySelf:"start", textAlign:"left"}}>
                                                                <ul>
                                                                    <li>
                                                                        <Text style={{...highlight, fontSize:"15px"}}>Actual AE = {displayActualAE}</Text>
                                                                    </li>
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>d = {props.result?.outputBeamDiameter?.outputBeamDiameterPretty ?? props.result.spotsize?.d63Pretty?.pretty ?? props.result.spotsize?.pretty ?? props?.result?.outputBeamDiameter} </Text>
                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>Correction factor glass = {`\\((\\text{${(useBeamDiameter).toFixed(2)}})^{1.1693}\\)`}</Text>
                                                                    </li>
                                                                </ul>
                                                            </Paragraph>
                                                            <Paragraph style={{textAlignLast: "justify"}}>
                                                                <Text type="secondary" style={{ textAlign:"left"}}>
                                                                        {`\\[\\text{Note: } F(15) \\text{ if } d > 15\\,\\text{mm}, \\; F(d) \\text{ if } d \\leq 15\\,\\text{mm}.\\]`}
                                                                </Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>Solution:</Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {displayActualAE} ×  {`\\((\\text{${(useBeamDiameter).toFixed(2)}})^{1.1693}\\)`}</Text>  
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {(useActualAE * correctionFactor("glass")).toFixed(2)} {unit}</Text>  

                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Use the corrected value <Text code>F(d)</Text> to determine the appropriate eyewear rating from <Text strong>Table B1 of BS EN 207:2017</Text>.
                                                            </Paragraph>

                                                            <Paragraph>
                                                                <Text>
                                                                    
                                                                    
                                                                    {props.result.Glass_average != null ? (
                                                                            <Space >
                                                                                <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.Glass_average}</Typography>
                                                                                {props.result.Plastic == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                                                                <Typography style={{...highlight, fontSize:"15px"}}>{props.result.Glass}</Typography>
                                                                            </Space>
                                                                        ) : <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.Glass}</Typography>
                                                                    }
                                                                </Text>
                                                            </Paragraph>

                                                            <Paragraph>
                                                            <Text type="secondary">
                                                                Note: This adjustment ensures that the calculated values align with the standard guidelines for glass eyewear safety.
                                                            </Text>
                                                            </Paragraph>
                                                        </Typography>
                                                    </MathJax>
                                                )
                                            }
                                            , {
                                                key:4,
                                                label:"LB Number for Plastic",
                                                children:(
                                                    <MathJax key={"2"}>
                                                        <Typography>
                                                            <Paragraph>
                                                            <Text strong>To calculate the adjusted power/energy density <Text style={highlight}>Plastic  {`\\(F(d)^{1.2233}\\)`}</Text>:</Text>
                                                            </Paragraph>
                                                            {/* <Paragraph>
                                                                <Text>Correction Factor<Text style={highlight}> {`\\((d)^{1.2233}\\)`} </Text>(plastic). If the beam diameter d is larger than 15 mm, the function values for d = 15 mm shall be used.</Text>
                                                            </Paragraph> */}
                                                            {/* <Paragraph>
                                                            Glass <Text strong>{`\\(F(d)^{1.1693}\\)`}</Text>
                                                            </Paragraph> */}
                                                            <Paragraph>
                                                            If the beam diameter <Text strong>d</Text> is larger than <Text strong>15 mm</Text>, the function values for <Text strong>d = 15 mm </Text> 
                                                            shall be used.
                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Multiply the actual power/energy density (<Text code>ActualAE</Text>) by the correction factor <Text strong style={highlight}>{`\\((d)^{1.2233}\\)`}</Text>:  
                                                            <br />
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>
                                                                    Formula:
                                                                </Text>
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = ActualAE ×  {`\\((d)^{1.2233}\\)`}</Text>  

                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong> Where:</Text>
                                                            </Paragraph>
                                                            

                                                            <Paragraph style={{justifySelf:"start", textAlign:"left"}}>
                                                                <ul>
                                                                    <li>
                                                                        <Text style={{...highlight, fontSize:"15px"}}>Actual AE = {displayActualAE}</Text>
                                                                    </li>
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>d = {props.result?.outputBeamDiameter?.outputBeamDiameterPretty ?? props.result.spotsize?.d63Pretty?.pretty ?? props.result.spotsize?.pretty ?? props?.result?.outputBeamDiameter} </Text>
                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>Correction factor plastic = {`\\((\\text{${(useBeamDiameter).toFixed(2)}})^{1.2233}\\)`}</Text>
                                                                    </li>
                                                                </ul>
                                                            </Paragraph>
                                                            <Paragraph style={{textAlignLast: "justify"}}>
                                                                <Text type="secondary" style={{ textAlign:"left"}}>
                                                                        {`\\[\\text{Note: } F(15) \\text{ if } d > 15\\,\\text{mm}, \\; F(d) \\text{ if } d \\leq 15\\,\\text{mm}.\\]`}
                                                                </Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>Solution:</Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {displayActualAE} ×  {`\\((\\text{${(useBeamDiameter).toFixed(2)}})^{1.2233}\\)`}</Text>  
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {(useActualAE * correctionFactor("plastic")).toFixed(2)} {unit}</Text>  

                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Use the corrected value <Text code>F(d)</Text> to determine the appropriate eyewear rating from <Text strong>Table B1 of BS EN 207:2017</Text>.
                                                            </Paragraph>

                                                            <Paragraph>
                                                                <Text>
                                                                    
                                                                    
                                                                    {props.result.Plastic_average != null ? (
                                                                            <Space >
                                                                                <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.Plastic_average}</Typography>
                                                                                {props.result.Plastic == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                                                                <Typography style={{...highlight, fontSize:"15px"}}>{props.result.Plastic}</Typography>
                                                                            </Space>
                                                                        ) : <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.Plastic}</Typography>
                                                                    }
                                                                </Text>
                                                            </Paragraph>

                                                            <Paragraph>
                                                            <Text type="secondary">
                                                                Note: This adjustment ensures that the calculated values align with the standard guidelines for plastic eyewear safety.
                                                            </Text>
                                                            </Paragraph>
                                                        </Typography>
                                                    </MathJax>
                                                )
                                            }
                                        ]}
                                    />
                            )
                        }
                        
                    ]}>
                </Collapse>
            </Row>

            <Divider />

            <Col sm={24} md={24} lg={24} xl={24} xxl={3} style={{alignContent:"center"}}>        
                <Flex wrap="true" style={{justifyContent: "center"}}>
                    <strong style={{ display: "flex", alignItems: "center" }}>Results at 0.1 m</strong>
                </Flex>
            </Col>
            <Flex wrap="true">
                <ResultCard>
                <strong style={textCenter}>Nominal O.D.</strong>
                <>
                    <Space style={textCenter}>
                    <strong>
                        <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                        <Typography>{props.result.NominalOD_distance.OD}</Typography>
                        </Tooltip>
                    </strong>
                    </Space>
                </>
                </ResultCard>
                <ResultCard>
                <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                    <strong style={textCenter}>RB Number</strong>
                    <Typography style={textCenter}>{props.result.RBNumber_distance}</Typography>
                </Tooltip>
                </ResultCard>
            </Flex>
            <Flex wrap="true">
                <ResultCard>
                <Tooltip title={message}>
                    <strong style={textCenter} >LB Number for Glass</strong>
                    <>
                    {props.result.GlassAverage_Distance != null ? (
                        <Space style={textCenter}>
                            <Typography style={textCenter}>{props.result.GlassAverage_Distance}</Typography>
                            {props.result.Glass_Distance == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                            <Typography style={textCenter}>{props.result.Glass_Distance}</Typography>
                        </Space>
                    ) : <Typography style={textCenter}>{props.result.Glass_Distance}</Typography>}
                    </>
                </Tooltip>
                </ResultCard>
                <ResultCard>
                <Tooltip title={message}>
                    <strong style={textCenter}>LB Number for Plastic</strong>
                    {props.result.PlasticAverage_Distance != null ? (
                    <Space style={{textCenter}}>
                        <Typography style={textCenter}>{props.result.PlasticAverage_Distance}</Typography>
                        {props.result.Plastic_Distance == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                        <Typography style={textCenter}>{props.result.Plastic_Distance}</Typography>
                    </Space>
                    ) : <Typography style={textCenter}>{props.result.Plastic_Distance}</Typography>}
                </Tooltip>
                </ResultCard>
            </Flex>
            <Row style={{width:"100%", margin:"10px 0px 30px 0px"}}>
                <Collapse   
                    style={{width:"100%"}}
                    size="small"
                    items={[
                        {
                            key: '1',
                            label: 'More Info',
                            children: (
                                
                                    <Tabs 
                                        items= {[
                                            
                                            {
                                                key:1,
                                                label:"Nominal O.D.",
                                                children: (
                                                    <MathJax key="1">
                                                    <Typography>
                                                        <Paragraph>
                                                        <Text strong>Nominal Optical Density (OD)</Text> is calculated as the logarithm of the quotient of the Accessible Emission (AE) and Maximum Permissible Exposure (MPE):
                                                        </Paragraph>
                                                        <Paragraph>
                                                        <Text strong style={{...highlight, fontSize: "15px"}}>{`\\(OD = \\log(\\frac{AE}{MPE})\\)`}</Text>
                                                        </Paragraph>
                                                        <Paragraph>
                                                        The formula is used to ensure laser safety by determining the required attenuation of laser exposure.
                                                        </Paragraph>
                                                        <Paragraph>
                                                        Where:
                                                        <ul >
                                                            <li>
                                                            <Text strong style={{...highlight, fontSize: "15px"}}>AE = { useAE}</Text>
                                                            </li>
                                                            <li>
                                                            <Text strong style={{...highlight, fontSize: "15px"}}>MPE = { displayMPE}</Text>
                                                            </li>
                                                        </ul>
                                                        </Paragraph>
                                                        <Paragraph>
                                                            <Text>Solution:</Text>
                                                            <br/>
                                                            <Text strong style={{...highlight, fontSize: "20px"}}>   {`\\(OD = \\log(\\frac{\\text{${(props.result.AE.joulesPerSqmPretty.eng ?? props.result?.AE?.joulesPerSqmPretty).includes("J/m²") ? props.result.AE.joulesPerSqmPretty.eng ?? props.result?.AE?.joulesPerSqmPretty : props.result.AE.joulesPerSqmPretty.eng + "J/m²" ?? props.result?.AE?.joulesPerSqmPretty + "J/m²"}}}{\\text{${displayMPE}}})\\)`}</Text>
                                                            <br/>
                                                            <Text strong style={{...highlight, fontSize: "20px"}}> {`\\(OD = \\text{${Math.log10(props.result?.AE?.joulesPerSqm / useMPE).toFixed(1)}}\\)`}</Text>
                                                        </Paragraph>
                                                        <Paragraph>
                                                        <Text type="secondary">
                                                            Note: The higher the OD, the greater the attenuation required to protect from hazardous laser exposure.
                                                        </Text>
                                                        </Paragraph>
                                                    </Typography>
                                                    </MathJax>
                                                )
                                                
                                            },
                                            {
                                                key:2,
                                                label:"RB Number",
                                                children: (
                                                    <MathJax>
                                                        <Typography>
                                                            <Paragraph>
                                                            <Text strong>
                                                                The EN 208 standard only applies to lasers that emit wavelengths in the visible section of the wavelength (400 to 700nm).
                                                            </Text>
                                                            </Paragraph>
                                                            <Paragraph> 
                                                                If the beam diameter is larger than the limit aperture of 7mm, then the selection can be based on the fraction of the power that would pass through a 7mm aperture.
                                                            </Paragraph>
                                                            <Paragraph >
                                                            Where:
                                                                <ul >
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}>Wavelength = {wavelength * 1000000000} nm </Text></li>
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}> Power/Energy = {powerEnergy?.num}{powerEnergy?.unit} </Text></li>
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}> Beam diameter =  {beamDiameter.toFixed(2)} mm</Text></li>
                                                                    <li><Text style={{...highlight, fontSize:"15px"}}> Limit Aperture = 7 mm </Text></li>
                                                                </ul>
                                                            
                                                            </Paragraph>
                                                                {wavelength * 1000000000 >= 400 && wavelength * 1000000000 < 700 ? (

                                                                    <Paragraph>
                                                                        <Text >Formula:</Text>
                                                                            <br/>
                                                                            {beamDiameter > 7 ? (
                                                                            <>
                                                                                <Text>If the beam diameter is larger than the 7mm limit aperture: </Text>
                                                                                <br/>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>{`\\(\\text{Adjusted Power/Energy = Power/Energy} \\cdot \\left( \\frac{\\text{limitAperture}}{\\text{outputBeamDiameter}} \\right)^2\\)`}</Text>
                                                                            
                                                                                <Paragraph>
                                                                                <Text > Solution:</Text>
                                                                                <br/>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>{`\\(\\text{Adjusted Power/Energy = ${powerEnergy?.num}} \\cdot \\left( \\frac{\\text{7}}{\\text{${diameterDistance.toFixed(2)}}} \\right)^2\\)`}</Text>
                                                                                <br/>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>Adjusted Power/Energy = {(powerEnergy?.num * (Math.pow((7/diameterDistance),2))).toFixed(2)} </Text>
                                                                                <br/>
                                                                                <Text>Use the <Text code style={{...highlight}}>Adjusted Power/Energy</Text> to determine the appropriate RB number from BS EN 208</Text>
                                                                                </Paragraph>
                                                                                <Paragraph>
                                                                                    <Text style={{...highlight, fontSize:"15px"}}>Result: {props.result.RBNumber_distance}</Text>
                                                                                </Paragraph>
                                                                            </>
                                                                            )
                                                                            : 
                                                                            <>
                                                                                <Text strong style={{...highlight, fontSize:"15px"}}>Power/Energy =  {powerEnergy?.num}</Text>
                                                                                <br/>
                                                                                <Text >Use <Text code style={{...highlight}}>Power/Energy </Text>to determine the appropriate RB number from BS EN 208</Text>
                                                                                <Paragraph>
                                                                                    <Text style={{...highlight, fontSize:"15px"}}>Result: {props.result.RBNumber_distance}</Text>
                                                                                </Paragraph>
                                                                            </>
                                                                            }                                                          
                                                                    </Paragraph>
                                                                )
                                                                :(
                                                                    <>
                                                                    <Paragraph>
                                                                        <Text> Wavelength is outside the visible section of wavelength (400 to 700nm)</Text>
                                                                    </Paragraph>
                                                                    <Paragraph>
                                                                        <Text strong> Thus, <Text >RB number = N/A</Text></Text>
                                                                    </Paragraph>
                                                                    </>
                                                                )}
                                                        </Typography>
                                                    </MathJax>
                                                )
                                            },
                                            {
                                                key:3,
                                                label:"LB Number for Glass",
                                                children: (
                                                    <MathJax>
                                                        <Typography>
                                                            <Paragraph>
                                                            <Text strong>To calculate the adjusted power/energy density <Text style={highlight}>Glass  {`\\(F(d)^{1.1693}\\)`}</Text>:</Text>
                                                            </Paragraph>
                                                            {/* <Paragraph>
                                                                <Text>Correction Factor<Text style={highlight}> {`\\((d)^{1.2233}\\)`} </Text>(plastic). If the beam diameter d is larger than 15 mm, the function values for d = 15 mm shall be used.</Text>
                                                            </Paragraph> */}
                                                            {/* <Paragraph>
                                                            Glass <Text strong>{`\\(F(d)^{1.1693}\\)`}</Text>
                                                            </Paragraph> */}
                                                            <Paragraph>
                                                            If the beam diameter <Text strong>d</Text> is larger than <Text strong>15 mm</Text>, the function values for <Text strong>d = 15 mm </Text> 
                                                            shall be used.
                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Multiply the actual power/energy density (<Text code>ActualAE</Text>) by the correction factor <Text strong style={highlight}>{`\\((d)^{1.1693}\\)`}</Text>:  
                                                            <br />
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>
                                                                    Formula:
                                                                </Text>
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = ActualAE ×  {`\\((d)^{1.1693}\\)`}</Text>  

                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong> Where:</Text>
                                                            </Paragraph>
                                                            

                                                            <Paragraph style={{justifySelf:"start", textAlign:"left"}}>
                                                                <ul>
                                                                    <li>
                                                                        <Text style={{...highlight, fontSize:"15px"}}>Actual AE = {displayActualAE}</Text>
                                                                    </li>
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>d = { (props?.result?.outputBeamDiameter?.outputBeamDiameter_distance_mm?? props?.result?.spotsize?.d63 ?? props?.result?.outputBeamDiameter?.outputBeamDiameter_distance_mm  ?? props?.result?.outputBeamDiameter?.d63_distance * 1000 ?? props?.result?.outputBeamDiameter?.spotSize_distance * 1000 ).toFixed(2)} </Text>
                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>Correction factor glass = {`\\((\\text{${(useDiameterDistance).toFixed(2)}})^{1.1693}\\)`}</Text>
                                                                    </li>
                                                                </ul>
                                                            </Paragraph>
                                                            <Paragraph style={{textAlignLast: "justify"}}>
                                                                <Text type="secondary" style={{ textAlign:"left"}}>
                                                                        {`\\[\\text{Note: } F(15) \\text{ if } d > 15\\,\\text{mm}, \\; F(d) \\text{ if } d \\leq 15\\,\\text{mm}.\\]`}
                                                                </Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>Solution:</Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {displayActualAE} ×  {`\\((\\text{${(useDiameterDistance).toFixed(2)}})^{1.1693}\\)`}</Text>  
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {(useActualAE * correctionFactor("glass", true)).toFixed(2)} {unit}</Text>  

                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Use the corrected value <Text code>F(d)</Text> to determine the appropriate eyewear rating from <Text strong>Table B1 of BS EN 207:2017</Text>.
                                                            </Paragraph>

                                                            <Paragraph>
                                                                <Text>
                                                                    
                                                                    
                                                                    {props.result.GlassAverage_Distance != null ? (
                                                                            <Space >
                                                                                <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.GlassAverage_Distance}</Typography>
                                                                                {props.result.Glass_Distance == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                                                                <Typography style={{...highlight, fontSize:"15px"}}>{props.result.Glass_Distance}</Typography>
                                                                            </Space>
                                                                        ) : <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.Glass_Distance}</Typography>
                                                                    }
                                                                </Text>
                                                            </Paragraph>

                                                            <Paragraph>
                                                            <Text type="secondary">
                                                                Note: This adjustment ensures that the calculated values align with the standard guidelines for glass eyewear safety.
                                                            </Text>
                                                            </Paragraph>
                                                        </Typography>
                                                    </MathJax>
                                                )
                                            }
                                            , {
                                                key:4,
                                                label:"LB Number for Plastic",
                                                children:(
                                                    <MathJax key={"2"}>
                                                        <Typography>
                                                            <Paragraph>
                                                            <Text strong>To calculate the adjusted power/energy density <Text style={highlight}>Plastic  {`\\(F(d)^{1.2233}\\)`}</Text>:</Text>
                                                            </Paragraph>
                                                            {/* <Paragraph>
                                                                <Text>Correction Factor<Text style={highlight}> {`\\((d)^{1.2233}\\)`} </Text>(plastic). If the beam diameter d is larger than 15 mm, the function values for d = 15 mm shall be used.</Text>
                                                            </Paragraph> */}
                                                            {/* <Paragraph>
                                                            Glass <Text strong>{`\\(F(d)^{1.1693}\\)`}</Text>
                                                            </Paragraph> */}
                                                            <Paragraph>
                                                            If the beam diameter <Text strong>d</Text> is larger than <Text strong>15 mm</Text>, the function values for <Text strong>d = 15 mm </Text> 
                                                            shall be used.
                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Multiply the actual power/energy density (<Text code>ActualAE</Text>) by the correction factor <Text strong style={highlight}>{`\\((d)^{1.2233}\\)`}</Text>:  
                                                            <br />
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>
                                                                    Formula:
                                                                </Text>
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = ActualAE ×  {`\\((d)^{1.2233}\\)`}</Text>  

                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong> Where:</Text>
                                                            </Paragraph>
                                                            

                                                            <Paragraph style={{justifySelf:"start", textAlign:"left"}}>
                                                                <ul>
                                                                    <li>
                                                                        <Text style={{...highlight, fontSize:"15px"}}>Actual AE = {displayActualAE}</Text>
                                                                    </li>
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>d = {(props?.result?.outputBeamDiameter?.outputBeamDiameter_distance_mm?? props?.result?.spotsize?.d63 ?? props?.result?.outputBeamDiameter?.outputBeamDiameter_distance_mm ?? ( props?.result?.outputBeamDiameter?.d63_distance ?? props?.result?.outputBeamDiameter?.spotSize_distance ) * 1000 ).toFixed(2)} </Text>
                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <Text  style={{...highlight, fontSize:"15px"}}>Correction factor plastic = {`\\((\\text{${(useDiameterDistance).toFixed(2)}})^{1.2233}\\)`}</Text>
                                                                    </li>
                                                                </ul>
                                                            </Paragraph>
                                                            <Paragraph style={{textAlignLast: "justify"}}>
                                                                <Text type="secondary" style={{ textAlign:"left"}}>
                                                                        {`\\[\\text{Note: } F(15) \\text{ if } d > 15\\,\\text{mm}, \\; F(d) \\text{ if } d \\leq 15\\,\\text{mm}.\\]`}
                                                                </Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text>Solution:</Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {displayActualAE} ×  {`\\((\\text{${(useDiameterDistance).toFixed(2)}})^{1.2233}\\)`}</Text>  
                                                                <br />
                                                                <Text strong style={{...highlight, fontSize:"15px"}}>F(d) = {(useActualAE * correctionFactor("plastic", true)).toFixed(2)} {unit}</Text>  

                                                            </Paragraph>
                                                            
                                                            <Paragraph>
                                                            Use the corrected value <Text code>F(d)</Text> to determine the appropriate eyewear rating from <Text strong>Table B1 of BS EN 207:2017</Text>.
                                                            </Paragraph>

                                                            <Paragraph>
                                                                <Text>
                                                                    
                                                                    
                                                                    {props.result.PlasticAverage_Distance != null ? (
                                                                            <Space >
                                                                                <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.PlasticAverage_Distance}</Typography>
                                                                                {props.result.Plastic_Distance == null ? null : <Divider type="vertical" style={{ height: "15px" }}></Divider>}
                                                                                <Typography style={{...highlight, fontSize:"15px"}}>{props.result.Plastic_Distance}</Typography>
                                                                            </Space>
                                                                        ) : <Typography style={{...highlight, fontSize:"15px"}}> Result:  {props.result.Plastic_Distance}</Typography>
                                                                    }
                                                                </Text>
                                                            </Paragraph>

                                                            <Paragraph>
                                                            <Text type="secondary">
                                                                Note: This adjustment ensures that the calculated values align with the standard guidelines for plastic eyewear safety.
                                                            </Text>
                                                            </Paragraph>
                                                        </Typography>
                                                    </MathJax>
                                                )
                                            }
                                        ]}
                                    />
                            )
                        }
                        
                    ]}>
                </Collapse>
            </Row>
        </Row>
      </>
    )
}