import React from 'react';
import {Typography, Space} from 'antd'
import "./link.css"

function Label() {
    return(
    <>  
    <Typography.Text class="textAnimate">
        <strong>
            <a href="https://www.lasermet.com/laser-safety-products/laser-classification-label/" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
            Buy Laser Classification Labels here!
            </a>
        </strong>
    </Typography.Text>    
    </>
    )
}

function TestingCenter() {
    return (
        <>
        <Space direction='vertical' style={{textAlign:"center"}}>
            <Typography.Text>
                <strong style={{display:"flex"}}> Do you want your laser product to be accredited?  
                </strong>
            </Typography.Text>
    
            <Typography.Text class="textAnimate">
                <strong >
                    <a  href="https://www.lasermet.com/laser-safety-services/product-testing-laser-led/" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                    Visit our accredited testing center! 
                    </a>
            </strong>
            </Typography.Text>
        </Space>
        </>
    )
}

function Eyewear() {
    return (
        <Typography.Text class="textAnimate" style={{textAlign: "center", color:"red"}}>
            <strong>
                <a href="https://www.lasermet.com/product-category/laser-protective-eyewear/" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                    Stay protected. Buy your Laser Protective Eyewear here!
                </a>
            </strong>
        </Typography.Text>
    )
}
export { Label, TestingCenter, Eyewear }