import React, {useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import LookupTable from '../../pages/Loginv2/Calculators/LookupTable';
import ResultCard from "../ResultCard";
import { Card, Row, Typography } from 'antd';
import Watermark from './Watermark';
import logo from "../../assets/Logo/logosidetext.png"
import laserFooter from "../../assets/Logo/laserFooter.png"
// Create styles
Font.register(({family: 'Roboto', fonts: [
  {
    src: 'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xIIzc.ttf', // Normal font
  },
  {
    src: 'https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrIzc.ttf', // Italic font
    fontStyle: 'italic',
  },
],}))

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: "center",
    position: 'relative',
    paddingTop: '25px',
   
  },
  title: {
    justify: 'cenetr',
    textAlign: 'center',
    marginBottom: "0.2cm",
    marginTop: "0.5cm"
  },
  image: {
    width: "35vw",
    marginBottom: "45px"
   
  },
  row: {
    flexDirection: "row",
    width: "90vw"
  },
  smallRow: {
    flexDirection: "row",
    width: "100%",
  
  },
  box: {
    width: "0.8in",
    height: '0.8in',
    justify: 'center',
    borderRadius:"50px"
  
  },
  marginLeft: {
    marginLeft: "2cm"
  },
  underLine: {
    borderBottom: '1px solid black',
    marginBottom: "0.5cm"
  },
  data: {
    fontSize: "6mm"
  },
  center: {
    display:"flex",
    justify: "center",
    justifyContent: "center",
    flexDirection: "row",
    textAlign: 'center',
  },
  titleBox: {
    justify: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    backgroundColor: 'rgb(230,230,230)',
    marginBottom: "0.2cm",
    marginTop: "0.5cm",
    width: "90vw",
    height: "30px",
    padding: "5px",
    fontSize: "15px",
    fontWeight: "bold"

  },
  classification: {
    backgroundColor: 'rgb(230,230,230)',
    marginBottom: "0.2cm",
    marginTop: "0.5cm",
    width: "90vw",
    height: "30px",
    padding: "5px",
    fontSize: "15px",
    fontWeight: "bold"

  },
  subTitle: {
    fontSize: "13px",
    justifyContent: "space-around",
    fontWeight: "bold",
    marginBottom: "15px"
  },
  result: {
    fontSize: "13px",
    fontWeight: "bold",
    marginTop: "20px"
  },
  footer: {
    position: "absolute",
    bottom: 40,
    width: "100vw",
    height: "4vh"
  
  },
  header: {
    justifyContent: "space-between",
  },
  verdict: {
    width: "90vw",
    borderRadius: "20px",
    height: "30px",
    padding:"5px",
    textAlign: 'center',
  
  },
  classVerdict: {
    width: "60vw",
    border: "1px solid, #1d1f8c",
    borderRadius: "20px",
    padding:"5px",
    textAlign: 'center',
    justify: "center",
    color: "#1d1f8c",
    marginTop: "50px"
  
  },
  repPulsedAEL: {
    fontSize: "16px"
  },
  repPulsedAELHeader: {
    fontSize: "14px"
  },
  correctionFactors: {
    justifyContent: "space-evenly",
    fontSize: "50px"
  },
  subscript: {
    fontSize: 10,
    baselineShift: -3, 
  },
  AE: {
    justifyContent: "space-around",
  },
  AccessibleEmission: {
    fontSize: "15px",
    marginTop: "10px"
  },
  secondaryText: {
    color: '#505050', 
    fontSize: 12,  
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  }
  
});
let color
let verText
let skinColor
let skinVerText

export function ResultsPDF({result, date, form, laserName})
{
  let operation
  let laserOperation
  let source 
   

 if (form.laserOperation === "diffuseReflectiveCW") {
    operation = form.diffuseReflectiveCW
    laserOperation = "Diffuse Reflective Continous Wave"
    result = result.data
 
  }
  else if (form.laserOperation === "diffuseReflectiveSinglePulse") {
    operation = form.diffuseReflectiveSP
    laserOperation = "Diffuse Reflective Single Pulse"
    result = result.data
 
  }
  else if (form.laserOperation === "diffuseReflectiveRepetitivelyPulsed") {
    operation = form.diffuseReflectiveRP
    laserOperation = "Diffuse Reflective Repetitively Pulsed"
    result = result.data
 
  }

  
  if (form.sourceType === "point") {
    source = "Point"
  }
  else {
    source = "Extended"
  }

  // Create Document Component
  console.log("hey",result)
return (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <Watermark />
      {/**Parameters */}
      <View>
        <View style={[styles.row, styles.header]}>
          <View>
            <Image src={logo} style={styles.image}></Image>
         </View>
         <View style={{fontSize:"14px"}}>
            <View style={{justifyContent:"center"}}>
              <Text style={{marginTop:"10px"}}>Name: {laserName}</Text> 
            </View>
            <View style={{justifyContent:"flex-end"}}>
              <Text style={{justifyContent:"flex-end"}}>Date: {date}</Text>
            </View>
          </View>
        </View>
      <View style={styles.row}><Text style={styles.title}>Point and Extended Source Results IEC 60825-1 2014</Text></View>
      <Text style={{fontWeight:"bold"}}> A) Laser Properties</Text>
        <View style={styles.row}>
          <div style={styles.titleBox}>
            <Text>Laser Profile</Text> 
          </div>
        </View>
        
            <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text>Laser Operation</Text>
                <View style={styles.center}>
                  <Text>{laserOperation}</Text>
                </View>
              </View>
              <View>
                <Text>Source</Text>
                <View style={styles.center}>
                  <Text>{source} Source</Text>
                </View>
              </View>
            </View>

        <View style={styles.row}>
          <div style={styles.titleBox}>
            <Text>{laserOperation} Settings</Text> 
          </div>
        </View>
        <View  style={[styles.row, styles.subTitle]}>
          <View>
            <Text style={styles.result}>Wavelength</Text>
            <Text style={styles.result}>Exposure Duration</Text>
            <Text style={styles.result}>Laser Power</Text>
            <Text style={styles.result}>Beam Diameter</Text>
            <Text style={styles.result}>Beam Divergence</Text>
            <Text style={styles.result}>Distance to Target</Text>
            {laserOperation === "Diffuse Reflective Repetitively Pulsed" ?  
            <>
              <Text style={styles.result}>Pulse Duration</Text> 
              <Text style={styles.result}>Frequency</Text>
            </>
            : null }
            {form.diffuseReflective ? (
              <>
                <Text style={styles.result}>Albedo</Text>
                <Text style={styles.result}>Distance to Diffuser</Text>
              </>
            ) : null }
          </View>
          <View>
            <Text style={styles.result}>{operation?.wavelength?.num} nm</Text>
            <Text style={styles.result}>{operation?.time?.num ?? operation?.pulseDuration?.num } s</Text>
             <Text style={styles.result}>{(operation?.power?.num != null ? operation?.power?.num + " W" :
              operation?.energy?.num != null ? operation?.energy?.num + " J" :
              operation?.peakPower?.num != null ? operation?.peakPower?.num + " W" :  operation?.averagePower?.num != null ? operation?.averagePower?.num + " W" :
              undefined) }</Text>
            <Text style={styles.result}>{operation?.beamDiameter?.num} mm</Text>
            <Text style={styles.result}>{operation?.beamDivergence?.num} mrad</Text>
            <Text style={styles.result}>{operation?.distance?.num} {operation?.distance?.unit}m</Text>
            {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? 
            <>
            <Text style={styles.result}>{operation?.pulseDuration?.num} {operation?.pulseDuration?.unit}s</Text>  
            <Text style={styles.result}>{operation?.pulseFrequency?.num} {operation?.pulseFrequency?.unit}Hz</Text>  
            </>
            : null}
            {form.diffuseReflective ? (
              <>
                <Text style={styles.result}>{form?.diffuseReflective?.albedo.num} %</Text>
                <Text style={styles.result}>{form?.diffuseReflective?.distance2.unit === false ? form?.diffuseReflective?.distance2?.num + " m" : form?.diffuseReflective?.distance2?.num + " " + form?.diffuseReflective?.distance2?.unit + "m"}  </Text>
              </>
            ) : null }
          </View>
        </View>
        {/* <View  style={[styles.row, styles.subTitle]}>
          <View>
          </View>
          <View>
          </View>
        </View>
        <View  style={[styles.row, styles.subTitle]}>
          <View>
          </View>
          <View>
          </View>
        </View>
        <View  style={[styles.row, styles.subTitle]}>
          <View>
          </View>
          <View>
          </View>
        </View>
        <View  style={[styles.row, styles.subTitle]}>
          <View>
          
          </View>
          <View>
           
          </View>
        </View>
        <View  style={[styles.row, styles.subTitle]}>
          <View>
           
          </View>
          <View>
            
          </View>
        </View> */}


      </View>
      
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
        
        {/* <View style={{justifyContent:"space-between"}}>
          <Text>RealTime - Laser Safety Software</Text>
          <Text>Lookup Table Results</Text>
        </View> */}
    </Page>

     {/**Calculation Results */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />
      
      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
        <Text> B) Calculation Results</Text>

        <View style={styles.row}>
          <View>
          <div style={styles.titleBox}>
            <Text>Eye</Text> 
          </div>
            <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text style={styles.center}>{ laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "Single Pulse MPE" : "Maximum Permissible Emission" }</Text>
                <View style={styles.center}>
                  <Text style={[styles.center, styles.result]}>{result?.MPE?.joulesPerSqmPretty?.eng ?? result?.MPE?.single?.joulesPerSqmPretty?.eng}J/m²</Text>
                </View>
              </View>
              <View>
                <Text>Accessible Emission</Text>
              
                  <View style={[styles.smallRow, styles.AE]}>
                    <Text  style={styles.center}>AE</Text>
                    <Text style={styles.center}>Actual AE</Text>
                  </View>
        
                  <View style={[styles.smallRow, styles.AE]}>
                    <Text style={styles.center}>{result?.AE?.joulesPerSqmPretyy ?? result?.AE?.joulesPerSqmPretty}</Text>
                    <Text style={{ ...styles.center, marginLeft: " 15px" }}>{result.AE.joulesPerSqmPretty}</Text>
                  </View>
  
              </View>
              <View >
                <Text>Eye Safety Ratio</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.MPEExcess?.joulesPerSqm_singlePretty ?? result?.MPEExcess?.joulesPerSqmPretty}</Text>
                </View>
              </View>
            </View>
            {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? (
              <>
                {/* Pulse Train Results*/}
                <View style={[styles.row, styles.subTitle]}>
                  <View>
                    <Text style={styles.center}>Pulse Train MPE</Text>
                    <View style={styles.center}>
                      <Text style={[styles.center, styles.result]}>{result.MPE?.pulseTrain?.joulesPerSqmPretty}</Text>
                    </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>AE</Text>
                        <Text style={styles.center}>Actual AE</Text>
                      </View>

                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{result?.AE?.joulesPerSqmPretyy ?? result?.AE?.joulesPerSqmPretty}</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{result.AE.joulesPerSqmPretty}</Text>
                      </View>
           
                  </View>
                  <View>
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                    <Text>{result?.MPEExcess?.joulesPerSqm_trainPretty}</Text>
                    </View>
                  </View>
                </View><View style={[styles.row, styles.subTitle]}>
                
                {/* Average Results*/}
                <View>
                  <Text style={styles.center}>Average MPE</Text>
                  <View style={styles.center}>
                    <Text style={[styles.center, styles.result]}>
                        {result.MPE?.average?.joulesPerSqmPretty}
                    </Text>
                  </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>AE</Text>
                        <Text style={styles.center}>Actual AE</Text>
                      </View>

                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{result?.AE?.joulesPerSqmPretyy ?? result?.AE?.joulesPerSqmPretty}</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{result.AE.joulesPerSqmPretty}</Text>
                      </View>
                
                  </View>
                  <View>
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result?.MPEExcess?.joulesPerSqm_averagePretty}</Text>
                    </View>
                  </View>
              </View></>
            ) : null}

            <View style={[styles.row, styles.center]}>
              
              {(() => {
                  if (result.MPEExcess.joulesPerSqm > 1) {
                    color = "#d82424"
                    verText = "DANGEROUS FOR THE EYE"
                  }
                  else if(result.MPEExcess.joulesPerSqm <= 0.5) {
                    color = "#10c96d"
                    verText = "SAFE FOR THE EYE"
                }
                  else if (result.MPEExcess.joulesPerSqm > 0.5 || result.MPEExcess.joulesPerSqm  <=1) {
                    color = "#ffa200"
                    verText = "MARGINALLY SAFE FOR THE EYE"
                }
                })()}
              <div style={{
                ...styles.verdict,
                backgroundColor: color
              }}>
                <Text style={{...styles.center, color: "white"}}>{verText}</Text>
              </div>
               
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View>
          <div style={styles.titleBox}>
            <Text>Skin</Text> 
          </div>
          <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text style={styles.center}>{ laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "Single Pulse MPE" : "Maximum Permissible Emission" }</Text>
                <View style={styles.center}>
                  <Text style={[styles.center, styles.result]}>
                    {result?.MPEskin?.joulesPerSqmPretty?.eng ?? result?.skinMPE?.joulesPerSqmPretty?.eng ?? result?.skinMPE?.single?.joulesPerSqmPretty?.eng}J/m²
                  </Text>
                </View>
              </View>
              <View>
                <Text>Accessible Emission</Text>
                <View style={[styles.smallRow, styles.AE]}>
                    <Text  style={styles.center}>AE</Text>
                    <Text style={styles.center}>Actual AE</Text>
                  </View>
                
                  <View style={[styles.smallRow, styles.AE]}>
                  <Text style={styles.center}>{(result?.skinAE?.joulesPerSqmPretyy ?? result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty).includes("J/m²") ? 
                    result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretyy ?? result?.skinAE?.joulesPerSqmPretty :
                    result?.skinAE?.joulesPerSqmPretty.eng + "J/m²" ?? result?.skinAE?.joulesPerSqmPretyy + "J/m²" ?? result?.skinAE?.joulesPerSqmPretty.eng + "J/m²" ?? result?.skinAE?.joulesPerSqmPretty + "J/m²"}
                  </Text>

                  <Text style={{ ...styles.center, marginLeft: " 15px" }}>{(result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty).includes("J/m²") ? 
                    result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty :
                    result?.skinAE?.joulesPerSqmPretty.eng + "J/m" ?? result?.skinAE?.joulesPerSqmPretty + "J/m"}
                  </Text> 
                </View>
                
  
              </View>
              <View >
                <Text>Skin Safety Ratio</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.skinMPEExcess?.joulesPretty_single?.pretty ?? result?.skinMPEExcess?.joulesPerSqmPretty ?? result?.skinMPEExcess?.joulesPretty?.pretty}</Text>
                </View>
              </View>
            </View>
              {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? (
                <>
                 {/** Pulse Train MPE Results */}
                <View style={[styles.row, styles.subTitle]}>
                  <View>
                    <Text style={styles.center}>Pulse Train MPE</Text>
                    <View style={styles.center}>
                      <Text style={[styles.center, styles.result]}>
                        {result.skinMPE?.PulseTrain?.joulesPerSqmPretty?.eng}J/m²
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                        <Text  style={styles.center}>AE</Text>
                        <Text style={styles.center}>Actual AE</Text>
                      </View>
                    
                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{result?.skinAE?.joulesPerSqmPretyy ?? result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty}J/m²</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty}J/m²</Text> 
                      </View>
                  </View>
                  <View >
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result.skinMPEExcess.joulesPretty_train?.pretty}</Text>
                    </View>
                  </View>
                </View>

                {/** Average MPE Results */}
                <View style={[styles.row, styles.subTitle]}>
                  <View>
                  <Text style={styles.center}>Average MPE</Text>
                  <View style={styles.center}>
                    <Text style={[styles.center, styles.result]}>
                      {result.skinMPE?.MPEAverage?.joulesPerSqmPretty?.eng}J/m²
                    </Text>
                  </View>
                  </View>
                  <View>
                    <Text>Accessible Emission</Text>
                    <View style={[styles.smallRow, styles.AE]}>
                      <Text  style={styles.center}>AE</Text>
                      <Text style={styles.center}>Actual AE</Text>
                      </View>
                  
                      <View style={[styles.smallRow, styles.AE]}>
                        <Text style={styles.center}>{result?.skinAE?.joulesPerSqmPretyy ?? result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty}J/m²</Text>
                        <Text style={{ ...styles.center, marginLeft: " 15px" }}>{result?.skinAE?.joulesPerSqmPretty.eng ?? result?.skinAE?.joulesPerSqmPretty}J/m²</Text> 
                      </View>
                    </View>
                  
                  <View >
                    <Text>Eye Safety Ratio</Text>
                    <View style={[styles.center, styles.result]}>
                      <Text>{result.skinMPEExcess.joulesPretty_average?.pretty}</Text>
                    </View>
                  </View>
                </View>
                </>
              ): null }
         
            <View style={[styles.row, styles.center]}>
              {(() => {
                  if (result.skinMPEExcess.joulesPerSqm > 1 || result.skinMPEExcess.joules > 1) {
                    skinColor = "#d82424"
                    skinVerText = "DANGEROUS FOR THE SKIN"
                  }
                  else if(result.skinMPEExcess.joulesPerSqm <= 0.5  || result.skinMPEExcess.joules <= 0.5){
                    skinColor = "#10c96d"
                    skinVerText = "SAFE FOR THE SKIN"
                }
                  else if ((result.skinMPEExcess.joulesPerSqm > 0.5 || result.skinMPEExcess.joulesPerSqm <=1) || (result.skinMPEExcess.joules > 0.5 || result.skinMPEExcess.joules <=1)) {
                    skinColor = "#ffa200"
                    skinVerText = "MARGINALLY SAFE FOR THE SKIN"
                }
                })()}
              <div style={{
                ...styles.verdict,
                backgroundColor: skinColor
              }}>
                <Text style={{ ...styles.center, color: "white" }}>{skinVerText}</Text>
              </div>
            </View>
        
          </View>
        </View>
        {laserOperation !== "Diffuse Reflective Repetitively Pulsed" ? (      
        <><View style={styles.row}>
            <div style={styles.titleBox}>
              <Text>Beam Properties</Text>
            </div>
          </View>
            <View style={[styles.row, styles.subTitle]}>
              <View>
                <Text>N.O.H.D.</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.NOHD?.value?.pretty ?? "N/A"}</Text>
                </View>
              </View>
              <View>
                <Text>E.N.O.H.D.</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.ENOHD?.value?.pretty}</Text>
                </View>
              </View>
              <View>
                <Text>Spot Size</Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.outputBeamDiameter}</Text>
                </View>
              </View>
              <View>
                <Text>Limit Aperture</Text>
                <View style={[styles.result]}>
                  <Text>Eye:{result?.limitAperture?.eye} mm</Text>
                  <Text>Skin:{result?.limitAperture?.skin} mm </Text>
                </View>
              </View>
              <View>
                <Text>Applicable Beam Area</Text>
                <View style={[styles.result]}>
                  <Text>Eye: {result?.beamArea?.applicablePretty } </Text>
                  <Text>Skin:  {result?.skinApplicableArea?.skinApplicableAreaPrettiest} </Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Spectrum</Text>
              </div>
            </View><View style={styles.center}>
              <View style={styles.center}>
                <Text style={[
                  styles.box,
                  {
                    backgroundColor: (() => {
                      if (result.spectrum.range == "Infrared") {
                        return "maroon";
                      }
                      else if (result.spectrum.range == "Ultraviolet") {
                        return "purple";
                      }
                      return "rgb(" + result.spectrum.rgb + ")";
                    })()
                  }
                ]}>
                </Text>
              </View>
              <View style={{ alignSelf: "center", marginLeft: "10px" }}>
                <Text>{result.spectrum.region}</Text>
              </View>
            </View></>
        ) : null}
      </View>

      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>
    
    {/**Moved beam properties to next page if laser operation is Repetitively Pulsed
     because it results wont fit on previouse page */}
    {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? (
      <Page size="LETTER" style={styles.page}>
        <Watermark />
        <View>
          <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
           </View>
          </View>
          <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Beam Properties</Text> 
              </div>
            </View>
              <View style={[styles.row, styles.subTitle]}>
                <View>
                  <Text>N.O.H.D.</Text>
                  <View style={[styles.center, styles.result]}>
                    <Text>{result?.NOHD?.value?.pretty ?? "N/A"}</Text>
                  </View>
                </View>
                <View>
                  <Text>E.N.O.H.D.</Text>
                  <View style={[styles.center, styles.result]}>
                    <Text>{result?.ENOHD?.value?.pretty}</Text>
                  </View>
                </View>
                <View>
                  <Text>Spot Size</Text>
                  <View style={[styles.center, styles.result]}>
                    <Text>{result?.outputBeamDiameter}</Text>
                  </View>
                </View>
                  <View>
                    <Text>Limit Aperture</Text> 
                    <View style={[styles.result]}>
                      <Text>Eye:{result?.limitingAperture.eye} mm</Text> 
                      <Text>Skin:{result?.limitingAperture.skin} mm </Text> 
                    </View>
                  </View> 
              </View>
            <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Spectrum</Text> 
              </div>
            </View>
            <View style={styles.center}>
                <View style={styles.center}>
                  <Text style={[
                      styles.box, 
                      {backgroundColor: (() => {
                        if (result.spectrum.range == "Infrared") {
                          return "maroon"
                        }
                        else if (result.spectrum.range == "Ultraviolet") {
                          return "purple"
                        }
                          return "rgb(" + result.spectrum.rgb + ")"
                        })()} 
                  ]}>
                  </Text>
                </View>
                <View style={{alignSelf:"center", marginLeft: "10px"}}>
                  <Text>{result.spectrum.region}</Text>
                </View>
            </View>
        </View>
        <View style={styles.footer}>
          <Image src={laserFooter}></Image>
        </View>
      </Page>

    ) : null}

    {/**Correction Factors */}
    <Page size="LETTER" style={styles.page}>
    <Watermark />
    <View>
      <View style={[styles.row, styles.header]}>
              <View>
                <Image src={logo} style={styles.image}></Image>
            </View>
              <View>
                <Text style={{marginTop:"20px"}}>{date}</Text>
              </View>
      </View>
      <Text> C) Correction Factors</Text>
      <View style={styles.row}>
                <div style={styles.titleBox}>
                  <Text>Correction Factors & Time Bases</Text>
                </div>
      </View>
      <View style={[styles.row, styles.correctionFactors]}>
        <View>  
          {result.correctionFactors.map(cf => { 
            if (cf.name === "T1" || cf.name === "T2") {
              return null // Skip rendering for filtered items
            }
            return (
              <Text style={{ ...styles.result, fontSize: "17px" }}>{cf?.name}: {cf?.pretty}</Text>
            )
          })}
        </View>
        <View>
          {result.correctionFactors.map(cf => {
            if (cf.name === "T1" || cf.name === "T2") {
              return <Text style={{ ...styles.result, fontSize: "17px" }}> {cf?.name}: {cf?.pretty}</Text>// Skip rendering for filtered items
            }
          })}
        </View>  
      </View>
      {laserOperation === "Diffuse Reflective Repetitively Pulsed"  ?
        <>
          <View style={styles.row}>
              <div style={styles.titleBox}>
                <Text>Pulse Datas</Text>
              </div>
          </View>
          {result.isEmployed ? 
            <View style={styles.center}>
              <Text style={styles.secondaryText}>Multiple Pulses Occur Within the Period Ti</Text>
            </View>
          : null}
          <View style={[styles.row, styles.subTitle]}>
            <View>
              <Text>Pulse Train Duration</Text> 
              <View style={[styles.center, styles.result]}>
                <Text>{result?.pulseTrainDuration}</Text>
              </View> 
            </View>
            <View>
              <Text>Number of Pulses</Text> 
              <View style={[styles.center, styles.result]}>
                <Text>{result?.Nv2.pretty}</Text> 
              </View>
            </View>
            {result.isEmployed ? 
              <>
              <View>
                <Text>T<Text style={[styles.subscript]}>i</Text></Text>
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.T_i}</Text> 
                </View>
              </View>
              <View>
                <Text>Pulses Contained in the Period T<Text style={[styles.subscript]}>i</Text></Text>
                  <View style={[styles.result]}>
                    <Text>{result?.PulsesWithinT_i}</Text> 
                  </View>
              </View>
              </>
            : null}
          </View>
      </>: null }
    </View>

      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>        
    </Page>

     {/**Classification Details Condition 1 */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />

    
      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View>
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
        <Text> D Classification Details</Text>
        <Text>These Accessible Emission Limit (AEL) has a computation time base of seconds.</Text>
        
        <Text>D.1 Condition 1</Text>

        <View style={[styles.row, styles.classification, { justifyContent:"space-around"}]}>
          <View>
            <Text>Laser Properties</Text> 
          </View>
          <View>
            <Text>Values</Text>
          </View>
        </View>

        <View style={[styles.row, { justifyContent:"space-around"}]}>
          <View>
            <Text>Aperture Stop</Text> 
            <Text>Aperture Distance</Text> 
            <Text>Angular Subtense</Text> 
            <Text>Value for C6</Text> 
            <Text>Acceptance Angle</Text> 

          </View>
          <View>
            <Text>{result.laserClass.condition1?.details.aperture === "NaNm" ? "N/A" : result.laserClass.condition1?.details.aperture}</Text> 
            <Text>{result.laserClass.condition1?.details.distance === "NaN m" ? "N/A" : result.laserClass.condition1?.distance}</Text> 
            <Text>{ result.laserClass.condition1.details.angularSubtense}</Text> 
            <Text>{ result.laserClass.condition1?.details.C6}</Text> 
            <Text>{ result.laserClass.condition1.details.angleOfAcceptance}</Text> 
          </View>
        </View>

        <View style={[styles.row, styles.classification, laserOperation === "Diffuse Reflective Repetitively Pulsed" ? styles.repPulsedAELHeader : null, { justifyContent:"space-around"}]}>
          <View>
            <Text style={{marginLeft:"10px"}}>Laser Class</Text> 
          </View>
          <View>
            <Text style={{ marginLeft: laserOperation === "Repetitively Pulsed" ? "25px" : "55px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>single</Text></Text>
          </View> 
          {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? (
          <>
          <View>
            <Text style={{ marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.Train</Text></Text>
          </View>
          <View>
            <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.T</Text></Text>
          </View> 
          <View>
            <Text style={{ marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "28px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>T</Text></Text>
          </View>
          </>
          ) : null}
          <View>
            <Text style={{marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "30px" : "55px" }}>EHV</Text>
          </View>
          <View >
            <Text style={{marginLeft:"40px"}}>Pass/Fail</Text>
          </View>
        </View>

        <View style={[styles.row, laserOperation === "Diffuse Reflective Repetitively Pulsed" ? styles.repPulsedAEL : null, { justifyContent:"space-around"}]}>
          <View>
            <Text>Class 1</Text> 
            <Text>Class 2</Text> 
            <Text>Class 3r</Text> 
            <Text>Class 3B</Text> 
          </View>
          <View>
            <Text>{result.laserClass.condition1.class.class1.joulesPretty === "nullJ" ? result?.laserClass?.condition1?.class?.class1?.joulesPerSqmPretty : result.laserClass.condition1.class?.class1?.joulesPretty != "nullJ" ?  result?.laserClass?.condition1?.class?.class1?.joulesPretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.class?.class2?.joulesPretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.class.class3r.joulesPretty === "nullJ" ? result.laserClass?.condition1?.class?.class3r?.joulesPerSqmPretty : result.laserClass.condition1.class?.class3r?.joulesPretty != "nullJ" ? result.laserClass.condition1?.class?.class3r?.joulesPretty : "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class3b.joulesPretty ?? "-"} </Text> 
          </View>

          {laserOperation === "Diffuse Reflective Repetitively Pulsed" || form.laserOperation === "FO_repetitivelyPulsed" ? (
         <>
          {/**pulse train */}
          <View>
            <Text>{result.laserClass.condition1.class.class1?.pulseTrain?.joulesPretty ??  "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class2?.pulseTrain?.joulesPretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.class.class3r?.pulseTrain?.joulesPretty ?? "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class3b?.pulseTrain?.joulesPretty ?? "-"} </Text> 
          </View>
           {/**AEL s.p.t */}
          <View>
            <Text>{result.laserClass.condition1.class.class1?.AELSPT?.joulesPretty ??  "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class2?.AELSPT?.joulesPretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.class.class3r?.AELSPT?.joulesPretty ?? "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class3b?.AELSPT?.joulesPretty ?? "-"} </Text> 
          </View>
           {/**average */}
          <View >
            <Text>{result.laserClass.condition1.class.class1?.average?.joulesPretty ?? result.laserClass.condition1.class.class1?.average?.joulesPerSqmPretty ?? "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class2?.average?.joulesPretty ?? "-"}</Text> 
            <Text>{result.laserClass.condition1.class.class3r?.average?.joulesPretty ?? result.laserClass.condition1.class.class3r?.average?.joulesPerSqmPretty ??  "-" }</Text> 
            <Text>{result.laserClass.condition1.class.class3b?.average?.joulesPretty ?? "-"} </Text> 
          </View>
          </>
          ) : null}
          
          <View>
            <Text>{ result.laserClass.condition1.class.class1.excess.pretty === "NaN" ? "--" :  result.laserClass.condition1.class.class1.excess.pretty}</Text> 
            <Text>{result.laserClass.condition1.class.class2.excess.pretty === "NaN" ? "--" : result.laserClass.condition1.class.class2.excess.pretty}</Text> 
            <Text>{result.laserClass.condition1.class.class3r.excess.pretty === "NaN" ? "--" : result.laserClass.condition1.class.class3r.excess.pretty}</Text>
            <Text>{result.laserClass.condition1.class.class3b.excess.pretty === "NaN" ? "--" : result.laserClass.condition1.class.class3b.excess.pretty}</Text> 
          </View>
          <View>
            <Text>{result.laserClass.condition1.class.class1.excess.val > 1 ? "Fail" : result.laserClass.condition1.class.class1.excess.val <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition1.class.class2.excess.val === null ? "-" : result.laserClass.condition1.class.class2.excess.val > 1 ? "Fail" : result.laserClass.condition1.class.class2.excess.val <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition1.class.class3r.excess.val > 1 ? "Fail" : result.laserClass.condition1.class.class3r.excess.val <= 1 ? "Pass" : null }</Text> 
            <Text>{result.laserClass.condition1.class.class3b.excess.val > 1 ? "Fail" : result.laserClass.condition1.class.class3b.excess.val <= 1 ? "Pass" : null }</Text> 
          </View>
        </View>

      <View style={[styles.row, styles.AccessibleEmission]}>
          <Text>Accessible Emission: {result?.laserClass?.condition1?.details.collectedEmission?.joulesPretty}</Text>
      </View>
      </View>
    
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
   
    </Page>

    {/**Classification Details Condition 3 */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />
   
        <View>
          <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
            </View>
            <View>
              <Text style={{ marginTop: "20px" }}>{date}</Text>
            </View>
          </View>
      
          <Text>D.2 Condition 3</Text>

          <View style={[styles.row, styles.classification, { justifyContent: "space-around" }]}>
            <View>
              <Text>Laser Properties</Text>
            </View>
            <View>
              <Text>Values</Text>
            </View>
          </View>

          <View style={[styles.row, { justifyContent: "space-around" }]}>
            <View>
              <Text>Aperture Stop</Text>
              <Text>Aperture Distance</Text>
              <Text>Angular Subtense</Text>
              <Text>Value for C6</Text>
              <Text>Acceptance Angle</Text>

            </View>
            <View>
              <Text>{result.laserClass.condition3?.details.aperture === "NaNm" ? "N/A" : result.laserClass.condition3?.details.aperture}</Text>
              <Text>{result.laserClass.condition3?.details.distance === "NaN m" ? "N/A" : result.laserClass.condition3?.details.distance}</Text>
              <Text>{result.laserClass.condition3.details.angularSubtense}</Text>
              <Text>{result.laserClass.condition3?.details.C6}</Text>
              <Text>{result.laserClass.condition3.details.angleOfAcceptance}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.classification, laserOperation === "Diffuse Reflective Repetitively Pulsed" ? styles.repPulsedAELHeader : null, { justifyContent: "space-around" }]}>
            <View>
              <Text style={{ marginLeft: "10px" }}>Laser Class</Text>
            </View>
            <View>
              <Text style={{ marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "25px" : "55px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>single</Text></Text>
            </View>
            {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? (
              <>
                <View>
                  <Text style={{ marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.Train</Text></Text>
                </View>
                <View>
                    <Text style={{marginLeft: laserOperation === "Repetitively Pulsed" ? "42px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>s.p.T</Text></Text>
                </View> 
                <View>
                  <Text style={{ marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "28px" : "65px" }}>AEL <Text style={{ fontSize: 8, marginTop: 2 }}>T</Text></Text>
                </View>
              </>
            ) : null}
            <View>
              <Text style={{ marginLeft: laserOperation === "Diffuse Reflective Repetitively Pulsed" ? "30px" : "55px" }}>EHV</Text>
            </View>
            <View >
              <Text style={{ marginLeft: "40px" }}>Pass/Fail</Text>
            </View>
          </View>

          <View style={[styles.row, laserOperation === "Diffuse Reflective Repetitively Pulsed" ? styles.repPulsedAEL : null, { justifyContent: "space-around" }]}>
            <View>
              <Text>Class 1</Text>
              <Text>Class 2</Text>
              <Text>Class 3r</Text>
              <Text>Class 3B</Text>
            </View>
            <View>
              <Text>{result.laserClass.condition3.class.class1.joulesPretty === "nullJ" ? result.laserClass.condition3.class.class1.joulesPerSqmPretty : result.laserClass.condition3.class.class1.joulesPretty != "nullJ" ? result.laserClass.condition3.class.class1.joulesPretty : "-"}</Text>
              <Text>{result.laserClass.condition3.class.class2?.joulesPretty ?? "-"}</Text>
              <Text>{result.laserClass.condition3.class.class3r.joulesPretty === "nullJ" ? result.laserClass.condition3.class.class3r.joulesPerSqmPretty : result.laserClass.condition3.class.class3r.joulesPretty != "nullJ" ? result.laserClass.condition3.class.class3r.joulesPretty : "-"}</Text>
              <Text>{result.laserClass.condition3.class.class3b.joulesPretty ?? "-"}</Text>
            
            </View>

            {laserOperation === "Diffuse Reflective Repetitively Pulsed" ? (
              <>
                {/**pulse train */}
                <View>
                  <Text>{result.laserClass.condition3.class?.class1?.pulseTrain?.joulesPretty ??  "-" }</Text> 
                  <Text>{result.laserClass.condition3.class?.class2?.pulseTrain?.joulesPretty ?? "-"}</Text> 
                  <Text>{result.laserClass.condition3.class?.class3r?.pulseTrain?.joulesPretty ?? "-" }</Text> 
                  <Text>{result.laserClass.condition3.class?.class3b?.pulseTrain?.joulesPretty ?? "-"} </Text> 
                </View>
                 {/**AEL s.p.t */}
                <View>
                  <Text>{result.laserClass.condition3.class.class1?.AELSPT?.joulesPretty ??  "-" }</Text> 
                  <Text>{result.laserClass.condition3.class.class2?.AELSPT?.joulesPretty ?? "-"}</Text> 
                  <Text>{result.laserClass.condition3.class.class3r?.AELSPT?.joulesPretty ?? "-" }</Text> 
                  <Text>{result.laserClass.condition3.class.class3b?.AELSPT?.joulesPretty ?? "-"} </Text> 
                </View>
                {/**average */}
                <View >
                  <Text>{result.laserClass.condition3.class.class1?.average?.joulesPretty ?? result.laserClass.condition3.class.class1?.average?.joulesPerSqmPretty ?? "-"}</Text>
                  <Text>{result.laserClass.condition3.class.class2?.average?.joulesPretty ?? "-"}</Text>
                  <Text>{ result.laserClass.condition3.class.class3r?.average?.joulesPretty ?? result.laserClass.condition3.class.class3r?.average?.joulesPerSqmPretty ?? "-"}</Text>
                  <Text>{result.laserClass.condition3.class.class3b?.average?.joulesPretty ?? "-"} </Text>
                </View>
              </>
            ) : null}

            <View>
              <Text>{result.laserClass.condition3.class.class1.excess.pretty === "NaN" ? "--" : result.laserClass.condition3.class.class1.excess.pretty}</Text>
              <Text>{result.laserClass.condition3.class.class2.excess.pretty === "NaN" ? "--" : result.laserClass.condition3.class.class2.excess.pretty}</Text>
              <Text>{result.laserClass.condition3.class.class3r.excess.pretty === "NaN" ? "--" : result.laserClass.condition3.class.class3r.excess.pretty}</Text>
              <Text>{result.laserClass.condition3.class.class3b.excess.pretty === "NaN" ? "--" : result.laserClass.condition3.class.class3b.excess.pretty}</Text>
           
            </View>
            <View>
              <Text>{result.laserClass.condition3.class.class1.excess.val > 1 ? "Fail" : result.laserClass.condition3.class.class1.excess.val <= 1 ? "Pass" : null }</Text> 
              <Text>{result.laserClass.condition3.class.class2.excess.val === null ? "-" : result.laserClass.condition3.class.class2.excess.val > 1 ? "Fail" : result.laserClass.condition3.class.class2.excess.val <= 1 ? "Pass" : null }</Text> 
              <Text>{result.laserClass.condition3.class.class3r.excess.val > 1 ? "Fail" : result.laserClass.condition3.class.class3r.excess.val <= 1 ? "Pass" : null }</Text> 
              <Text>{result.laserClass.condition3.class.class3b.excess.val > 1 ? "Fail" : result.laserClass.condition3.class.class3b.excess.val <= 1 ? "Pass" : null }</Text> 
            </View>
          </View>
          <View style={[styles.row, styles.AccessibleEmission]}>
            <Text>Accessible Emission: {result?.laserClass?.condition3?.details.collectedEmission?.joulesPretty}</Text>
          </View>
          <View style={[styles.row, styles.center]}>
          <div  style={[styles.center, styles.classVerdict]}>
            <Text>This Product is Classified as a Class {result.Class.Class} Laser</Text>
          </div>
        </View>
        </View>
     
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>

    {/**Eyewear */}
    <Page size="LETTER" style={styles.page}>
      <Watermark />

      <View>
        <View style={[styles.row, styles.header]}>
            <View>
              <Image src={logo} style={styles.image}></Image>
          </View> 
            <View>
              <Text style={{marginTop:"20px"}}>{date}</Text>
          </View>
        </View>
        <Text> E) Safety Eyewears and Filter</Text>
       
        <View style={styles.row}>
          <div style={styles.titleBox}>
                <Text>Optical Density, LB Rating, and RB Number</Text> 
          </div>
        </View>

        <View style={styles.row}>
         
          <View style={[styles.row, styles.subTitle]}>
            <View style={{ alignSelf: "center" }}><Text>Results at {operation?.distance?.num} {operation?.distance?.unit !== false ? operation?.distance?.unit : null}m</Text></View>
              <View style={{justifyContent:"center"}}>
                <Text>Nominal O.D.</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.NominalOD.OD}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Glass</Text> 
                <View style={[styles.center, styles.result]}>
                    <Text>{result?.Glass_average ?? result?.GlassAverage}  {result?.Glass}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Plastic</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.Plastic_average ?? result?.PlasticAverage}  {result?.Plastic}</Text>
                </View>
              </View>
              <View>
                <Text>RB Number </Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.RBNumber}</Text>
                </View>
              </View>
          </View>
        </View>
        <View style={[styles.row, { width: "100%", borderBottom: "1px solid black", marginBottom:"20px"}]}></View>
        
        <View style={[styles.row, styles.subTitle]}>
              <View style={{ alignSelf: "center" }}><Text>Results at 0.1m</Text> </View>
              <View style={{justifyContent:"center"}}>
                <Text>Nominal O.D.</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.NominalOD_distance.OD}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Glass</Text> 
                <View style={[styles.center, styles.result]}>
                    <Text>{result?.GlassAverage_Distance }  { result.Glass_Distance}</Text>
                </View>
              </View>
              <View>
                <Text>LB Number for Plastic</Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result?.PlasticAverage_Distance }  { result.Plastic_Distance}</Text>
                </View>
              </View>
              <View>
                <Text>RB Number </Text> 
                <View style={[styles.center, styles.result]}>
                  <Text>{result.RBNumber_distance}</Text>
                </View>
              </View>
        </View>    
      </View>
      <View style={styles.footer}>
        <Image src={laserFooter}></Image>
      </View>
    </Page>
  </Document>
)
}
