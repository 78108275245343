

import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { EnvironmentContext } from './EnvironmentContext'
import { Flex, Spin } from 'antd'
import { ReactComponent as Bars } from '../assets/Bars.svg'

/**
 *
 * @param {*} param0
 * @returns Wrapping any component using this component will check if RTLSS TOKEN is present and check if refresh token is valid.
 */
const ProtectedRoutes = ({ children }) => {
    const { isLocal } = useContext(EnvironmentContext)
    const [isVerified, setIsVerified] = useState(null)
    const [isFetching, setIsFetching] = useState(true)
    const [message, setMessage] = useState(null)
    const { pathname } = useLocation();



    useEffect(() => {
        const token = Cookies.get('RTLSS_TOKEN')
        const refreshToken = Cookies.get(`RTLSS_REFRESH`)


        console.log(`pathname changed or manualcheck triggered.`, token, refreshToken)
        setIsFetching(true)
        // console.log(`protected route was rendered.`)
        // console.log(`Use Context: `, isLocal)
        axios({
            method: 'post',
            url: isLocal ? 'http://localhost:8000/check-refresh' : 'api/check-refresh',
            data: token,
            timeout: 5000,
            timeoutErrorMessage: 'Connection timed out when checking for refresh token.',
            withCredentials: true
        })
            .then((res) => {
                // console.log(`Protected route Running`, res)
                setIsVerified(true)
            })
            .catch((err) => {
                console.error(err.response)
                setMessage(err.response.data.message)
                setIsVerified(false)

            })
            .finally(() => {
                setIsFetching(false)
            })
    }, [pathname]) //run only once.



    return (
        isFetching ? (
            <Flex align='center' justify='center' style={{ height: `calc(100vh - 48px)` }}>
                <Spin tip='Checking credentials..' indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", right: "50%", top: "60%", transform: "translate(-41%, -50%)" }} />}>
                    <div style={{ padding: 50 }} />
                </Spin>
            </Flex>

        ) : (
            isVerified ? (
                children
            ) : (
                <Navigate to='/login' state={{ message: message, setDisableNav: true }} />
            )
        )
    )
}

export default ProtectedRoutes
