import { Modal, Button, Form, Checkbox, Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import Cookies from 'js-cookie'

const rtllSettings = { expires: 365 }

const liStyle = {
  marginBottom: "5px"
}
export function ConfirmCalculateModal(props) {
  // var input = document.getElementById("myInput")

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (props.isConfirmOpen && event.key === 'Enter') {
        props.submitForm();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.isConfirmOpen, props.submitForm]);


  return (
    <Modal
      title="Disclaimer"
      // description="Are you sure you want to calculate?" // placement="topLeft"
      open={props.isConfirmOpen}
      onOk={props.submitForm} // confirmLoading={isSubmitting}
      onCancel={props.handleCancelPopConfirm}
      width={"60%"}
    >
      {/* Are you sure you want to calculate */}


      {/* <Typography.Text type='secondary'>
          RealTimeLSS is for informational purposes only and does not provide legal, technical, or professional advice. For safety compliance decisions, 
          consult a certified Lasermet Laser Safety Officer or another qualified professional. Click here to view the full  <a href="/terms-and-conditions" target="_blank" rel="noreferrer">terms and conditions</a>, or click <a href='https://www.lasermet.com/laser-safety-services/laser-protection-adviser/' target="_blank" rel="noreferrer">here</a> to request a consultation with a certified Lasermet Laser Safety Officer
        </Typography.Text> */}
      <Typography.Paragraph>

        The laser safety parameter calculation tool provided on this website is designed to assist users in calculating laser safety parameters based on the IEC 60825-1 standard. While we strive to ensure the accuracy and reliability of the calculations, the tool is provided "as is" without any guarantees or warranties of any kind, either express or implied.

      </Typography.Paragraph>
      <Typography.Paragraph>
        Accuracy and Limitations:
      </Typography.Paragraph>
      <ol>
        <li style={liStyle}>
          <strong>Accuracy:</strong> The tool is designed to provide accurate calculations in the vast majority of scenarios, with an expected accuracy rate significantly higher than 99%. However, due to the complexity and the high number of possible scenarios, it is not feasible to verify the accuracy of every single calculation. Users should be aware that there may be instances where the tool provides incorrect results.
        </li>
        <li style={liStyle}>
          <strong>No Professional Advice:</strong> The calculations and information provided by this tool are for informational purposes only and do not constitute professional advice. Users should not rely solely on the tool for making safety decisions and should consult with a qualified professional for specific advice tailored to their situation.
        </li>
        <li style={liStyle}>
          <strong>Limitation of Liability:</strong> In no event shall Lasermet, its affiliates, or its licensors be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the tool, including but not limited to, any errors or omissions in the calculations, even if advised of the possibility of such damages.
        </li>
        <li style={liStyle}>
          <strong>User Responsibility:</strong> Users are responsible for verifying the results obtained from the tool and for ensuring that they comply with all applicable safety standards and regulations. Lasermet does not assume any responsibility for the accuracy or completeness of the calculations or for any actions taken based on the results provided by the tool.
        </li>
      </ol>

      <Typography.Text type='secondary'>
        By using this tool, you acknowledge and agree to the terms of this disclaimer and accept full responsibility for any risks associated with its use.
      </Typography.Text>


      <Flex justify='right' style={{ marginBottom: "-20px" }}>
        <Form.Item
          label={`Don't show again`}>
          <Checkbox
            onChange={(e) => {
              if (Cookies.get('RTLSS_SETTINGS')) {

                if (e.target.checked) {
                  Cookies.set('RTLSS_SETTINGS', JSON.stringify({ ...JSON.parse(Cookies.get('RTLSS_SETTINGS')), hide_confirmation: true }), rtllSettings)
                } else {
                  Cookies.set('RTLSS_SETTINGS', JSON.stringify({ ...JSON.parse(Cookies.get('RTLSS_SETTINGS')), hide_confirmation: false }), rtllSettings)
                }
              } else {

                Cookies.set('RTLSS_SETTINGS', JSON.stringify({}), rtllSettings)
              }

            }} />
        </Form.Item>
      </Flex>

    </Modal>);
}
