import Axios from "axios"
import {React, useContext, useState, useEffect} from "react"
import { Form, Input, Card, Flex, Layout, Button, Row, Typography } from "antd"
import miniIcon from '../../assets/Logo/logosidetext.png'
import { useNavigate } from "react-router-dom"
import { EnvironmentContext } from "../../routes/EnvironmentContext"

const { Title } = Typography;

const {Content} = Layout




const VerifyCode =() =>{
    const { isLocal } = useContext(EnvironmentContext);
    const navigate = useNavigate()
    const { setEmail, setPage, otp, setOTP } = useContext(EnvironmentContext);
    const [isSpinning, setIsSpinning] = useState(false)
    const [isSpinning2, setIsSpinning2] = useState(false)
    const [timerCount, setTimer] = useState(60);
    const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
    const [disable, setDisable] = useState(true);

    const email = sessionStorage.getItem('userEmail');
    function resendOTP(){
    
        setIsSpinning2(true)
        Axios({
            method: 'post',
            url: 'http://localhost:8000/sendCode',
            data: {Email: email},
            withCredentials: true
        })
        .then((response)=>{
            setIsSpinning2(false)
            setDisable(true)
            setTimer(60)
           
        })
        .catch((error)=>{
            console.error(error)
            setIsSpinning(false)
        })       
    }

    const verifyCode=()=>{
        const {code} = form.getFieldValue()
        setIsSpinning(true)
        Axios({
            method: 'post',
            url: (()=>{
                if(isLocal === true){
                    return 'http://localhost:8000/fetchOTP'
                }
                else{
                    return 'api/fetchOTP'
                }
            })(),
            data: {email: email, code: code}
        })
        .then((res)=>{
            if(res.data.data === "Success"){
                navigate('/forgot-change-password')
            }
            setIsSpinning(false)
        })
        .catch((err)=>{
            setIsSpinning(false)
            form.setFields([
                {
                    name: "code",
                    errors: [err.response.data.message]
                }
            ])
            console.error("error")
        })
    }
    const checkEmail = () =>{
        if(!sessionStorage.getItem('userEmail')){
            navigate('/login')
            return false
        }
    }
    
    useEffect(() => {
        let interval = setInterval(() => {
          setTimer((lastTimerCount) => {
            lastTimerCount <= 1 && clearInterval(interval);
            if (lastTimerCount <= 1) setDisable(false);
            if (lastTimerCount <= 0) return lastTimerCount;
            return lastTimerCount - 1;
          });
        }, 1000); //each count lasts for a second
        //cleanup the interval on complete
        return () => clearInterval(interval);
      }, [disable]);

   
    const [form] = Form.useForm();
    return (
        <Content style={{ backgroundColor: 'white'}}>
            <Flex  justify='center' style={{ width: "100%"}}>
                <Card 
                    style={{
                        width: 600,
                        fontWeight: '500',
                        border:"none",
                        marginTop:"100px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        padding: "10px 0px 20px 0px"
                      
                    }}
                    // cover={
                    //     <Flex justify='center' align='center' style={{ marginBottom: "60px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                    //         <img
                    //             justify='center' align='center'
                    //             src={miniIcon}
                    //             style={{ width: "90%", justifySelf: "center"}}
                    //         />
                    //     </Flex> 
                    // }
                    >
                    <Row justify={"center"} style={{textAlign:"center"}}>
                    <Typography.Title level={1}>
                        VERIFY YOUR EMAIL ADDRESS
                    </Typography.Title>
                    </Row>
                    <Row justify={"center"} style={{textAlign:"center"}}>
                    A verification code has been sent to {email}
                    </Row>

                    <Row justify={"center"} style={{margin: '10px 0px 20px 0px'}}>
                    <Typography.Text type='secondary'>
                        Please check your inbox and enter the verification code below to verify your email address.
                    </Typography.Text>
                    <Typography.Text type='secondary' italic={true}>
                        Note: If you don’t see the email right away, please check your spam or junk folder. Sometimes email delivery may be delayed by a few minutes. If you still haven’t received the code after a short wait, you can request a new one.
                    </Typography.Text>
                    </Row>

                    <Form
                        onFinish={()=>verifyCode()}
                        form={form}
                       >
                        <Title level={5}></Title>
                        <Form.Item
                            style={{justifySelf:"center"}}
                            name="code"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your verification code!',
                                },
                            ]}
                            >
                            <Input.OTP size="large" style={{margin: '0px 0px 20px 0px'}}/>   
                        </Form.Item>
                        <Button htmlType="submit" type="primary" block loading={isSpinning}>
                        Verify Code
                        </Button>
                    </Form>
                    <Button  onClick={()=>resendOTP()} block disabled={disable? true : false} loading={isSpinning2} style={{margin:"10px 0px 0px 0px"}}>
                        {disable ? ` Resend OTP in ${timerCount}s` : "Resend OTP"}
                    </Button>
                </Card>
            </Flex>
        </Content>

    )
} 

export default VerifyCode