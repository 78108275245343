import { Table, Row, Collapse, Col, Flex, Typography, Space } from "antd";
import { Label } from "../TestAndLabelLinks";
import Title from "antd/es/skeleton/Title";
import { MathJax } from 'better-react-mathjax';
import { mostRestrictiveCheck, AEcompare, AEL, collectedEmission, excess, passFail } from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassResultsV2";

export function AELTab(props, condition){
//    console.log(props)

    return(
        <>
        <Space direction="vertical" size="large" style={{width: "100%"}}>
            {AELTable(props, 1)}
            {props.laserClass.laserOperation.includes("FO") ? 
            AELTable(props, 2) : null
            }
            {AELTable(props, 3)}
        </Space>
        </>
    )
}

function AELTable(props, condition){
    const extendedProps = { ...props, condition };
    const { Text, Title } = Typography
       
    const myAEL = {
        c1: {
            joules: AEL('c1', 'joules', extendedProps),
            watts: AEL('c1', 'watts', extendedProps)
        },
        c2: {
            joules: AEL('c2', 'joules', extendedProps),
            watts: AEL('c2', 'watts', extendedProps)
        },
        c3r: {
            joules: AEL('c3r', 'joules', extendedProps),
            watts: AEL('c3r', 'watts', extendedProps)
        },
        c3b: {
            joules: AEL('c3b', 'joules', extendedProps),
            watts: AEL('c3b', 'watts', extendedProps)
        }
    }
    
    return(
        <Table 
        title={(()=><Row justify="center" style={{ fontSize: '15px', fontWeight: "bold" }}>Condition {condition} Class {props.class}</Row>)}
        pagination= {false}
        bordered
        scroll={{ x: 600}} 
        justify="center"
        columns={[
        {
            key: 1,
            title: "",
            dataIndex: "class",
            align: "center"
        },
        {
            key: 2,
            title: "Accessible Emission Limit",
            dataIndex: "aelSingle",
            align: "center",
            children: props.laserOperation === "RP" || props.laserClass.laserOperation === "FO_RP"
                ?[
                    {
                        key: 1, 
                        title:<Text strong>AEL <sub>single </sub></Text>,
                        dataIndex: "aelSingle"
                    },
                    {
                        key: 2, 
                        title: <Text strong>AEL <sub>s.p. Train </sub></Text>,
                        dataIndex: "aelPulseTrain"
                    },
                    {
                        key: 3, 
                        title: <Text strong>AEL <sub>s.p.T</sub></Text>,
                        dataIndex: "aelSPT"
                    },
                ]
                : []
        },
        ...(props.laserClass.laserOperation === "RP" || props.laserClass.laserOperation === "FO_RP"
        ? [
            {
            key: 4,
            title: <Text strong>AEL <sub>T</sub></Text>,
            dataIndex: "aelTimeBase",
            onCell: (record) => ({
                style: {
                backgroundColor: record.aelTimeBase === "Critical" ? "#d9d9d9" : "white",
                color: record.aelTimeBase === "Critical" ? "gray" : "black",
                },
            }),
            },
        ]
        : []),
        {
            key: 3,
            title: "Emission Hazard Value",
            dataIndex: "safetyRatio",
            align: "center"
        },
        {
            key: 4,
            title: "Pass/Fail",
            dataIndex: "check",
            align: "center"
        }
        ]}


   
        dataSource={[
            // {
            //     key: 1,
            //     name: "accessibleEmission",
            //     class: "Class " + props.class.toUpperCase(),
            //     aelSingle: <Text style={mostRestrictiveCheck('single', [`c${props.class}`], props)} strong><Text type ={color()}>{props.laserClass[`condition${condition}`].AEL_standard.single[`c${props.class}`].joulesPretty ?? "-"}</Text></Text> ,
            //     aelPulseTrain: <Text style={mostRestrictiveCheck('pulseTrain', [`c${props.class}`], props)} strong type={AEcompare('pulseTrain', 'c1', props)}>{props.laserClass[`condition${condition}`].AEL_standard.pulseTrain[`c${props.class}`].pretty ?? "-"}</Text>,
            //     aelSPT: <Text style={mostRestrictiveCheck('AELSPT', [`c${props.class}`], props)} strong type={AEcompare('AELSPT', 'c1', props)}>{props.laserClass[`condition${condition}`].AEL_standard.AELSPT[`c${props.class}`].pretty ?? "-"}</Text>,
            //     aelTimeBase: props.laserClass[`condition${condition}`].AEL_standard.Average[`c${props.class}`].pretty ?? "-",
            //     safetyRatio: <Text strong type={color()}> {props.laserClass[`condition${condition}`].AEL_standard.Excess[`c${props.class}excess`].pretty ?? "-"}</Text>,
            //     check: <Text strong type={color()}>{check()}</Text>
            // },
            {
                key: 1,
                name: "accessibleEmission",
                class: "Class " + props.class.toUpperCase(),
                aelSingle: <Text style={mostRestrictiveCheck('single', [`c${props.class}`], extendedProps)} strong><Text type ={AEcompare('single', [`c${props.class}`], extendedProps)}>{myAEL[`c${props.class}`].joules.single ?? myAEL[`c${props.class}`].joules ?? '-'}</Text></Text> ,
                aelPulseTrain: <Text style={mostRestrictiveCheck('pulseTrain', [`c${props.class}`], extendedProps)} strong type={AEcompare('pulseTrain', [`c${props.class}`], extendedProps)}>{myAEL[`c${props.class}`].joules.pulseTrain ?? myAEL[`c${props.class}`].joules ?? "-"}</Text>,
                aelSPT: <Text style={mostRestrictiveCheck('AELSPT', [`c${props.class}`], extendedProps)} strong type={AEcompare('AELSPT', [`c${props.class}`], extendedProps)}>{myAEL[`c${props.class}`].joules.AELSPT || '-'}</Text>,
                aelTimeBase: <Text strong>{myAEL[`c${props.class}`].joules.average ?? myAEL[`c${props.class}`].joules ?? '-'}</Text>,
                // safetyRatio: <Text strong type={color()}> {props.laserClass[`condition${condition}`].AEL_standard.Excess[`c${props.class}excess`].pretty ?? "-"}</Text>,
                safetyRatio:  <Text strong type={passFail([`c${props.class}`], extendedProps).style}>{excess([`c${props.class}`], extendedProps)}</Text>,
                // check: <Text strong type={color()}>{check()}</Text>
                check: <Text strong type={passFail([`c${props.class}`], extendedProps).style}>{passFail([`c${props.class}`], extendedProps).value}</Text>
            },
            {
                key: 2,
                class: <Text strong>Accessible Emission</Text>,
                // aelSingle: <Text strong>{props.laserClass[`condition${condition}`].collectedEmissionJoules.joulesPretty ?? "-"} </Text>,
                // aelTimeBase: <Text strong>{props.laserClass[`condition${condition}`].collectedEmissionJoules.joulesPretty ?? "-"} </Text>,
                aelSingle: <Text strong>{`${collectedEmission('joules', undefined, extendedProps)} | ${collectedEmission('watts', undefined, extendedProps)}`}</Text>,
               

               
            }
        ]}
        footer={()=>(
            <Collapse 
                size="small"
                items={[
                    {
                        key: 1,
                        label:  ( 
                        <Flex justify='center'>
                            <Flex>
                                <Text strong type="warning">More Details</Text>
                            </Flex>
                        </Flex>
                        ),
                        children: (
                            <>
                            <Row justify={"space-between"} style={{width:"100%"}}>
                                <Col lg={12} sm={24} justify={"center"}>
                                    <Table 
                                    bordered
                                    style={{ width: '95%', justifySelf:"center" }}
                                    pagination= {false}
                                    columns={[
                                        {
                                            key: 1,
                                            title: "Correction Factor",
                                            dataIndex: "name",
                                            align: "right",
                                            width: 10
                                        },
                                        {
                                            key: 2,
                                            title: "Value",
                                            dataIndex: "value",
                                            align: "center",
                                            width: 10
                                        }
                                    ]}
                                    dataSource={[
                                        // {
                                        //     key: 1,
                                        //     name: "C5",
                                        //     value: props.class === "2" ? [
                                        //         Math.floor(props.laserClass.myC5v2.condition1_Class2 * 100) / 100
                                        //     ] : props.class === "3r" ?  Math.floor(props.laserClass.myC5v2[`condition${condition}_Class3R`] * 100) / 100
                                        //     : Math.floor(props.laserClass.myC5v2[`condition${condition}`] * 100) / 100
                                             
                                        // },
                                        // {
                                        //     key: 2,
                                        //     name: "C6 (t=Timebase)",
                                        //     value: props.laserClass.C6[`condition${condition}`].timebase
                                             
                                        // },
                                        // {
                                        //     key: 3,
                                        //     name: "C6 (t=pulse duartion)",
                                        //     value: props.laserClass?.C6[`condition${condition}`]?.extendedFixed
                                        // },
                                        ...props.laserClass.correctionFactors[`condition${condition}`].filter((cf) => cf.val !== null)
                                        .map((item, index) => ({
                                            key: 4 + index, 
                                            name: item.name,
                                            value: Math.floor(item.val* 100) / 100
                                        })),
                                    ]}
                            />
                                </Col>
                                <Col lg={12} sm={24}>
                                    <Table 
                                    style={{ width: '95%' }}
                                    pagination= {false}
                                    bordered
                                    columns={[
                                        {
                                            key: 1,
                                            title: "Measurement aperture diameters and measurement distances",
                                            dataIndex: "name",
                                            align: "right",
                                        },
                                        {
                                            key: 2,
                                            title: "Value",
                                            dataIndex: "value",
                                            align: "center",
                                            width: "130px"
                                        }
                                    ]}
                                    dataSource={[
                                        {
                                            key: 1,
                                            name: 'Aperture Stop',
                                            value: props.laserClass[`condition${condition}`]?.apertureStop === "NaNm" ? "--" : props.laserClass[`condition${condition}`]?.apertureStop
                                        },
                                        {
                                            key: 2,
                                            name: 'Aperture Distance',
                                            value: props.laserClass[`condition${condition}`]?.apertureDistance === "NaN m" ? "--" : props.laserClass[`condition${condition}`]?.apertureDistance
                                        },
                                        {
                                            key: 3,
                                            name: 'Angular Subtense',
                                            value: props.laserClass[`condition${condition}`]?.angularSubtense !== null ? props.laserClass[`condition${condition}`]?.angularSubtense : '-'
                                        },
                                    ]}
                                    />
                                </Col>
                            </Row>    
                            <br/>
                            <Row >
                                <Col lg={12} sm={24}>
                                    <Table 
                                            bordered
                                            style={{ width: '95%' }}
                                            pagination= {false}
                                            columns={[
                                                {
                                                    key: 1,
                                                    title: "Timebases",
                                                    dataIndex: "name",
                                                    align: "right",
                                                    width: 10
                                                },
                                                {
                                                    key: 2,
                                                    title: "Value",
                                                    dataIndex: "value",
                                                    align: "center",
                                                    width: 10
                                                }
                                            ]}
                                            dataSource={[
                                                {
                                                    key: 1,
                                                    name: "T",
                                                    value: props.laserClass.timeBase
                                                    
                                                },
                                                {
                                                    key: 2,
                                                    name: "Ti",
                                                    value: props.laserClass.T_i.eng + "s"
                                                    
                                                },
                                                {
                                                    key: 3,
                                                    name: "T2",
                                                    value: props?.laserClass[`condition${condition}`]?.T2?.toFixed(1)
                                                },
                                                ...props.laserClass.laserOperation !== "cw"?[
                                                {
                                                    key: 4,
                                                    name: "Pulse Duration",
                                                    value: props.laserClass.pulseDuration
                                                    
                                                }
                                                ] : [],
                                            ]}
                                    />
                                </Col>
                                {props.laserClass.laserOperation ==="RP" || props.laserClass.laserOperation ==="FO_RP" ? 
                                <Col lg={12} sm={24}>
                                    <Table 
                                            bordered
                                            style={{ width: '95%' }}
                                            pagination= {false}
                                            columns={[
                                                {
                                                    key: 1,
                                                    title: "Number of Pulses",
                                                    dataIndex: "name",
                                                    align: "right",
                                                    width: 10
                                                },
                                                {
                                                    key: 2,
                                                    title: "Value",
                                                    dataIndex: "value",
                                                    align: "center",
                                                    width: 10
                                                }
                                            ]}
                                            dataSource={[
                                                {
                                                    key: 1,
                                                    name: <Text>N <sub>T</sub></Text>,
                                                    value: props.laserClass.nv2
                                                    
                                                },
                                                {
                                                    key: 2,
                                                    name: <Text>N <sub>T2</sub></Text>,
                                                    value:props.laserClass[`condition${condition}`]?.NT2
                                                    
                                                },
                                            ]}
                                    />
                                </Col>
                                : null
                                }   
                            </Row>
                            
                    
                           
                         </>
                        )
                    }
                ]}
            />
        )}
    />
    )
}

