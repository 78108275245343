import { Flex, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const { Text } = Typography



const ErrorPage = (e) => {
    console.log(`Plasticino: `, e)



    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <MyFlex>
                <h1 style={{
                    fontSize: '12vw',
                    backgroundColor: '#2f8df6',
                    backgroundImage: `url('https://img.freepik.com/free-photo/hand-painted-watercolour-space-background-with-nebula-stars_1048-14650.jpg')`,
                    backgroundRepeat: 'repeat',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontFamily: 'revert-layer'
                }}>Oops!</h1>
                <Text style={{ fontSize: '3vw' }}><strong>404 - PAGE NOT FOUND</strong></Text>
                <Text style={{ fontSize: '1vw' }}>It seems you are lost.</Text>
                <Text style={{ fontSize: '1vw' }}> <Link to={'/home'}><strong>Click here</strong></Link> to be redirected to the homepage.</Text>
            </MyFlex>
        </div>
    )
}

const MyFlex = ({ children }) => {
    return (
        <Flex vertical justify='center' align='center' style={{ width: '100%', height: '100%' }}>
            {children}
        </Flex>
    )
}

export default ErrorPage