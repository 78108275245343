import { Table, Row, Collapse, Col, Flex, Typography, Space } from "antd";
import { Label } from "../TestAndLabelLinks";
import Title from "antd/es/skeleton/Title";
import { MathJax } from 'better-react-mathjax';
import { mostRestrictiveCheck, AEcompare, AEL, collectedEmission, excess, passFail } from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassResultsV2";
import ExcessHandler from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/TextHandlers/ExcessHandler";
import AELHandler from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/TextHandlers/AELHandler";
import AELPulseTrainHandler from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/TextHandlers/AELPulseTrainHandler";
import AELAverageHandler from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/TextHandlers/AELAverageHandler";
import PFHandler from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/TextHandlers/PFHandler";
import CEHandler from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/TextHandlers/CEHandler";

export function AELTabDiffuse(props, condition){
   console.log("AEL",props)

    return(
        <>
        <Space direction="vertical" size="large" style={{width: "100%"}}>
            {AELTable(props, 1)}
            {AELTable(props, 3)}
        </Space>
        </>
    )
}

function AELTable(props, condition){
    const extendedProps = { ...props, condition };
    const { Text, Title } = Typography
       
    const mostRestrictiveCheck = (aelType, laserClass) => {
        const x = {
            c1: 'class1',
            c2: 'class2',
            c3r: 'class3r',
            c3b: 'class3b'
        }
        const orange = '#F8BD14'
        if(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed"){
            if (condition === 1) {
                const mostRestrictive =  props?.laserClass?.condition1?.mostRestrictiveAEL[x[laserClass]]
                if (aelType === 'single') {
                    if ( props.laserClass?.condition1?.class[x[laserClass]]?.joules !== undefined && props.laserClass?.condition1?.class[x[laserClass]]?.joules !== null) {
                        
                            if (mostRestrictive ===  props.laserClass?.condition1?.class[x[laserClass]]?.joules) {
                                return { background: '#F8BD14' }
                            } else {
                                return { background: null }
                            }
                        
                    }
                } else {
                    if ( props?.laserClass?.condition1?.class[x[laserClass]][aelType]?.joules !== 0) {
                        if (mostRestrictive === props?.laserClass?.condition1?.class[x[laserClass]][aelType]?.joules && props.laserClass?.condition1?.class[x[laserClass]]?.joules !== props?.laserClass?.condition1?.class[x[laserClass]][aelType]?.joules ) {
                            return { background: orange }
                        } else { 
                            return { background: null }
                        }
                    }
                }
            }
            else{
                const mostRestrictive =  props?.laserClass?.condition3?.mostRestrictiveAEL[x[laserClass]]
                if (aelType === 'single') {
                    if ( props.laserClass?.condition3?.class[x[laserClass]]?.joules !== undefined && props.laserClass?.condition3?.class[x[laserClass]]?.joules !== null) {
                        
                            if (mostRestrictive ===  props.laserClass?.condition3?.class[x[laserClass]]?.joules) {
                                return { background: '#F8BD14' }
                            } else {
                                return { background: null }
                            }
                    }
                } else {
                    if ( props.laserClass?.condition3?.class[x[laserClass]][aelType]?.joules !== 0) {
                        if (mostRestrictive === props?.laserClass?.condition3?.class[x[laserClass]][aelType]?.joules && props.laserClass?.condition3?.class[x[laserClass]]?.joules !== props?.laserClass?.condition3?.class[x[laserClass]][aelType]?.joules ) {
                            return { background: orange }
                        } else {
                            return { background: null }
                        }
                    }
                }
            }
        }
       return null
    }
    
    return(
        <Table 
        title={(()=><Row justify="center" style={{ fontSize: '15px', fontWeight: "bold" }}>Condition {condition} Class {props.class}</Row>)}
        pagination= {false}
        bordered
        scroll={{ x: 600}} 
        justify="center"
        columns={[
        {
            key: 1,
            title: "",
            dataIndex: "class",
            align: "center"
        },
        {
            key: 2,
            title: "Accessible Emission Limit",
            dataIndex: "aelSingle",
            align: "center",
            children: props.laserOperation === "diffuseReflectiveRepetitivelyPulsed"
                ?[
                    {
                        key: 1, 
                        title:<Text strong>AEL <sub>single </sub></Text>,
                        dataIndex: "aelSingle"
                    },
                    {
                        key: 2, 
                        title: <Text strong>AEL <sub>s.p. Train </sub></Text>,
                        dataIndex: "aelPulseTrain"
                    },
                    {
                        key: 3, 
                        title: <Text strong>AEL <sub>s.p.T</sub></Text>,
                        dataIndex: "aelSPT"
                    },
                ]
                : []
        },
        ...(props.laserOperation === "diffuseReflectiveRepetitivelyPulsed"
        ? [
            {
            key: 4,
            title: <Text strong>AEL <sub>T</sub></Text>,
            dataIndex: "aelTimeBase",
            onCell: (record) => ({
                style: {
                backgroundColor: record.aelTimeBase === "Critical" ? "#d9d9d9" : "white",
                color: record.aelTimeBase === "Critical" ? "gray" : "black",
                },
            }),
            },
        ]
        : []),
        {
            key: 3,
            title: "Emission Hazard Value",
            dataIndex: "safetyRatio",
            align: "center"
        },
        {
            key: 4,
            title: "Pass/Fail",
            dataIndex: "check",
            align: "center"
        }
        ]}

        dataSource={[
            {
                key: 1,
                name: "accessibleEmission",
                class: "Class " + props.class.toUpperCase(),
                aelSingle:  <AELHandler style={mostRestrictiveCheck('single', [`c${props.class}`])} hec={props?.laserClass} condition={condition} laserClass={props.class} unitToReturn={'joules'}/>,
                aelPulseTrain: <AELPulseTrainHandler style={mostRestrictiveCheck('pulseTrain', [`c${props.class}`])} hec={props?.laserClass} condition={condition} laserClass={props.class} unitToReturn={'joules'}/>,
                aelSPT: <AELAverageHandler  style={mostRestrictiveCheck('AELSPT', [`c${props.class}`])} hec={props?.laserClass} condition={condition} laserClass={props.class} unitToReturn={'joules'}/>,
                aelTimeBase:  <Text>{props.laserClass[`condition${condition}`].class[`class${props.class}`]?.average?.joulesPretty}</Text>,
                safetyRatio:<ExcessHandler hec={props?.laserClass} condition={condition} laserClass={props.class} />,
                check: <PFHandler hec={props?.laserClass} condition={condition} laserClass={props.class} />
            },
            {
                key: 2,
                class: <Text strong>Accessible Emission</Text>,
                // aelSingle: <Text strong>{props.laserClass[`condition${condition}`].details.collectedEmission.joulesPretty ?? "-"} </Text>,
                aelSingle: <Text> {<CEHandler hec={props?.laserClass} condition={condition} unitToReturn={'joules'} />} | {<CEHandler hec={props?.laserClass} condition={condition} unitToReturn={'watts'} />}</Text>,
                // aelTimeBase: <Text strong>{props.laserClass[`condition${condition}`].details.collectedEmission.wattsPretty ?? "-"} </Text>,
                // aelSingle: <Text strong>{`${collectedEmission('joules', undefined, extendedProps)} | ${collectedEmission('watts', undefined, extendedProps)}`}</Text>,
                // aelSingle: <Text strong>{`${collectedEmission('joules', undefined, extendedProps)} | ${collectedEmission('watts', undefined, extendedProps)}`}</Text>,


               
            }
        ]}
        footer={()=>(
            <Collapse 
                size="small"
                items={[
                    {
                        key: 1,
                        label:  ( 
                        <Flex justify='center'>
                            <Flex>
                                <Text strong type="warning">More Details</Text>
                            </Flex>
                        </Flex>
                        ),
                        children: (
                            <>
                            <Row justify={"center"} style={{width:"100%"}}>
                                <Col lg={12} sm={24} style={{justify:"center"}}>
                                    <Table 
                                    bordered
                                    style={{ width: '95%' }}
                                    pagination= {false}
                                    columns={[
                                        {
                                            key: 1,
                                            title: "Correction Factor",
                                            dataIndex: "name",
                                            align: "right",
                                            width: 10
                                        },
                                        {
                                            key: 2,
                                            title: "Value",
                                            dataIndex: "value",
                                            align: "center",
                                            width: 10
                                        }
                                    ]}
                                    dataSource={[
                                        // {
                                        //     key: 1,
                                        //     name: "C5",
                                        //     value: props.class === "2" ? [
                                        //         Math.floor(props.laserClass.myC5v2.condition1_Class2 * 100) / 100
                                        //     ] : props.class === "3r" ?  Math.floor(props.laserClass.myC5v2[`condition${condition}_Class3R`] * 100) / 100
                                        //     : Math.floor(props.laserClass.myC5v2[`condition${condition}`] * 100) / 100
                                             
                                        // },
                                        // {
                                        //     key: 2,
                                        //     name: "C6 (t=Timebase)",
                                        //     value: props.laserClass.C6[`condition${condition}`].timebase
                                             
                                        // },
                                        // {
                                        //     key: 3,
                                        //     name: "C6 (t=pulse duartion)",
                                        //     value: props.laserClass?.C6[`condition${condition}`]?.extendedFixed
                                        // },
                                        ...props.laserClass[`condition${condition}`].correctionFactors.filter((cf) => cf.val !== null)
                                        .map((item, index) => ({
                                            key: 4 + index, 
                                            name: item.name,
                                            value: Math.floor(item.val* 100) / 100
                                        })),
                                    ]}
                            />
                                </Col>
                                <Col lg={12} sm={24}>
                                    <Table 
                                    style={{ width: '95%' }}
                                    pagination= {false}
                                    bordered
                                    columns={[
                                        {
                                            key: 1,
                                            title: "Measurement aperture diameters and measurement distances",
                                            dataIndex: "name",
                                            align: "right",
                                        },
                                        {
                                            key: 2,
                                            title: "Value",
                                            dataIndex: "value",
                                            align: "center",
                                            width: "130px"
                                        }
                                    ]}
                                    dataSource={[
                                        {
                                            key: 1,
                                            name: 'Aperture Stop',
                                            value: props.laserClass[`condition${condition}`]?.details.aperture === "NaNm" ? "--" : props.laserClass[`condition${condition}`].details?.aperture
                                        },
                                        {
                                            key: 2,
                                            name: 'Aperture Distance',
                                            value: props.laserClass[`condition${condition}`].details?.distance === "NaN m" ? "--" : props.laserClass[`condition${condition}`].details?.distance
                                        },
                                        {
                                            key: 3,
                                            name: 'Angular Subtense',
                                            value: props.laserClass[`condition${condition}`].details?.angularSubtense !== null ? props.laserClass[`condition${condition}`].details?.angularSubtense : '-'
                                        },
                                    ]}
                                    />
                                </Col>
                            </Row>    
                            <br/>
                            <Row justify={"center"} style={{width:"100%"}}>
                                <Col lg={12} sm={24}>
                                    <Table 
                                            bordered
                                            style={{ width: '95%' }}
                                            pagination= {false}
                                            columns={[
                                                {
                                                    key: 1,
                                                    title: "Timebases",
                                                    dataIndex: "name",
                                                    align: "right",
                                                    width: 10
                                                },
                                                {
                                                    key: 2,
                                                    title: "Value",
                                                    dataIndex: "value",
                                                    align: "center",
                                                    width: 10
                                                }
                                            ]}
                                            dataSource={[
                                                {
                                                    key: 1,
                                                    name: "T",
                                                    value: props.laserClass.condition1.timeBase
                                                    
                                                },
                                                {
                                                    key: 3,
                                                    name: "T2",
                                                    value: props?.laserClass[`condition${condition}`]?.T2
                                                },
                                              
                                                ...props.laserOperation.includes("diffuseReflectiveRepetitivelyPulsed") ?[
                                                {
                                                    key: 2,
                                                    name: "Ti",
                                                    value: props.laserClass.condition1.T_i
                                                    
                                                },
                                                {
                                                    key: 4,
                                                    name: "Pulse Duration",
                                                    value: props.laserClass.condition1.pulseDuration
                                                    
                                                }
                                                ] : [],
                                            ]}
                                    />
                                </Col>
                                {props.laserOperation === "diffuseReflectiveRepetitivelyPulsed" ? 
                                <Col lg={12} sm={24}>
                                    <Table 
                                            bordered
                                            style={{ width: '95%' }}
                                            pagination= {false}
                                            columns={[
                                                {
                                                    key: 1,
                                                    title: "Number of Pulses",
                                                    dataIndex: "name",
                                                    align: "right",
                                                    width: 10
                                                },
                                                {
                                                    key: 2,
                                                    title: "Value",
                                                    dataIndex: "value",
                                                    align: "center",
                                                    width: 10
                                                }
                                            ]}
                                            dataSource={[
                                                {
                                                    key: 1,
                                                    name: <Text>N <sub>T</sub></Text>,
                                                    value: props.laserClass[`condition${condition}`]?.N
                                                    
                                                },
                                                {
                                                    key: 2,
                                                    name: <Text>N <sub>T2</sub></Text>,
                                                    value:props.laserClass[`condition${condition}`]?.NT2
                                                    
                                                },
                                            ]}
                                    />
                                </Col>
                                : null
                                }   
                            </Row>
                            
                    
                           
                         </>
                        )
                    }
                ]}
            />
        )}
    />
    )
}

