import React, { useContext } from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Flex, Input, Button, Form, Col, Row, Upload, Card, Select, message, Typography } from 'antd';
import '../components/nav_bar.css'
import { useState } from 'react';
import axios from 'axios'
import { FooterHandler } from '../components/Footer/Footer';
import { EnvironmentContext } from '../routes/EnvironmentContext';

const { Option } = Select;
const { TextArea } = Input;
export const textCenter = { display: 'flex', justifyContent: 'center' }
const onChange = (e) => {

};

const Report = () => {
  const { myUser, isLocal } = useContext(EnvironmentContext)
  const [result, setResult] = useState(null)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage()

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    })
  }
  const showError = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
      duration: 4
    })
  }

  const onFinish = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/report'
        }
        else {
          return 'api/report'
        }
      })(),
      withCredentials: true,
      data: { firstName: myUser.firstName, lastName: myUser.lastName, data: form.getFieldsValue() }
    }).then((res) => {

      success(res.data.message)
    }).catch((err) => {
      console.error(err)
      showError(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      form.resetFields()

    })

  };

  return (

    <>
      {contextHolder}
      <Row justify="center">
        <Form form={form} layout="vertical" onFinish={onFinish} style={{ ...textCenter }}>
          <Card style={{ margin: "100px 0px 230px 0px", width: "100%" }} >
            <h2 align='center'>Report a Bug</h2>
            <Flex vertical >
              <Form.Item label="Title" name="title" rules={[{ required: true, message: ' Please select an option.' },]} >
                <Select
                  placeholder="Select title"
                  allowClear
                >
                  <Option value="MPE_AEL">MPE & AEL</Option>
                  <Option value="fiber">Fiber Optic</Option>
                  <Option value="diffuse">Diffused Reflective</Option>
                  <Option value="lookup">Lookup Table</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.title !== currentValues.title}
              >
                {({ getFieldValue }) =>
                  getFieldValue('title') === 'other' ? (
                    <Form.Item
                      name="other_title"
                      label="Other"
                      rules={[
                        {
                          required: true,
                          message: "Please input title."
                        },
                      ]}
                    >
                      <Input placeholder="Enter title" />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                help={
                  <Typography.Text type='secondary' italic>Describe the problem you have encountered. Please be as specific as possible.</Typography.Text>
                }
                label="Description"
                name="description"
                rules={[{ required: true, message: "Please input a description." }]}>
                <TextArea
                  onChange={onChange}
                  placeholder="Enter description"
                  style={{
                    height: "25vh",
                    width: "100%",
                    resize: 'none',
                  }} />
              </Form.Item>
              <div class="hover-underline-animation2" ><Button type="primary" htmlType="submit" style={{ width: '100%', background: '#1d1f8c', marginTop: '30px' }} loading={loading} >Submit Report</Button></div>
            </Flex>
          </Card>
        </Form>
      </Row>
      <FooterHandler />
    </>
  )
}

export default Report