import { Typography } from 'antd'
import React from 'react'
const { Text } = Typography


const AELAverageHandler = (props) => {
    const { unitToReturn, laserClass, condition, hec, result } = props

    const AEL = hec?.[`condition${condition}`]?.class?.[`class${laserClass}`].AELSPT

    const AELval = AEL?.watts
    const CE = hec?.[`condition${condition}`]?.details?.collectedEmission
    const CEval = hec?.[`condition${condition}`]?.details?.collectedEmission?.watts
    return (
        <Text
            strong
            style= {props.style}
            type={(() => {
                if (CEval <= AELval) return 'success'
                if (CEval >= AELval) return 'danger'
                return null
            })()}
        >
            {(() => {
                // AEL here
                if (unitToReturn === 'watts') {
                    return AEL?.wattsPretty  || '-'
                } else {
                    return AEL?.joulesPretty || '-'
                }
            })()}
        </Text>
    )
}

export default  AELAverageHandler