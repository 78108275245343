import { Button, Card, Form, Input, InputNumber, Radio, Select, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import Field from '../../../../components/Field'
import { NavLink } from 'react-router-dom'
import WavelengthField from './QuickFields/WavelengthField'

const SinglePulseCalculator = ({ form }) => {

    const [customPlaceholder, setCustomPlaceholder] = useState(`Pulse duration must be less than 250 ms`)
    const decimalForm = ({ unit, num }) => {
        switch (unit) {
            case 'T': {
                return num * 1e12
            }
            case 'G': {
                return num * 1e9
            }
            case 'M': {
                return num * 1e6
            }
            case 'k': {
                return num * 1e3
            }
            case false: {
                return num
            }
            case 'm': {
                return num * 1e-3
            }
            case 'u': {
                return num * 1e-6
            }
            case 'n': {
                return num * 1e-9
            }
            case 'p': {
                return num * 1e-12
            }
            case 'f': {
                return num * 1e-15
            }
        }
    }


    const handleUnitChange = (value) => {
        form.validateFields()
        switch (value) {
            case false: {
                setCustomPlaceholder('Time must be between 0 ~ 0.25 s')
                break
            }
            case 'm': {
                setCustomPlaceholder(`Time must between 0 ~ 250 ms`)
                break
            }
            case 'u': {
                setCustomPlaceholder(`Time must between 0 ~ 250,000 μs`)
                break
            }
            case 'n': {
                setCustomPlaceholder(`Time must between 0 ~ 250,000,000 ns`)
                break
            }
            case 'p': {
                setCustomPlaceholder(`Time must between 0 ~ 250,000,000,000 ps`)
                break
            }
            case 'f': {
                setCustomPlaceholder(`Time must between 0 ~ 250,000,000,000,000 fs`)
                break
            }

        }
    }

    return (
        <Card size='small' title='Single Pulse Settings'>
            {/* 
            <Tooltip title="prompt text"> */}
            <WavelengthField name={['singlePulse', 'wavelength']} />
            {/* <Field
                tabIndex={1}
                name={['singlePulse', 'wavelength']}
                label='Wavelength'
                unit='m'
                min='180'
                max='1000000'
                denoms={["n", "u"]}
                placeholder={`Enter laser's wavelength`}
                toolTipText={`The laser's wavelength.`}
            /> */}
            {/* </Tooltip> */}
            {/* 
            <Field
                name={['singlePulse', 'pulseDuration']}
                label='Pulse Duration'
                unit='s'
                denoms={['f', 'p', 'n', 'u', 'm', false]}
                max={0.25}
                placeholder='Time must be between 0 ~ 0.25 s'
                toolTipText={`The time it took for a whole pulse of laser radiation to be delivered.`}
                isSpecial={true}
            /> */}

            <Tooltip
                mouseEnterDelay={0.5}
                title={`The time it takes for a whole pulse of laser radiation to be delivered.`}
                placement='right'
                destroyTooltipOnHide
            >
                <Form.Item
                    label='Pulse Duration'
                    name={['singlePulse', 'pulseDuration', 'num']}
                    rules={[
                        { required: true, message: 'Please specify value.' },
                        () => ({
                            validator(_, value) {
                                let pulseDuration = form.getFieldsValue()?.singlePulse?.pulseDuration
                                if (decimalForm(pulseDuration) >= 0.25) {
                                    return Promise.reject(<>The pulse duration is greater than or equal to 0.25 s - This is a CW laser! <NavLink to='#' onClick={() => { form.setFieldValue(['laserOperation'], 'cw') }}>Click here</NavLink> to use the CW calculator instead.</>)
                                } else {
                                    return Promise.resolve()
                                }

                            }
                        })
                    ]}
                >
                    <InputNumber
                        tabIndex={2}
                        placeholder={customPlaceholder}
                        // max={customMin}
                        style={{ width: '100%' }}
                        addonAfter={
                            <>
                                <Form.Item noStyle
                                    name={['singlePulse', 'pulseDuration', 'unit']}
                                    rules={[{ required: true, message: 'Please specify unit.' }]}>
                                    <Select
                                        onChange={handleUnitChange}
                                        style={{ width: 80 }}
                                        options={(() => {
                                            let units = ['f', 'p', 'n', 'u', 'm', false]
                                            return units.map((unit) => {
                                                if (unit === false) {
                                                    return (
                                                        {
                                                            label: 's',
                                                            value: false
                                                        }
                                                    )
                                                } else if (unit === 'u') {
                                                    return (
                                                        {
                                                            label: 'µs',
                                                            value: unit
                                                        }
                                                    )
                                                } else {
                                                    return (
                                                        {
                                                            label: unit + 's',
                                                            value: unit
                                                        }
                                                    )
                                                }
                                            })
                                        })()}
                                    />
                                </Form.Item>
                            </>
                        } />
                </Form.Item>
            </Tooltip>

            {/* <Tooltip title="prompt text"> */}
            <Form.Item
                label='Beam Output Units'
                name={['singlePulse', 'outputUnits']}>
                <Radio.Group optionType='default'>
                    <Radio.Button value='energy'>Energy</Radio.Button>
                    <Radio.Button value='peakPower'>Peak Power</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* </Tooltip>
            <Tooltip title="prompt text"> */}
            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.singlePulse.outputUnits !== curr.singlePulse.outputUnits}>
                {({ getFieldValue }) => {
                    let outputUnits = getFieldValue(['singlePulse', 'outputUnits'])
                    return (
                        outputUnits === 'energy' ? (
                            <Field
                                tabIndex={3}
                                name={['singlePulse', 'energy']}
                                label='Energy'
                                unit='J'
                                denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M', 'G']}
                                placeholder="Enter laser's pulse energy"
                                min={0.1}
                                toolTipText={'The output of the laser expressed as Energy (J).'}
                            />
                        ) : (
                            outputUnits === 'peakPower' ? (
                                <Field
                                    tabIndex={3}
                                    name={['singlePulse', 'peakPower']}
                                    label='Peak Power'
                                    unit='W'
                                    denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M', 'G']}
                                    placeholder="Enter laser's pulse peak power"
                                    min={0.1}
                                    toolTipText={`The output of the laser expressed as Peak Power (W)`}
                                />
                            ) : (null)
                        )
                    )
                }}
            </Form.Item>
            {/* // </Tooltip>
            // <Tooltip title="The laser's initial beam diameter">
            //     <Form.Item> */}
            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
                {({ getFieldValue }) => {
                    let sourceType = getFieldValue(['sourceType'])
                    return (
                        sourceType === 'point' ? (
                            <Field
                                tabIndex={4}
                                name={['singlePulse', 'beamDiameter']}
                                label='Beam Diameter'
                                unit='m'
                                denoms={["m", "u"]}
                                min='0.1'
                                placeholder="Enter laser's beam diameter"
                                toolTipText={`The diameter of the source of the laser beam.`}
                            />) : null
                    )
                }}
            </Form.Item>
            {/* </Form.Item>
            </Tooltip>
            <Tooltip  title="Far field plane angle of the cone defined by the beam diameter">
                <Form.Item > */}
            <Field
                tabIndex={5}
                name={['singlePulse', 'beamDivergence']}
                label='Beam Divergence'
                unit='rad'
                denoms={[false, 'm', 'u', 'degree']}
                min='0.01'
                placeholder="Enter laser's beam divergence"
                toolTipText={`The far field plane angle of the cone defined by the beam diameter.`}
            />
            {/* </Form.Item>
            </Tooltip>
            <Tooltip title="Distance of the target from the laser">
            <Form.Item > */}
            <Field
                tabIndex={6}
                name={['singlePulse', 'distance']}
                label='Distance to target'
                unit='m'
                denoms={['k', false, 'm', 'c', 'u']}
                min='0.01'
                placeholder='Enter distance from laser'
                toolTipText={`The distance between the source of the laser beam and the target.`}
            />
            {/* </Form.Item>
            </Tooltip> */}
        </Card>
    )
}

export default SinglePulseCalculator