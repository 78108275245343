import { Tabs, Row } from "antd";
import { LaserClassResultsV2 } from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassResultsV2";
import LaserClassVerdict from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassVerdict";
import { AELTabDiffuse } from "./AELTabDiffuse";
import { LaserClassResultsDiffuseReflective } from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassResultsDiffuseReflective";

export function AllAELDiffuse(props){
    const {result, laserOperation } = props
        return(
        <Tabs
            style={{width:"100%"}}
            centered
            items={[
            {
                key: 1,
                label: "Overall",
                children:
                <>
                        <LaserClassResultsDiffuseReflective result={result} condition={1} laserOperation={laserOperation}/>
                        <LaserClassResultsDiffuseReflective result={result} condition={3} laserOperation={laserOperation}/>
                        <LaserClassVerdict laserClass={result.Class}/>
                </>
            },
            {
                key: 2,
                label: "Class 1",
                children: 
                <>
                   <AELTabDiffuse class={"1"} laserClass={result.laserClass} laserOperation={laserOperation}/>
                </>
            },
            {
                key: 3,
                label: "Class 2",
                children:   
                <>
                   <AELTabDiffuse class={"2"} laserClass={result.laserClass} laserOperation={laserOperation}/>
                   </>
            },
            {
                key: 4,
                label: "Class 3R",
                children:  
                <>
                   <AELTabDiffuse class={"3r"} laserClass={result.laserClass} laserOperation={laserOperation}/>
                   </>
            },
            {
                key: 5,
                label: "Class 3B",
                children:   
                <>
                   <AELTabDiffuse class={"3b"} laserClass={result.laserClass} laserOperation={laserOperation}/>
                   </>
            }

        ]}
        />
    )
}