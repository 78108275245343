import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { MathJaxContext } from 'better-react-mathjax';
import { EnvironmentProvider } from './routes/EnvironmentContext';
import { ConfigProvider } from 'antd'
import Loginv2 from './pages/Loginv2'
import ErrorPage from './pages/ErrorPages/ErrorPage';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Home from './pages/Home';
import MPE_AEL from './pages/Loginv2/Calculators/MPE_AEL';
import FiberOptic from './pages/Loginv2/Calculators/FiberOptic';
import DiffuseReflectiveCalculator from './pages/Loginv2/Calculators/DiffuseReflectiveCalculator';
import LookupTable from './pages/Loginv2/Calculators/LookupTable';
import Report from './pages/Report';
import Register from './pages/Register';
import RegistrationSuccess from './pages/RegistrationSuccess';
import ChangeProfile from './pages/ChangeProfile';
import PurchaseSuccess from './components/PurchaseSuccess';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import { Contacts } from './components/Footer/Contact';
import { TermsAndConditions } from './components/Footer/TermsAndCondition';
import { PrivacyPolicy } from './components/Footer/PrivacyPolicy';
import { Disclaimer } from './components/Footer/Disclaimer';
import { About } from './components/Footer/About';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import VerifyCode from './components/ForgotPassword/VerifyCode';
import ForgotChangePassword from './components/ForgotPassword/ChangePassword';
import ForgotPasswordSuccess from './components/ForgotPassword/ForgotPasswordSuccess';
import Logout from './pages/Logout';



const isLocal = Boolean(process.env?.['REACT_APP_IS_LOCAL'])

console.log(`IS LOCALINO: `, isLocal)


const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        )
      },
      {
        path: '/home',
        element: (
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        )
      },
      {
        path: '/login',
        element: (<Loginv2 />)
      },
      {
        path: '/mpe-ael-calc',
        element: (
          <ProtectedRoutes>
            <MPE_AEL />
          </ProtectedRoutes>
        )
      },
      {
        path: '/fiber-optic',
        element: (
          <ProtectedRoutes>
            <FiberOptic />
          </ProtectedRoutes>
        )
      },
      {
        path: 'diffuse-reflective',
        element: (
          <ProtectedRoutes>
            <DiffuseReflectiveCalculator />
          </ProtectedRoutes>
        )
      },
      {
        path: '/lookup-table',
        element: (
          <ProtectedRoutes>
            <LookupTable />
          </ProtectedRoutes>
        )
      },
      {
        path: '/edit-profile',
        element: (
          <ProtectedRoutes>
            Under construction.
          </ProtectedRoutes>
        )
      },
      {
        path: '/report',
        element: (
          <ProtectedRoutes>
            <Report />
          </ProtectedRoutes>
        )
      },
      {
        path: '/register',
        element: (
          <Register />
        )
      },
      {
        path: '/confirm',
        element: (
          <RegistrationSuccess />
        )
      },
      {
        path: '/secure-change',
        element: (
          <ProtectedRoutes>
            <ChangeProfile />
          </ProtectedRoutes>
        )
      },
      {
        path: '/purchase-success',
        element: (
          <ProtectedRoutes>
            <PurchaseSuccess /> {/* In the previous version, props {isLocal, setMyUser, myUser } was passed but not used. It's now deleted here to avoid confusion. */}
          </ProtectedRoutes>
        )
      },
      {
        path: '/subscription-success',
        element: (
          <ProtectedRoutes>
            <SubscriptionSuccess /> {/* In the previous version, props {isLocal, setMyUser, myUser } was passed but not used. It's now deleted here to avoid confusion. */}
          </ProtectedRoutes>
        )

      },
      {
        path: '/contacts',
        element: (
          <Contacts />
        )
      },
      {
        path: '/terms-and-conditions',
        element: (
          <TermsAndConditions />
        )
      },
      {
        path: '/privacy-policy',
        element: (
          <PrivacyPolicy />
        )
      },
      {
        path: '/disclaimer',
        element: (
          <Disclaimer />
        )
      },
      {
        path: '/about',
        element: (
          <ProtectedRoutes>
            <About />
          </ProtectedRoutes>
        )
      },
      {
        path: '/forgotPassword',
        element: (
          <ForgotPassword />
        )
      },
      {
        path: '/verifyCode',
        element: (
          <VerifyCode />
        )
      },
      {
        path: '/forgot-change-password',
        element: (
          <ForgotChangePassword />
        )
      },
      {
        path: '/forgot-password-success',
        element: (
          <ForgotPasswordSuccess />
        )
      },
      {
        path: '/logout',
        element: (
          <ProtectedRoutes>
            <Logout />
          </ProtectedRoutes>
        )
      },
      {
        path: ''
      },
    ]
  },
  {
    path: '*',
    element: <ErrorPage />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }
root.render(

  <>
    <React.StrictMode>
      <EnvironmentProvider isLocal={isLocal}>
        <MathJaxContext>
          <ConfigProvider
            theme={
              {
                token: {
                  colorText: "#1d1f8c",
                  colorPrimary: "#1d1f8c"
                },
              }
            }
          >
            <RouterProvider router={router} />
          </ConfigProvider>
        </MathJaxContext>
      </EnvironmentProvider>
    </React.StrictMode >
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
