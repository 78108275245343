import { Tabs, Row } from "antd";
import { LaserClassResultsV2 } from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassResultsV2";
import LaserClassVerdict from "../../pages/Loginv2/Calculators/Results/MPE_AEL_Results/LaserClassVerdict";
import { AELTab } from "./AEL";
export function AllAEL(props){
        return(
        <Tabs
            style={{width:"100%"}}
            centered
            items={[
            {
                key: 1,
                label: "Overall",
                children:
                <>
                        <LaserClassResultsV2 laserClass={props.laserClass} condition={1} />
                        {props.laserClass.laserOperation.includes("FO") ? 
                        <LaserClassResultsV2 laserClass={props.laserClass} condition={2} /> : null
                        }
                        <LaserClassResultsV2 laserClass={props.laserClass} condition={3} />
                        <LaserClassVerdict laserClass={props.laserClass} />
                </>
            },
            {
                key: 2,
                label: "Class 1",
                children: 
                <>
                    <AELTab class={"1"} laserClass={props.laserClass} laserOperation={props.laserOperation}/>
                </>
            },
            {
                key: 3,
                label: "Class 2",
                children:   
                <>
                    <AELTab class={"2"} laserClass={props.laserClass} laserOperation={props.laserOperation}/>
                </>
            },
            {
                key: 4,
                label: "Class 3R",
                children:  
                <>
                    <AELTab class={"3r"} laserClass={props.laserClass} laserOperation={props.laserOperation}/>
                </>
            },
            {
                key: 5,
                label: "Class 3B",
                children:   
                <>
                    <AELTab class={"3b"} laserClass={props.laserClass} laserOperation={props.laserOperation}/>
                </>
            }

        ]}
        />
    )
}