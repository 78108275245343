import { Card, Row, Typography, Flex } from "antd";
import { FooterHandler } from "./Footer";

export function TermsAndConditions() {
    const { Paragraph, Text, Title } = Typography
    return (
        <>
            <Row style={{ width: "100%", minHeight: "100vh", display: "flex", justifyContent: "center", marginTop: "60px", marginBottom: "50px" }}>
                <Row className="content">
                    <Card className="scroll" bordered={false} style={{ width: "100%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: "20px",}}>
                        <Flex justify="center">
                            <Typography.Title>
                                Terms & Conditions
                            </Typography.Title>
                        </Flex>

                        <Typography>
                            <Paragraph>
                                <Text>
                                    Welcome to the Laser Safety Parameter Calculation Tool known as RealTime LSS (“the Tool” or “Tool”) provided by Lasermet (“Company,” “we,” or “us”).
                                    These Terms of Use (“Terms”) govern your access to and use of the Tool. By accessing or using the Tool, you (“user” or “you”)
                                    acknowledge and agree to comply with these Terms. If you do not agree with any part of these Terms, you must refrain from accessing or using the Tool.
                                </Text>
                                <Text>
                                    We strongly encourage users to review these Terms in detail. For your convenience, we have included section headings for easy navigation.
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    1. General Information
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    1.1 Purpose:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The Tool is designed to assist users in calculating laser safety parameters in compliance with the IEC 60825-1 standard.
                                It is intended to provide general guidance on laser safety measures by generating calculations based on the data input by users.
                                The Tool is not a substitute for expert advice or professional judgment.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    1.2 Informational Use Only:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The Tool is provided solely for informational and educational purposes. It does not replace the need for professional consultation or adherence to formal laser safety regulations.
                                Users must independently verify the accuracy and applicability of the calculations generated by the Tool to their specific circumstances.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    1.3 Governing Body Specific Compliance::
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The Tool is not tailored to meet the specific laser safety requirements of individual states or countries. Users are responsible for ensuring compliance with their specific laws.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    1.4 Acceptance of Terms:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                By accessing or using the Tool, you confirm that you have read, understood, and accepted these Terms of Use in their entirety.
                                If you do not agree with these Terms, you must refrain from using the Tool.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    2. User Responsibilities
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    2.1 Accuracy of Inputs:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The accuracy and relevance of the Tool’s outputs depend entirely on the data inputs provided by users.
                                It is your responsibility to input accurate, complete, and relevant data to achieve meaningful results.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    2.2 Verification of Results:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                You acknowledge and agree that the results provided by the Tool are estimates based on the IEC 60825-1 standard and may not account for state-specific requirements, including those in Illinois.
                                It is your responsibility to verify the accuracy of these results before applying them in a real-world context.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    2.3 Prohibited Activities:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                You agree not to:
                                <ul>
                                    <li>
                                        Use the Tool for any unlawful, deceptive, or fraudulent purpose.
                                    </li>
                                    <li>
                                        Access the Tool through unauthorized means, including automated methods such as bots or scripts.
                                    </li>
                                    <li>
                                        Attempt to reverse-engineer, modify, copy, or create derivative works based on the Tool or its underlying algorithms.
                                    </li>
                                    <li>
                                        Distribute or share the outputs of the Tool in a manner that misrepresents its purpose.
                                    </li>
                                    <li>
                                        Share access with a user who is not an acting agent of said user.
                                    </li>
                                </ul>

                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    2.4 Compliance with Illinois and Federal Laws:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Users operating in their specific state or county must ensure compliance with all applicable laws, including those governing laser safety, workplace safety, and radiation control.
                                Users are encouraged to consult qualified professionals familiar with state specific safety regulations.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    3. Disclaimer of Warranties
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    3.1 "As Is" and "As Available" Basis:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The Tool is provided “as is” and “as available” without warranties of any kind, express or implied, including but not limited to warranties of merchantability,
                                fitness for a particular purpose, non-infringement, or error-free operation.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    3.2 No Guarantees:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Lasermet does not guarantee the reliability, completeness, or accuracy of the Tool’s outputs. Users rely on the Tool at their own risk.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    3.3 No Professional Advice:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The Tool does not constitute legal, technical, or professional advice.
                                For decisions involving safety compliance, users should consult a certified laser safety officer or other qualified professionals.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    4. Limitation of Liability
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    4.1 Limitation to the Extent Permitted by Law:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                To the fullest extent permitted under state and federal law, Lasermet and its affiliates, officers, employees, and agents are not liable for any direct, indirect, incidental,
                                consequential, punitive, or special damages arising from your use of the Tool. This limitation includes, but is not limited to:
                                <ul>
                                    <li>Personal injury or property damage.</li>
                                    <li>Loss of profits, data, or business opportunities.</li>
                                    <li>Any reliance placed on the Tool’s outputs.</li>

                                </ul>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    4.2 Assumption of Risk:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                By using the Tool, you acknowledge and accept the inherent risks associated with reliance on automated calculations.
                                You agree that Lasermet is not responsible for any adverse outcomes resulting from such reliance.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    4.3 Indemnification:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                You agree to indemnify, defend, and hold harmless Lasermet, its affiliates, officers, employees, and agents from any claims, liabilities, damages, or expenses arising from your use of the Tool or violation of these Terms.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    5. Intellectual Property
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    5.1 Ownership:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                All rights, title, and interest in and to the Tool, including its content, design, algorithms, and functionality, remain the exclusive property of Lasermet.
                                These rights are protected under U.S. and international copyright, trademark, and intellectual property laws.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    5.2 License:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Lasermet grants you a non-exclusive, limited, revocable license to access and use the Tool for its intended purpose. This license does not transfer ownership rights to you.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    5.3 Restrictions:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                You may not:
                                <ul>
                                    <li>Reproduce, modify, or distribute the Tool without prior written consent from Lasermet.</li>
                                    <li>Remove, alter, or obscure any proprietary notices on the Tool.</li>
                                </ul>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    6. Privacy
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    6.1 Data Collection:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                The Tool may collect anonymized data to improve its functionality and performance.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    7. Governing Law and Dispute Resolution
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    7.1 Governing Law:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                These Terms shall be governed by and construed in accordance with the laws of the State of Illinois, excluding its conflict of laws principles.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    7.2 Jurisdiction and Venue:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Any legal disputes arising from these Terms shall be subject to the exclusive jurisdiction of the state and federal courts located in Cook County, Illinois.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    7.3 Arbitration Clause:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <ul>
                                    <li>Disputes that cannot be resolved amicably shall be submitted to binding arbitration administered by an Illinois-based arbitration institution, such as the American Arbitration Association (AAA).</li>
                                    <li>Arbitration shall take place in Cook County, Illinois, and be conducted under AAA rules.</li>
                                    <li>The arbitrator’s decision shall be final and binding on both parties.</li>
                                </ul>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    8. Modifications to the Terms
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    8.1 Right to Modify:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Lasermet reserves the right to update or modify these Terms at any time without prior notice.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    8.2 Notification:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Changes will take effect upon posting updated Terms on our website. Continued use of the Tool constitutes acceptance of the updated Terms.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    9. Termination
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    9.1 Right to Terminate:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Lasermet may terminate or suspend your access to the Tool at its sole discretion, with or without cause or notice.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    9.2 Effect of Termination:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                Upon termination, all rights granted under these Terms shall immediately cease.
                            </Paragraph>
                            <Paragraph>
                                <Text strong>
                                    10. Acceptance of Terms
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                By clicking “I Agree” or using the Tool, you confirm that you have read, understood, and agreed to these Terms of Use in their entirety.
                            </Paragraph>
                        </Typography>
                    </Card>
                </Row>
            </Row>
            <FooterHandler />
        </>
    )

}