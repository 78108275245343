import { Table, Flex, Collapse, Typography, Space, List, Col, Row } from 'antd'
import React from 'react'

const { Text, Title } = Typography

function LaserClassResultsV2(props) {
    const condition = props.condition || 1
    const { laserOperation, CE3isPersqm, condition1, condition2, condition3, timeBase, conditionIsEmpty } = props.laserClass
    /**
     * 
     * @param {*} laserClass  'c1', 'c2', 'c3r' or 'c3b'
     * @param {*} unitToReturn 'joules' or 'watts'
     * @returns Returns the AEL given the laser class and unit to return.
     */
   
    /**
     * 
     * @param {*} laserClass 
     * @returns returns the excess given the laser class.
     */


    /**
     * 
     * @param {*} aelType 'single', 'pulseTrain' or 'Average'
     * @param {*} laserClass 
     */


    const data = (() => {
        let column = []
        let dataSource = []
        if (laserOperation === 'RP' || laserOperation === "FO_RP") {
            column = [
                {
                    key: 1,
                    title: 'Laser Class',
                    dataIndex: 'name',
                    align: 'center',
                    width: '20%'
                },
                {
                    key: 2,
                    title: 'Accessible Emission Limit',
                    align: 'center',
                    children: ([
                        {
                            key: 1,
                            title: <Text strong>AEL <sub>single </sub></Text>,
                            dataIndex: 'joulesPerSqm',
                            align: 'center',
                            // children: ([
                            //     {
                            //         key: 1,
                            //         title: 'Joules',
                            //         dataIndex: 'joulesPerSqm',
                            //         align: 'center'
                            //     },
                            //     // {
                            //     //     key: 2,
                            //     //     title: 'Watts',
                            //     //     dataIndex: 'wattsPerSqm',
                            //     //     align: 'center'
                            //     // }
                            // ])
                        },
                        {
                            key: 2,
                            title: <Text strong>AEL <sub>s.p. Train </sub></Text>,
                            dataIndex: 'PTjoulesPerSqm',
                            align: 'center',
                            // children: ([
                            //     {
                            //         key: 1,
                            //         title: 'Joules',
                            //         dataIndex: 'PTjoulesPerSqm',
                            //         align: 'center'
                            //     },
                            //     // {
                            //     //     key: 2,
                            //     //     title: 'Watts',
                            //     //     dataIndex: 'PTwattsPerSqm',
                            //     //     align: 'center'
                            //     // }
                            // ])
                        },
                        {
                            key: 3,
                            title: <Text strong>AEL <sub>s.p.T</sub></Text>,
                            dataIndex: 'AELSPT',
                            align: 'center',
                            // children: ([
                            //     {
                            //         key: 1,
                            //         title: 'Joules',
                            //         dataIndex: 'AELSPT',
                            //         align: 'center'
                            //     },
                            // ])
                        },
                       
                    ])
                },
                {
                    key: 3,
                    title: <Text strong>AEL <sub>T</sub></Text>,
                    dataIndex: 'AvejoulesPerSqm',
                    align: 'center',
                    // children: ([
                    //     {
                    //         key: 1,
                    //         title: 'Joules',
                    //         dataIndex: 'AvejoulesPerSqm',
                    //         align: 'center'
                    //     },
                    //     // {
                    //     //     key: 2,
                    //     //     title: 'Watts',
                    //     //     dataIndex: 'AvewattsPerSqm',
                    //     //     align: 'center'
                    //     // }
                    // ])
                },
                {
                    key: 4,
                    title: 'Emission Hazard Value',
                    dataIndex: 'excess',
                    align: 'center',
                    width: '15%'
                },
                {
                    key: 5,
                    title: 'Pass/Fail',
                    dataIndex: 'pf',
                    align: 'center',
                    width: '15%'
                }

            ]

            // The indeces for the following are typed below:
            // ==============================================
            // Single      =>     wattsPerSqm  |   joulesPerSqm
            // Pulse Train =>  PTjoulesPerSqm  |  PTwattsPerSqm
            // Average     => AvejoulesPerSqm  | AvewattsPerSqm
            const myAEL = {
                c1: {
                    joules: AEL('c1', 'joules', props),
                    watts: AEL('c1', 'watts', props)
                },
                c2: {
                    joules: AEL('c2', 'joules', props),
                    watts: AEL('c2', 'watts', props)
                },
                c3r: {
                    joules: AEL('c3r', 'joules', props),
                    watts: AEL('c3r', 'watts', props)
                },
                c3b: {
                    joules: AEL('c3b', 'joules', props),
                    watts: AEL('c3b', 'watts', props)
                }
            }

            dataSource = [
                {
                    key: 1,
                    name: 'AEL for Class 1',
                    joulesPerSqm: <Text
                        strong
                        type={AEcompare('single', 'c1', props)}
                        style={mostRestrictiveCheck('single', 'c1', props)}
                    >
                        {myAEL.c1.joules.single ?? myAEL.c1.joules ?? '-'}
                    </Text>,
                    wattsPerSqm: <Text style={mostRestrictiveCheck('single', 'c1', props)} strong type={AEcompare('single', 'c1', props)}>{myAEL.c1.watts.single ?? myAEL.c1.watts ?? '-'}</Text>,
                    PTjoulesPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c1', props)} strong type={AEcompare('pulseTrain', 'c1', props)}>{myAEL.c1.joules.pulseTrain ?? myAEL.c1.joules ?? "-"}</Text>,
                    PTwattsPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c1', props)} strong type={AEcompare('pulseTrain', 'c1', props)}>{myAEL.c1.watts.pulseTrain ?? myAEL.c1.watts ?? '-'}</Text>,
                    AvejoulesPerSqm: <Text strong >{myAEL.c1.joules.average ?? myAEL.c1.joules ?? '-'}</Text>,
                    AvewattsPerSqm: <Text strong >{myAEL.c1.watts.average ?? myAEL.c1.watts ?? '-'}</Text>,
                    AELSPT: <Text style={mostRestrictiveCheck('AELSPT', 'c1', props)} strong type={AEcompare('Average', 'c1', props)}>{myAEL.c1.joules.AELSPT || '-'}</Text>,
                    excess: <Text strong type={passFail('c1', props).style}>{excess('c1', props)}</Text>,
                    pf: <Text strong type={passFail('c1', props).style}>{passFail('c1', props).value}</Text>
                },
                {
                    key: 2,
                    name: 'AEL for Class 2',
                    joulesPerSqm: <Text style={mostRestrictiveCheck('single', 'c2', props)} strong type={AEcompare('single', 'c2', props)}>{myAEL.c2.joules.single || '-'}</Text>,
                    wattsPerSqm: <Text style={mostRestrictiveCheck('single', 'c2', props)} strong type={AEcompare('single', 'c2', props)}>{myAEL.c2.watts.single || '-'}</Text>,
                    PTjoulesPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c2', props)} strong type={AEcompare('pulseTrain', 'c2', props)}>{myAEL.c2.joules.pulseTrain || '-'}</Text>,
                    PTwattsPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c2', props)} strong type={AEcompare('pulseTrain', 'c2', props)}>{myAEL.c2.watts.pulseTrain || '-'}</Text>,
                    AvejoulesPerSqm: <Text strong>{myAEL.c2.joules.average || '-'}</Text>,
                    AvewattsPerSqm: <Text strong>{myAEL.c2.watts.average || '-'}</Text>,
                    AELSPT: <Text style={mostRestrictiveCheck('AELSPT', 'c2', props)} strong type={AEcompare('Average', 'c2', props)}>{myAEL.c2.joules.AELSPT || '-'}</Text>,
                    excess: <Text strong type={passFail('c2', props).style}>{excess('c2', props)}</Text>,
                    pf: <Text strong type={passFail('c2', props).style}>{passFail('c2', props).value}</Text>
                },
                {
                    key: 3,
                    name: 'AEL for Class 3R',
                    joulesPerSqm: <Text style={mostRestrictiveCheck('single', 'c3r', props)} strong type={AEcompare('single', 'c3r', props)}>{myAEL.c3r.joules.single ?? myAEL.c3r.joules ?? '-'}</Text>,
                    wattsPerSqm: <Text style={mostRestrictiveCheck('single', 'c3r', props)} strong type={AEcompare('single', 'c3r', props)}>{myAEL.c3r.watts.single ?? myAEL.c3r.watts ?? '-'}</Text>,
                    PTjoulesPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c3r', props)} strong type={AEcompare('pulseTrain', 'c3r', props)}>{myAEL.c3r.joules.pulseTrain ?? myAEL.c3r.joules ?? '-'}</Text>,
                    PTwattsPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c3r', props)} strong type={AEcompare('pulseTrain', 'c3r', props)}>{myAEL.c3r.watts.pulseTrain ?? myAEL.c3r.watts ?? '-'}</Text>,
                    AvejoulesPerSqm: <Text strong>{myAEL.c3r.joules.average ?? myAEL.c3r.joules ?? '-'}</Text>,
                    AvewattsPerSqm: <Text strong>{myAEL.c3r.watts.average ?? myAEL.c3r.watts ?? '-'}</Text>,
                    AELSPT: <Text style={mostRestrictiveCheck('AELSPT', 'c3r', props)} strong type={AEcompare('Average', 'c3r', props)}>{myAEL.c3r.joules.AELSPT || '-'}</Text>,
                    excess: <Text strong type={passFail('c3r', props).style}> {excess('c3r', props)}</Text>,
                    pf: <Text strong type={passFail('c3r', props).style}>{passFail('c3r', props).value}</Text>
                },
                {
                    key: 3,
                    name: 'AEL for Class 3B',
                    joulesPerSqm: <Text style={mostRestrictiveCheck('single', 'c3b', props)} strong type={AEcompare('single', 'c3b', props)}>{myAEL.c3b.joules.single ?? myAEL.c3b.joules ?? '-'}</Text>,
                    wattsPerSqm: <Text style={mostRestrictiveCheck('single', 'c3b', props)} strong type={AEcompare('single', 'c3b', props)}>{myAEL.c3b.watts.single ?? myAEL.c3b.watts ?? '-'}</Text>,
                    PTjoulesPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c3b', props)} strong type={AEcompare('pulseTrain', 'c3b', props)}>{myAEL.c3b.joules.pulseTrain ?? myAEL.c3b.joules ?? '-'}</Text>,
                    PTwattsPerSqm: <Text style={mostRestrictiveCheck('pulseTrain', 'c3b', props)} strong type={AEcompare('pulseTrain', 'c3b', props)}>{myAEL.c3b.watts.pulseTrain ?? myAEL.c3b.watts ?? '-'}</Text>,
                    AvejoulesPerSqm: <Text strong>{myAEL.c3b.joules.average ?? myAEL.c3b.joules ?? '-'}</Text>,
                    AvewattsPerSqm: <Text strong>{myAEL.c3b.watts.average ?? myAEL.c3b.watts ?? '-'}</Text>,
                    AELSPT: <Text style={mostRestrictiveCheck('AELSPT', 'c3b', props)} strong type={AEcompare('Average', 'c3b', props)}>{myAEL.c3b.joules.AELSPT || '-'}</Text>,
                    excess: <Text strong type={passFail('c3b', props).style}>{excess('c3b', props)}</Text>,
                    pf: <Text strong type={passFail('c3b', props).style}>{passFail('c3b', props).value}</Text>
                },
                {
                    key: 4,
                    name: <Text strong>Accessible Emission</Text>,
                    joulesPerSqm: <Text strong>{collectedEmission('joules', undefined, props) || '-'}</Text>,
                    PTjoulesPerSqm: <Text strong>{collectedEmission('watts', undefined, props) || '-'}</Text>
                }
            ]
        } else {
            column = [
                {
                    key: 1,
                    title: 'Laser Class',
                    dataIndex: 'name',
                    align: "center",
                    width: '33%'
                },
                {
                    key: 2,
                    title: 'Accessible Emission Limit',
                    dataIndex: 'joulesPerSqm',
                    align: "center"
                    // children: [
                    //     {
                    //         key: 1,
                    //         title: 'Joules',
                    //         dataIndex: 'joulesPerSqm',
                    //         align: 'center'
                    //     },
                    //     {
                    //         key: 2,
                    //         title: 'Watts',
                    //         dataIndex: 'wattsPerSqm',
                    //         align: 'center'
                    //     }
                    // ]
                },
                {
                    key: 3,
                    title: 'Emission Hazard Value',
                    dataIndex: 'excess',
                    align: 'center'
                },
                {
                    key: 4,
                    title: 'Pass/Fail',
                    dataIndex: 'pf',
                    align: 'center'
                }
            ]

            const myCollectedEmission = (() => {
                if (!CE3isPersqm) {
                    return {
                        key: 5,
                        name: <Text strong>Accessible Emission</Text>,
                        joulesPerSqm: (
                            <Text strong>
                                {collectedEmission('joules',undefined, props)}
                            </Text>

                        ),
                        wattsPerSqm: (
                            <Text strong>
                                {collectedEmission('watts',undefined, props)}
                            </Text>
                        )
                    }
                } else {
                    return {
                        key: 5,
                        name: <Text strong>Accessible Emission</Text>,
                        joulesPerSqm: (
                            <Text strong>
                                {`${collectedEmission('joules',undefined, props)} | ${collectedEmission('watts',undefined, props)}`}
                            </Text>
                        ),
                        wattsPerSqm: (
                            <Text strong>
                                {collectedEmission('watts',undefined, props)}
                            </Text>
                        ),
                        // excess: (
                        //     <Text strong>
                        //         {collectedEmission('joules', true, props)}
                        //     </Text>
                        // ),
                        // pf: (
                        //     <Text strong>
                        //         {collectedEmission('watts', true, props)}
                        //     </Text>
                        // )
                    }
                }
            })()
            dataSource = [
                {
                    key: 1,
                    name: 'AEL for Class 1',
                    joulesPerSqm: (
                        <Text strong type={AEcompare('single', 'c1', props)}>
                            {AEL('c1', 'joules', props)}
                        </Text>
                    ),
                    wattsPerSqm: (
                        <Text strong type={AEcompare('single', 'c1', props)}>
                            {AEL('c1', 'watts', props)}
                        </Text>
                    ),
                    excess: (
                        <Text strong type={passFail('c1', props).style}>
                            {excess('c1', props)}
                        </Text>
                    ),
                    pf: (
                        <Text strong type={passFail('c1', props).style}>
                            {passFail('c1', props).value}
                        </Text>
                    )
                },
                {
                    key: 2,
                    name: `AEL for Class 2`,
                    joulesPerSqm: (
                        <Text strong type={AEcompare('single', 'c2', props)}>
                            {AEL('c2', 'joules', props)}
                        </Text>
                    ),
                    wattsPerSqm: (
                        <Text strong type={AEcompare('single', 'c2', props)}>
                            {AEL('c2', 'watts', props)}
                        </Text>
                    ),
                    excess: (
                        <Text strong type={passFail('c2', props).style}>
                            {excess('c2', props)}
                        </Text>
                    ),
                    pf: (
                        <Text strong type={passFail('c2', props).style}>
                            {passFail('c2', props).value}
                        </Text>
                    )
                },
                {
                    key: 3,
                    name: `AEL for Class 3R`,
                    joulesPerSqm: (
                        <Text strong type={AEcompare('single', 'c3r', props)}>
                            {AEL('c3r', 'joules', props)}
                        </Text>
                    ),
                    wattsPerSqm: (
                        <Text strong type={AEcompare('single', 'c3r', props)}>
                            {AEL('c3r', 'watts', props)}
                        </Text>
                    ),
                    excess: (
                        <Text strong type={passFail('c3r', props).style}>
                            {excess('c3r', props)}
                        </Text>

                    ),
                    pf: (
                        <Text strong type={passFail('c3r', props).style}>
                            {passFail('c3r', props).value}
                        </Text>
                    )
                },
                {
                    key: 4,
                    name: 'AEL for Class 3B',
                    joulesPerSqm: (
                        <Text strong type={AEcompare('single', 'c3b', props)}>
                            {AEL('c3b', 'joules', props)}
                        </Text>

                    ),
                    wattsPerSqm: (
                        <Text strong type={AEcompare('single', 'c3b', props)}>
                            {AEL('c3b', 'watts', props)}
                        </Text>

                    ),
                    excess: (
                        <Text strong type={passFail('c3b', props).style}>
                            {excess('c3b', props)}
                        </Text>

                    ),
                    pf: (
                        <Text strong type={passFail('c3b', props).style}>
                            {passFail('c3b', props).value}
                        </Text>
                    )
                },
                myCollectedEmission
            ]
        }
        return (
            {
                column: column,
                dataSource: dataSource,
            }
        )
    })()
    return (
        <>
        <Flex justify='center' style={{ width: '100%'}}>
            <Table
                title={() => (
                    <Flex style={{ width: '100%' }} justify="center">
                        <Text style={{ fontSize: '15px' }} strong>Condition {props.condition}</Text>
                    </Flex>
                )}
                style={{ width: '100%', margin: '12px', overflowX: "auto" }}
                size="small"
                scroll={{ x: 600}} 
                pagination={false}
                bordered
                columns={data.column}
                dataSource={data.dataSource}
                footer={() => (
                    <Collapse
                        size="small"
                        items={[
                            {
                                key: 1,
                                label: (
                                    <Flex justify='center'>
                                        <Flex>
                                            <Text strong type="warning">
                                                More info
                                            </Text>
                                        </Flex>
                                    </Flex>
                                ),
                                children: (
                                    <>
                                        <Row justify="center">
                                            <Col lg={12} sm={24} justify='center' style={{ width: '100%', padding: '12px'}} >
                                                <Space direction='vertical'>
                                                    <Flex justify='center'><Title level={4}>Legend</Title></Flex>
                                                    <List bordered>
                                                        <List.Item>The AE values displayed in <Typography.Text strong type={'danger'}>red font</Typography.Text> indicate that the collected emission has exceeded the specified limit.</List.Item>
                                                        <List.Item>The AE values displayed in <Typography.Text strong type={'success'}>green font</Typography.Text> indicate that the collected emission is within the specified limit.</List.Item>
                                                        {laserOperation === 'RP' || laserOperation === "FO_RP" ? <List.Item>The AE values highlighted in orange indicate that specified value is the <Text strong style={{ background: `#F8BD14` }}>Most Restrictive AEL.</Text></List.Item> : null}
                                                    </List>

                                                </Space>
                                            </Col>
                                            <Col lg={12} sm={24}>
                                            <Table
                                                style={{ width: '100%' }}
                                                size="small"
                                                pagination={false}
                                                bordered
                                                columns={[
                                                    {
                                                        key: 1,
                                                        title: `Condition ${props.condition} Misc Results`,
                                                        dataIndex: 'name',
                                                        align: 'right',
                                                        width: '80%'
                                                    },
                                                    {
                                                        key: 2,
                                                        title: 'Value',
                                                        dataIndex: 'value',
                                                        align: 'center'
                                                    }
                                                ]}
                                                dataSource={[
                                                    {
                                                        key: 1,
                                                        name: 'Aperture Stop',
                                                        value: props.laserClass[`condition${condition}`]?.apertureStop === "NaNm" ? "--" : props.laserClass[`condition${condition}`]?.apertureStop
                                                    },
                                                    {
                                                        key: 2,
                                                        name: 'Aperture Distance',
                                                        value: props.laserClass[`condition${condition}`]?.apertureDistance === "NaN m" ? "--" : props.laserClass[`condition${condition}`]?.apertureDistance
                                                    },
                                                    {
                                                        key: 3,
                                                        name: 'Angular Subtense',
                                                        value: props.laserClass[`condition${condition}`]?.angularSubtense !== null ? props.laserClass[`condition${condition}`]?.angularSubtense : '-'
                                                    },
                                                    // {
                                                    //     key: 4,
                                                    //     name: 'Value for C₆',
                                                    //     value: props.laserClass[`condition${condition}`]?.C6
                                                    // },
                                                    {
                                                        key: 5,
                                                        name: 'Acceptance Angle',
                                                        value: `-`
                                                    }
                                                ]}
                                            />
                                            </Col>
                                        </Row>
                                        <Flex justify='center' style={{ width: '100%'}}>
                                            <Text type='secondary'>Note: The correction factors used are displayed on the respective Class tabs</Text>
                                        </Flex>
                                    </>
                                )
                            }
                        ]}
                    />
                )}
            />
        </Flex>
       
        </>
    )
}
function AEL(laserClass, unitToReturn, props) {

    const condition = props.condition || 1
    const { condition1, condition2, condition3, laserOperation, conditionIsEmpty} = props.laserClass
    if (condition === 1) {
        if (conditionIsEmpty === false && (laserOperation === 'cw' || laserOperation === 'FO_cw')) {
            // The AEL for Continuous wave operation.
            if (condition1.AEL_standard.single[laserClass].joulesPretty !== undefined && condition1.AEL_standard.single[laserClass].joulesPretty !== null) {
                if (unitToReturn === 'joules') {
                    if (condition1.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                        return condition1.AEL_standard.single[laserClass].joulesPretty
                    } else {
                        return condition1.AEL_standard.single[laserClass].joulesPerSqmPretty + '/m²';
                    }
                } else {
                    if (condition1.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                        return condition1.AEL_standard.single[laserClass].wattsPretty;
                    } else {
                        return condition1.AEL_standard.single[laserClass].wattsPerSqmPretty + '/m²';
                    }
                }
            } else {
                return '-'
            }
        } else if ((laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" || laserOperation === "FO_RP") && conditionIsEmpty === false) {
            // The AEL for single pulse.
            if (condition1.AEL_standard.single[laserClass].joulesPretty !== undefined && condition1.AEL_standard.single[laserClass].joulesPretty !== null) {
                if ((laserOperation === 'RP' || laserOperation === "FO_RP") && conditionIsEmpty === false) {
                    // The AEL for Repetitively Pulsed calculators
                    let single = '-'
                    let pulseTrain = '-'
                    let average = '-'
                    let AELSPT = '-'

                    // Single
                    if (condition1.AEL_standard.single[laserClass].joulesPretty !== undefined && condition1.AEL_standard.single[laserClass].joulesPretty !== null) {
                        if (unitToReturn === 'joules') {
                            if (condition1.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                                single = condition1.AEL_standard.single[laserClass].joulesPretty
                            } else {
                                single = condition1.AEL_standard.single[laserClass].joulesPerSqmPretty + '/m²';
                            }
                        } else {
                            if (condition1.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                                single = condition1.AEL_standard.single[laserClass].wattsPretty;
                            } else {
                                single = condition1.AEL_standard.single[laserClass].wattsPerSqmPretty + '/m²';
                            }
                        }
                    }
                    // Pulse Train
                    if (condition1.AEL_standard.pulseTrain[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            pulseTrain = condition1.AEL_standard.pulseTrain[laserClass].pretty || '-'
                        } else {
                            pulseTrain = condition1.AEL_standard.pulseTrain[laserClass].prettyWatts || '-'
                        }
                    }
                    // Average
                    if (condition1.AEL_standard.Average[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            average = condition1.AEL_standard.Average[laserClass].pretty || '-'
                        } else {
                            average = condition1.AEL_standard.Average[laserClass].prettyWatts || '-'
                        }
                    }
                    //AELSPT
                    if (condition1.AEL_standard.AELSPT[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            AELSPT = condition1.AEL_standard.AELSPT[laserClass].pretty || '-'
                        } else {
                            AELSPT = condition1.AEL_standard.AELSPT[laserClass].prettyWatts || '-'
                        }
                    }
                    return {
                        single: single,
                        pulseTrain: pulseTrain,
                        average: average,
                        AELSPT: AELSPT
                    }
                } else {
                    if (condition1.AEL_standard.single[laserClass].joulesPretty !== undefined && condition1.AEL_standard.single[laserClass].joulesPretty !== null) {
                        if (unitToReturn === 'joules') {
                            if (condition1.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                                return condition1.AEL_standard.single[laserClass].joulesPretty
                            } else {
                                return condition1.AEL_standard.single[laserClass].joulesPerSqmPretty + '/m²';
                            }
                        } else {
                            if (condition1.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                                return condition1.AEL_standard.single[laserClass].wattsPretty;
                            } else {
                                return condition1.AEL_standard.single[laserClass].wattsPerSqmPretty + '/m²';
                            }
                        }
                    }
                }
            } else {
                return '-'
            }
        } else {
            return '-'
        }
    }
    else if (condition === 2 ) {
        if (conditionIsEmpty === false && (laserOperation === 'cw' || laserOperation === 'FO_cw')) {
            // The AEL for Continuous wave operation.
            if (condition2.AEL_standard.single[laserClass].joulesPretty !== undefined && condition2.AEL_standard.single[laserClass].joulesPretty !== null) {
                if (unitToReturn === 'joules') {
                    if (condition2.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                        return condition2.AEL_standard.single[laserClass].joulesPretty
                    } else {
                        return condition2.AEL_standard.single[laserClass].joulesPerSqmPretty + '/m²';
                    }
                } else {
                    if (condition2.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                        return condition2.AEL_standard.single[laserClass].wattsPretty;
                    } else {
                        return condition2.AEL_standard.single[laserClass].wattsPerSqmPretty + '/m²';
                    }
                }
            } else {
                return '-'
            }
        } else if ((laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" || laserOperation === "FO_RP") && conditionIsEmpty === false) {
            // The AEL for single pulse.
            if (condition2.AEL_standard.single[laserClass].joulesPretty !== undefined && condition2.AEL_standard.single[laserClass].joulesPretty !== null) {
                if ((laserOperation === 'RP' || laserOperation === "FO_RP") && conditionIsEmpty === false) {
                    // The AEL for Repetitively Pulsed calculators
                    let single = '-'
                    let pulseTrain = '-'
                    let average = '-'
                    let AELSPT = '-'
                    // Single
                    if (condition2.AEL_standard.single[laserClass].joulesPretty !== undefined && condition2.AEL_standard.single[laserClass].joulesPretty !== null) {
                        if (unitToReturn === 'joules') {
                            if (condition2.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                                single = condition2.AEL_standard.single[laserClass].joulesPretty
                            } else {
                                single = condition2.AEL_standard.single[laserClass].joulesPerSqmPretty + '/m²';
                            }
                        } else {
                            if (condition2.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                                single = condition2.AEL_standard.single[laserClass].wattsPretty;
                            } else {
                                single = condition2.AEL_standard.single[laserClass].wattsPerSqmPretty + '/m²';
                            }
                        }
                    }
                    // Pulse Train
                    if (condition2.AEL_standard.pulseTrain[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            pulseTrain = condition2.AEL_standard.pulseTrain[laserClass].pretty || '-'
                        } else {
                            pulseTrain = condition2.AEL_standard.pulseTrain[laserClass].prettyWatts || '-'
                        }
                    }
                    // Average
                    if (condition2.AEL_standard.Average[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            average = condition2.AEL_standard.Average[laserClass].pretty || '-'
                        } else {
                            average = condition2.AEL_standard.Average[laserClass].prettyWatts || '-'
                        }
                    }
                    //AELSPT
                    if (condition1.AEL_standard.AELSPT[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            AELSPT = condition2.AEL_standard.AELSPT[laserClass].pretty || '-'
                        } else {
                            AELSPT = condition2.AEL_standard.AELSPT[laserClass].prettyWatts || '-'
                        }
                    }
                    return {
                        single: single,
                        pulseTrain: pulseTrain,
                        average: average,
                        AELSPT: AELSPT
                    }
                } else {
                    if (condition2.AEL_standard.single[laserClass].joulesPretty !== undefined && condition2.AEL_standard.single[laserClass].joulesPretty !== null) {
                        if (unitToReturn === 'joules') {
                            if (condition2.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                                return condition2.AEL_standard.single[laserClass].joulesPretty
                            } else {
                                return condition2.AEL_standard.single[laserClass].joulesPerSqmPretty + '/m²';
                            }
                        } else {
                            if (condition2.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                                return condition2.AEL_standard.single[laserClass].wattsPretty;
                            } else {
                                return condition2.AEL_standard.single[laserClass].wattsPerSqmPretty + '/m²';
                            }
                        }
                    }
                }
            } else {
                return '-'
            }
        } else {
            return '-'
        }
    } else {
        // Condition 3
        if (laserOperation === 'cw' || laserOperation === 'FO_cw') {
            if (condition3.AEL_standard.single[laserClass].wattsPretty !== "nullW") {
                if (unitToReturn === 'joules') {
                    return condition3.AEL_standard.single[laserClass].joulesPretty || '-'
                } else {
                    return condition3.AEL_standard.single[laserClass].wattsPretty || '-'
                }
            } else {
                if (unitToReturn === 'watts') {
                    return condition3.AEL_standard.single[laserClass].wattsPerSqmPretty || '-'
                } else {
                    return condition3.AEL_standard.single[laserClass].joulesPerSqmPretty || '-'
                }
            }
        } else if (laserOperation === 'singlePulse' || laserOperation === "FO_singlePulse" || laserOperation === "RP" || laserOperation === "FO_RP") {
            if (condition3.AEL_standard.single[laserClass].joulesPretty !== undefined && condition3.AEL_standard.single[laserClass].joulesPretty !== null) {
                if ((laserOperation === 'RP' || laserOperation === "FO_RP")) {
                    // The AEL for Repetitively Pulsed calculators
                    let single = '-'
                    let pulseTrain = '-'
                    let average = '-'
                    let AELSPT = '-'

                    // Single
                    if (condition3.AEL_standard.single[laserClass].joulesPretty !== undefined && condition3.AEL_standard.single[laserClass].joulesPretty !== null) {
                        if (unitToReturn === 'joules') {
                            if (condition3.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                                single = condition3.AEL_standard.single[laserClass].joulesPretty
                            } else {
                                single = condition3.AEL_standard.single[laserClass].joulesPerSqmPretty;
                            }
                        } else {
                            if (condition3.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                                single = condition3.AEL_standard.single[laserClass].wattsPretty;
                            } else {
                                single = condition3.AEL_standard.single[laserClass].wattsPerSqmPretty ;
                            }
                        }
                    }
                    // Pulse Train
                    if (condition3.AEL_standard.pulseTrain[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            pulseTrain = condition3.AEL_standard.pulseTrain[laserClass].pretty || '-'
                        } else {
                            pulseTrain = condition3.AEL_standard.pulseTrain[laserClass].prettyWatts || '-'
                        }
                    }
                    // Average
                    if (condition3.AEL_standard.Average[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            average = condition3.AEL_standard.Average[laserClass].pretty || '-'
                        } else {
                            average = condition3.AEL_standard.Average[laserClass].prettyWatts || '-'
                        }
                    }
                    //AELSPT
                    if (condition3.AEL_standard.AELSPT[laserClass].raw !== 0) {
                        if (unitToReturn === 'joules') {
                            AELSPT = condition3.AEL_standard.AELSPT[laserClass].pretty || '-'
                        } else {
                            AELSPT = condition3.AEL_standard.AELSPT[laserClass].prettyWatts || '-'
                        }
                    }
                    return {
                        single: single,
                        pulseTrain: pulseTrain,
                        average: average,
                        AELSPT: AELSPT
                    }
                } else {
                    if (condition3.AEL_standard.single[laserClass].joulesPretty !== undefined && condition3.AEL_standard.single[laserClass].joulesPretty !== null) {
                        if (unitToReturn === 'joules') {
                            if (condition3.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                                return condition3.AEL_standard.single[laserClass].joulesPretty
                            } else {
                                return condition3.AEL_standard.single[laserClass].joulesPerSqmPretty ;
                            }
                        } else {
                            if (condition3.AEL_standard.single[laserClass].wattsPretty.includes('ull') === false) {
                                return condition3.AEL_standard.single[laserClass].wattsPretty;
                            } else {
                                return condition3.AEL_standard.single[laserClass].wattsPerSqmPretty;
                            }
                        }
                    }
                }
            } else {
                return '-'
            }
            // if (condition3.AEL_standard.single[laserClass].joulesPretty !== "nullJ") {
            //     if (unitToReturn === 'joules') {
            //         return condition3.AEL_standard.single[laserClass].joulesPretty || '-'
            //     } else {
            //         return condition3.AEL_standard.single[laserClass].wattsPretty || '-'
            //     }
            // } else {
            //     if (unitToReturn === 'joules') {
            //         return condition3.AEL_standard.single[laserClass].joulesPerSqmPretty || '-'
            //     } else {
            //         return condition3.AEL_standard.single[laserClass].wattsPerSqmPretty || '-'
            //     }
            // }
        } else {
            return '-'
        }
    }
}

const mostRestrictiveCheck = (aelType, laserClass, props) => {
    const condition = props.condition || 1
    const { condition1, condition2, condition3, laserOperation} = props.laserClass
    const x = {
        c1: 'class1',
        c2: 'class2',
        c3r: 'class3R',
        c3b: 'class3B'
    }
    const orange = '#F8BD14'
    if(laserOperation === "RP" || laserOperation === "FO_RP"){
        if (condition === 1) {
            const mostRestrictive = condition1.mostRestrictiveAEL[x[laserClass]]
            if (aelType === 'single') {
                if (condition1.AEL_standard.single[laserClass].joulesPretty !== undefined && condition1.AEL_standard.single[laserClass].joulesPretty !== null) {
                    if (condition1.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                        if (mostRestrictive === condition1.AEL_standard.single[laserClass].joules) {
                            return { background: '#F8BD14' }
                        } else {
                            return { background: null }
                        }
                    } else {
                        return { background: null }
                    }
                }
            } else {
                if (condition1.AEL_standard[aelType][laserClass].raw !== 0) {
                    if (mostRestrictive === condition1.AEL_standard[aelType][laserClass].raw && condition1.AEL_standard.single[laserClass].joules !== condition1.AEL_standard[aelType][laserClass].raw ) {
                        return { background: orange }
                    } else {
                        return { background: null }
                    }
                }
            }
        }
        if (condition === 2) {
            const mostRestrictive = condition2.mostRestrictiveAEL[x[laserClass]]
            if (aelType === 'single') {
                if (condition2.AEL_standard.single[laserClass].joulesPretty !== undefined && condition2.AEL_standard.single[laserClass].joulesPretty !== null) {
                    if (condition2.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                        if (mostRestrictive === condition2.AEL_standard.single[laserClass].joules) {
                            return { background: '#F8BD14' }
                        } else {
                            return { background: null }
                        }
                    } else {
                        return { background: null }
                    }
                }
            } else {
                if (condition2.AEL_standard[aelType][laserClass].raw !== 0) {
                    if (mostRestrictive === condition2.AEL_standard[aelType][laserClass].raw && condition2.AEL_standard.single[laserClass].joules !== condition2.AEL_standard[aelType][laserClass].raw ) {
                        return { background: orange }
                    } else {
                        return { background: null }
                    }
                }
            }
        }else {
            const mostRestrictive = condition3.mostRestrictiveAEL[x[laserClass]]
            if (aelType === 'single') {
                if (condition3.AEL_standard.single[laserClass].joulesPretty !== undefined && condition3.AEL_standard.single[laserClass].joulesPretty !== null) {
                    if (condition3.AEL_standard.single[laserClass].joulesPretty.includes('ull') === false) {
                        if (mostRestrictive === condition3.AEL_standard.single[laserClass].joules) {
                            return { background: '#F8BD14' }
                        } else {
                            return { background: null }
                        }
                    } else {
                        return { background: null }
                    }
                }
            } else {
                if (condition3.AEL_standard[aelType][laserClass].raw !== 0) {
                    if (mostRestrictive === condition3.AEL_standard[aelType][laserClass].raw && condition3.AEL_standard.single[laserClass].joules !== condition3.AEL_standard[aelType][laserClass].raw) {
                        return { background: orange }
                    } else {
                        return { background: null }
                    }
                }
            }
        }
    }
}

function AEcompare(aelType, laserClass, props) {
    const condition = props.condition || 1
    const { condition1, condition2, condition3, CE3isPersqm} = props.laserClass

    if (condition === 1) {
        if (!condition1.collectedEmissionJoules.joulesPretty.includes('NaN')) {
            if (aelType === 'single') {
                if (condition1.AEL_standard.single[laserClass].joules !== undefined && condition1.AEL_standard.single[laserClass].joules !== null) {
                    if (condition1.collectedEmissionJoules.joules <= condition1.AEL_standard.single[laserClass].joules) {
                        return 'success'
                    } else {
                        return 'danger'
                    }
                } else {
                    return null
                }
            } else {
                if (condition1.AEL_standard[aelType][laserClass].raw !== undefined && condition1.AEL_standard[aelType][laserClass].raw !== null && condition1.AEL_standard[aelType][laserClass].raw !== 0) {
                    if (condition1.collectedEmissionJoules.joules <= condition1.AEL_standard[aelType][laserClass].raw) {
                        return 'success'
                    } else {
                        return 'danger'
                    }
                } else {
                    return null
                }
            }
        } else {
            return null
        }
    }
    else if (condition === 2) {
        if (!condition2.collectedEmissionJoules.joulesPretty.includes('NaN')) {
            if (aelType === 'single') {
                if (condition2.AEL_standard.single[laserClass].joules !== undefined && condition2.AEL_standard.single[laserClass].joules !== null) {
                    if (condition2.collectedEmissionJoules.joules <= condition2.AEL_standard.single[laserClass].joules) {
                        return 'success'
                    } else {
                        return 'danger'
                    }
                } else {
                    return null
                }
            } else {
                if (condition2.AEL_standard[aelType][laserClass].raw !== undefined && condition2.AEL_standard[aelType][laserClass].raw !== null && condition2.AEL_standard[aelType][laserClass].raw !== 0) {
                    if (condition2.collectedEmissionJoules.joules <= condition2.AEL_standard[aelType][laserClass].raw) {
                        return 'success'
                    } else {
                        return 'danger'
                    }
                } else {
                    return null
                }
            }
        } else {
            return null
        }
    }
    else if (condition === 3) {
        // Check if collected emission does not contain null values
        if (!condition3.collectedEmissionJoules.joulesPretty.includes('NaN')) {
            // Check if single, pulse train or average
            if (aelType === 'single') {
                // Check if condition3.AEL_standard.single.c1.joules
                let thisAEL = condition3.AEL_standard.single[laserClass].joules
                let thisCollectedEmission = (() => {
                    if (CE3isPersqm) {
                        if (condition3.AEL_standard.single[laserClass].joulesPretty !== null) {
                            if(condition3.AEL_standard.single[laserClass].joulesPretty === null){
                                if ( condition3.AEL_standard.single[laserClass].joulesPretty.includes('null')) {
                                    thisAEL = condition3.AEL_standard.single[laserClass].joulesPerSqm
                                    return condition3.collectedEmissionPerSqm.joules
                                } else {
                                    thisAEL = condition3.AEL_standard.single[laserClass].joules
                                    return condition3.collectedEmissionJoules.joules
                                    }
                                }
                            // if (condition3?.AEL_standard?.single[laserClass]?.joulesPretty.includes('null')) {
                        } else {
                            return condition3.collectedEmissionJoules.joules
                        }

                    } else {
                        return condition3.collectedEmissionJoules.joules
                    }
                })()
                // if (CE3isPersqm) {
                //     // thisCollectedEmission = condition3.collectedEmissionPerSqm.joules
                //     thisAEL = condition3.AEL_standard.single[laserClass].joulesPerSqm
                // }

                // console.log(`COndition ${condition}, class: ${laserClass}: `, thisAEL, thisCollectedEmission)
                if (thisAEL !== null && thisAEL !== undefined) {
                    if (thisCollectedEmission <= thisAEL) {
                        return 'success'
                    } else {
                        return 'danger'
                    }
                } else {
                    return null
                }
            } else {
                // ael type is Average or pulseTrain
                // check if ael is present
                let thisAEL = condition3.AEL_standard[aelType][laserClass].raw
                let thisCollectedEmission = condition3.collectedEmissionJoules.joules

                if (thisCollectedEmission <= thisAEL) {
                    return 'success'
                } else if (thisAEL === 0) {
                    return null
                
                } else {
                    return 'danger'
                }
            }
        } else {
            return "success"
        }
    }
    return "success"
}

function collectedEmission(unitToReturn, returnStandard = false, props) {
    const condition = props.condition || 1
    const { condition1, condition2, condition3, CE3isPersqm} = props.laserClass
    if (condition === 1) {
        if (condition1.collectedEmissionWatts.wattsPretty.includes('NaN')) {
            return '-'
        } else {
            if (unitToReturn === 'joules') {
                return condition1.collectedEmissionWatts.joulesPretty
            } else {
                return condition1.collectedEmissionJoules.wattsPretty
            }
        }
        
    }
    else if (condition === 2) {
        if (condition2.collectedEmissionWatts.wattsPretty.includes('NaN')) {
            return '-'
        } else {
            if (unitToReturn === 'joules') {
                return condition2.collectedEmissionWatts.joulesPretty
            } else {
                return condition2.collectedEmissionJoules.wattsPretty
            }
        }
    }
    else {
        // condiition  3
        if (condition3.collectedEmissionWatts.wattsPretty.includes('NaN')) {
            return '-'
        } else {
            if (unitToReturn === 'joules') {
                if (CE3isPersqm) {
                    if (returnStandard) {
                        return condition3.collectedEmissionJoules.joulesPretty
                    } else {
                        return condition3.collectedEmissionPerSqm.joulesPretty + '/m²'
                    }
                } else {
                    return condition3.collectedEmissionJoules.joulesPretty
                }
            } else {
                if (CE3isPersqm) {
                    if (returnStandard) {
                        return condition3.collectedEmissionWatts.wattsPretty
                    } else {
                        return condition3.collectedEmissionPerSqm.wattsPretty + '/m²'
                    }
                } else {
                    return condition3.collectedEmissionWatts.wattsPretty
                }
            }
        }
    }
}

function excess(laserClass, props) {
    const condition = props.condition || 1
    const { condition1, condition2, condition3} = props.laserClass
    if (condition === 1) {
        // condition 1
        if (typeof condition1.AEL_standard.Excess[`${laserClass}excess`].raw === 'number') {
            return condition1.AEL_standard.Excess[`${laserClass}excess`].pretty
        } else {
            return '-'
        }
    }
    else if ( condition === 2) {
        // condition 2
        if (typeof condition2.AEL_standard.Excess[`${laserClass}excess`].raw === 'number') {
            return condition2.AEL_standard.Excess[`${laserClass}excess`].pretty
        } else {
            return '-'
        }
    } else {
        // condition 3
        if (typeof condition3.AEL_standard.Excess[`${laserClass}excess`].raw === 'number') {
            return condition3.AEL_standard.Excess[`${laserClass}excess`].pretty || '-'
        } else {
            return condition3.AEL_standard.Excess[`${laserClass}excess`].raw || '-'
        }
    }
}

function passFail(laserClass, props) {
    const condition = props.condition || 1
    const { condition1, condition2, condition3} = props.laserClass
    if (condition === 1) {
        if (condition1.AEL_standard.Excess[`${laserClass}excess`].raw == undefined) {
            return { style: null, value: '-' }
        }
        else if (condition1.AEL_standard.Excess[`${laserClass}excess`].raw > 1) {
            return { style: 'danger', value: 'Fail' }
        }
        else if (condition1.AEL_standard.Excess[`${laserClass}excess`].raw <= 1) {
            return { style: 'success', value: 'Pass' }
        }
    }
    if (condition === 2) {
        if (condition2.AEL_standard.Excess[`${laserClass}excess`].raw == undefined) {
            return { style: null, value: '-' }
        }
        else if (condition2.AEL_standard.Excess[`${laserClass}excess`].raw > 1) {
            return { style: 'danger', value: 'Fail' }
        }
        else if (condition2.AEL_standard.Excess[`${laserClass}excess`].raw <= 1) {
            return { style: 'success', value: 'Pass' }
        }
    } else {
        if (condition3.AEL_standard.Excess[`${laserClass}excess`].raw == undefined) {
            return { style: null, value: '-' }
        }
        else if (condition3.AEL_standard.Excess[`${laserClass}excess`].raw > 1) {
            return { style: 'danger', value: 'Fail' }
        }
        else if (condition3.AEL_standard.Excess[`${laserClass}excess`].raw <= 1) {
            return { style: 'success', value: 'Pass' }
        }
    }
}



export {LaserClassResultsV2, mostRestrictiveCheck, AEcompare, AEL, collectedEmission, excess, passFail}