import Cookies from 'js-cookie'
import { useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { EnvironmentContext } from '../routes/EnvironmentContext'
import axios from 'axios'

const Logout = () => {
    const { isLocal, handleLogout } = useContext(EnvironmentContext)
    const navigate = useNavigate()
    const token = Cookies.get('RTLSS_TOKEN')

    // setDisableNav(true)
    useEffect(() => {
        const newLogout = async () => {
            axios({
                method: 'post',
                url: isLocal ? 'http://localhost:8000/jwt-logout' : 'api/jwt-logout',
                withCredentials: true
            })
                .then((response) => {
                    // console.log(`JWT logged out!`)
                    // console.log(response)
                })
                .catch((err) => {
                    // console.log(`Something went wrong when using jwt logout.`)
                    // console.log(err)
                })
                .finally(() => {
                    handleLogout()
                    // Navigate to login page after logging out.
                    navigate('/login')
                })
        }

        newLogout()
    })
}

export default Logout
