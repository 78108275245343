import { Card, Form, InputNumber, Select, Tooltip } from 'antd'
import React, { useState } from 'react'
import Field from '../../../../components/Field'
import { QuestionCircleOutlined } from '@ant-design/icons'
import WavelengthField from './QuickFields/WavelengthField'

const CWCalculator = () => {
    return (
        <>
            <Card style={{ margin: '24px 0 0' }} size='small' title='CW Settings'>
                {/* <Field
                    isCW
                    tabIndex={1}
                    name={['cw', 'wavelength']}
                    label={<>Wavelength</>}
                    unit='m'
                    min={180}
                    max='1000000000000000'
                    denoms={["n", "u"]}
                    placeholder='400nm to 1,000,000m'
                    toolTipText={`The laser's wavelength.`}
                /> */}

                <WavelengthField name={['cw', 'wavelength']} />

                <Field
                    min={0.000001}
                    isCW
                    tabIndex={2}
                    name={['cw', 'time']}
                    label='Exposure Duration'
                    unit='s'
                    denoms={[false, 'm', 'u', 'p', 'n', 'f']}
                    placeholder={`Enter emission duration`}
                    toolTipText={`The duration of continuous emission of laser radiation incident upon the human body.`}
                />

                <Field
                    tabIndex={3}
                    min={0.000001}
                    name={['cw', 'power',]}
                    label='Laser Power'
                    unit='W'
                    denoms={['u', 'm', false, 'k', 'M']}
                    placeholder={`Enter laser's output power`}
                    toolTipText={`The laser's rated power expressed in watts.`}
                />

                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
                    {({ getFieldValue }) => {
                        let sourceType = getFieldValue(['sourceType'])
                        return (
                            sourceType === 'point' ? (
                                <Field
                                    min={0.000001}
                                    tabIndex={4}
                                    name={['cw', 'beamDiameter']}
                                    label='Beam Diameter'
                                    unit='m'
                                    denoms={["m", "u"]}
                                    placeholder={`Enter laser's beam diameter`}
                                    toolTipText={`The diameter of the source of the laser beam.`}
                                />
                            ) : null
                        )
                    }}
                </Form.Item>

                <Field
                    tabIndex={5}
                    name={['cw', 'beamDivergence']}
                    label='Beam Divergence'
                    unit='rad'
                    denoms={['m', false, 'degree']}
                    placeholder={`Enter laser's beam divergence`}
                    toolTipText={`The far field plane angle of the cone defined by the beam diameter.`}
                />

                <Field
                    tabIndex={6}
                    name={['cw', 'distance']}
                    label='Distance to target'
                    unit='m'
                    denoms={['k', false, 'c', 'm', 'u', 'n', 'p', 'f']}
                    placeholder={`Enter distance from the laser`}
                    toolTipText={`The distance between the source of the laser beam and the target.`}
                />
            </Card>
        </>
    )
}

export default CWCalculator