import { Form, Tooltip, InputNumber, Select, } from 'antd'
import React, { useState } from 'react'

const WavelengthField = ({ name }) => {
    const [customWavelengthMin, setCustomWavelengthMin] = useState(180)
    const [customWavelengthMax, setCustomWavelengthMax] = useState(1000000000000000)
    const [customWavelengthPlaceholder, setCustomWavelengthPlaceholder] = useState('180nm to 1,000,000m')
    return (
        <>
            <Tooltip
                mouseEnterDelay={0.5}
                title={'The wavelength of the laser.'}
                placement='left'
                destroyTooltipOnHide
            >
                <Form.Item
                    label='Wavelength'
                    name={[...name, 'num']}
                    rules={[{ required: true, message: 'Please specify value.' }]}
                >
                    <InputNumber
                        tabIndex={1}
                        min={customWavelengthMin}
                        max={customWavelengthMax}
                        placeholder={customWavelengthPlaceholder}
                        style={{ width: '100%' }}
                        addonAfter={
                            <Form.Item noStyle shouldUpdate>
                                {({ setFieldValue }) => (
                                    <Form.Item
                                        noStyle
                                        name={[...name, 'unit']}
                                        rules={[{ required: true, message: 'Please specify unit.' }]}
                                    >
                                        <Select
                                            tabIndex={-1}
                                            onChange={(e) => {
                                                // console.log(`pandesal`, e)
                                                if (e) {

                                                    if (e === 'n') {
                                                        setFieldValue([...name, 'num'])
                                                        setCustomWavelengthMin(180)
                                                        setCustomWavelengthMax(1000000000000000)
                                                        setCustomWavelengthPlaceholder('180nm to 1,000,000')
                                                    } else if (e === 'u') {
                                                        setFieldValue([...name, 'num'])
                                                        setCustomWavelengthMin(180 / 1000)
                                                        setCustomWavelengthMax(1000000000000000 / 1000)
                                                        setCustomWavelengthPlaceholder('0.18µm to 1,000,000m')
                                                    }
                                                }
                                            }}
                                            style={{ width: 80 }}
                                            options={[
                                                { value: 'n', label: 'nm' },
                                                { value: 'u', label: 'µm' }
                                            ]}
                                        />
                                    </Form.Item>
                                )}

                            </Form.Item>
                        }
                    />
                </Form.Item>

            </Tooltip>
        </>
    )
}

export default WavelengthField