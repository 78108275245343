import { Button, Card, Flex, Form, Input, Tabs, message, Row } from 'antd'
import React, { useState, useContext } from 'react'
import Axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom';
import { EnvironmentContext } from '../../routes/EnvironmentContext';

// import logo from '../assets/Logo/logosidetext.png'

const ForgotChangePassword = ({ myUser, setMyUser }) => {

    const { isLocal } = useContext(EnvironmentContext);
    const [changePasswordForm] = Form.useForm()
    const [changeEmailForm] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [emailIsSubmitting, setEmailIsSubmitting] = useState(false)
    const email = sessionStorage.getItem('userEmail');
    const [form] = Form.useForm();
    const Navigate = useNavigate()

    const defaultEmailForm = {
        email: myUser?.emailAddress
    }

    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
            duration: 4
        })
    }

    const error = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: 4
        })
    }

    const handleChangePassword = (props) => {
        const {newPassword} = form.getFieldValue()

        setIsSubmitting(true)
        // const data = { ...props, accountID: myUser?.accountID }
        Axios({
            method: 'put',
            url: (()=>{
                if(isLocal === true){
                    return 'http://localhost:8000/forgotPassword'
                }
                else{
                    return 'api/forgotPassword'
                }
            })(),
            data: {email: email, newPassword: newPassword},
            withCredentials: true
            })
            .then((response) => {
                Navigate('/forgot-password-success')
                sessionStorage.removeItem('userEmail');
                form.resetFields()
            })
            .catch((err) => {
                // console.log("nag error")
               
            })
            
    }
  
    return (
        <>
            {contextHolder}
            <Flex vertical justify='center'>
                <Flex justify='center'>
                    <Card   
                        style={{
                            width: 500,
                            height: "40vh",
                            fontWeight: '500',
                            border:"none",
                            marginTop:"100px",
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                        }}>
                         <Row justify={'center'} style={{marginBottom:'10px'}}>
                            <h1>
                                Reset Password
                            </h1>
                        </Row>
                        <Row justify={'center'} style={{marginBottom:'30px'}}>
                            Enter new password for {email}
                        </Row>
                       
                        <Form
                            form={form}
                            labelCol={{ span: 8 }}
                            onFinish={handleChangePassword}>

                            <Form.Item name={'newPassword'}
                                label=''
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your new password.'
                                    },
                                    {   
                                        validator: (_, password) => {
                                            let uppercaseCheck = /(?=.*[A-Z])/
                                            if (!password.match(uppercaseCheck)) {
                                                return Promise.reject(new Error("At least one uppercase letter"));
                                            }
                                            return Promise.resolve()
                                        }
                                    },
                                    {
                                        validator: (_, password) => {
                                            let lowercaseCheck = /(?=.*[a-z])/
                                            if (!password.match(lowercaseCheck)) {
                                                return Promise.reject(new Error("At least one lowercase letter"));
                                            }
                                            return Promise.resolve()
                                            }
                                    },
                                    {
                                        validator: (_, password) => {
                                            let digitCheck = /(?=.*[0-9])/
                                            if (!password.match(digitCheck)) {
                                                return Promise.reject(new Error("At least one number"));
                                            }
                                            return Promise.resolve()
                                        }
                                    },
                                    {
                                        validator: (_, password) => {
                                            let minCheck = /(?=.{8,})/
                                            if (!password.match(minCheck)) {
                                                return Promise.reject(new Error("Minimum 8 characters"));
                                            }
                                            return Promise.resolve()
                                        }
                                    }
                                ]}>
                                <Input.Password placeholder='New Password'/>
                            </Form.Item>

                            <Form.Item name={'confirmPassword'}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}>
                                <Input.Password placeholder='Confirm Password'/>
                            </Form.Item>
                            <Row style={{marginTop:"50px"}}>
                                <Button loading={isSubmitting} htmlType='submit' block type='primary'>Reset Password</Button>
                           </Row>
                            <Row style={{marginTop:"10px"}}>
                                <Button block onClick={()=>Navigate('/login')}>
                                    Cancel
                                </Button>
                            </Row>
                        </Form>
                    </Card>
                </Flex>
            </Flex>
        </>
    )
}

export default ForgotChangePassword